import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useTheme } from '../hooks/useTheme';
import { ColorThemeProps } from '../theme';

const ModalWrapper = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  backdrop-filter: blur(1px); /* 背景模糊 */
  align-items: center;
  justify-content: center;
  z-index: 1000; /* 保证在最前面显示 */
  animation: ${({ isOpen }) => (isOpen ? 'fadeIn 0.3s' : 'none')};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ModalContent = styled.div<ColorThemeProps>`
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.black};
  padding: 2rem;
  border-radius: 0.75rem;
  border: 2px solid ${({ theme }) => theme.primaryButton}; 
  width: 90%;
  max-width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: slideIn 0.4s ease-out;

  @keyframes slideIn {
    from {
      transform: translateY(-30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.gray};
  &:hover {
    color: ${({ theme }) => theme.black};
  }
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const { theme } = useTheme();

  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalContent theme={theme}>
        <CloseButton theme={theme} onClick={onClose}>
          &times;
        </CloseButton>
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};
