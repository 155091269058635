const defaultThumbnail = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWgAAAFoCAMAAADtZpSnAAAAYFBMVEX///+hoaGtpaXm5uZHR0enp6d0dHSEhIcnJyfp6fPz8/b29t5eXlXV1erq6u2trbg4OBNTU1DQ0Ovr6+0tLSjo6OZmZlKSkqcnJyVlZXw8PDS0tKvq6u/v7+9vb3IyMgwMDCdQdeYAAAAXElEQVR4nO3BAQEAAADCoPdPbQ8HFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwFYsfgAABGEz+pUAAAAASUVORK5CYII=";

export const generateThumbnail = (base64Image: string, maxWidth = 100, maxHeight = 100): Promise<string> => {
    return new Promise((resolve, reject) => {
      // 检查base64Image是否包含 MIME 类型前缀，如果没有则返回默认缩略图
      if (!base64Image.startsWith("data:image")) {
        console.warn("Image does not have a valid MIME type prefix, using default thumbnail.");
        resolve(defaultThumbnail);
        return;
      }

      const img = new Image();
      img.onload = () => {
        const scale = Math.min(maxWidth / img.width, maxHeight / img.height) * 2;
        const canvas = document.createElement('canvas');
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const thumbnailBase64 = canvas.toDataURL('image/png');
          resolve(thumbnailBase64);
        } else {
          reject("Failed to get canvas context.");
        }
      };
      img.onerror = () => {
        console.error("Image loading error, using default thumbnail.");
        resolve(defaultThumbnail);
      };
      img.src = base64Image;
    });
};
