import { openDB } from 'idb';

const DB_NAME = 'StorageDB';
const STORE_NAME = 'keyval';
const MAX_ENTRIES = 100; // Max entries for LRU

interface Storage {
  set: (obj: any, callback?: () => void) => void;
  get: (key: string | string[], callback: (result: any) => void) => void;
  remove: (key: string | string[], callback?: () => void) => void;
  clear: () => Promise<void>;
}

// idbStorage: 使用真实的 IndexedDB 存储
const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME);
    }
  },
});

// Helper function to clean old entries (LRU)
const cleanOldEntries = async () => {
  const db = await dbPromise;
  const store = db.transaction(STORE_NAME, 'readwrite').objectStore(STORE_NAME);
  
  const count = await store.count();
  if (count > MAX_ENTRIES) {
    const cursor = await store.openCursor();
    let deleted = 0;

    while (cursor && deleted < count - MAX_ENTRIES) {
      await cursor.delete();
      deleted++;
      await cursor.continue();
    }
  }
};

// idbStorage: 实现 IndexedDB 存储
const idbStorage: Storage = {
  set: async (obj, callback) => {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    Object.keys(obj).forEach(async (key) => {
      await store.put(obj[key], key);
    });

    await tx.done;
    await cleanOldEntries(); // Clean old entries after setting new ones

    if (callback) callback();
  },

  get: async (keyOrKeys, callback) => {
    const db = await dbPromise;
    const store = db.transaction(STORE_NAME).objectStore(STORE_NAME);
    const result: { [key: string]: any } = {};

    if (typeof keyOrKeys === 'string') {
      const value = await store.get(keyOrKeys);
      result[keyOrKeys] = value ?? null;
      callback(result);
    } else if (Array.isArray(keyOrKeys)) {
      for (const key of keyOrKeys) {
        const value = await store.get(key);
        result[key] = value ?? null;
      }
      callback(result);
    }
  },

  remove: async (keyOrKeys, callback) => {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    if (typeof keyOrKeys === 'string') {
      await store.delete(keyOrKeys);
    } else if (Array.isArray(keyOrKeys)) {
      for (const key of keyOrKeys) {
        await store.delete(key);
      }
    }

    await tx.done;
    if (callback) callback();
  },

  clear: async () => {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.clear();
    await tx.done;
    //console.log('Database cleared successfully');
  },
};

// mockIdbStorage: 模拟的存储方案，使用 localStorage 或内存存储
const mockIdbStorage: Storage = {
  set: (obj, callback) => {
    // 模拟 LRU 行为：限制最大条目数
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (typeof obj[key] === 'object') {
        localStorage.setItem(key, JSON.stringify(obj[key]));
      } else {
        localStorage.setItem(key, obj[key]);
      }
    });

    // 清理旧的条目以保持最大条目数
    const totalKeys = Object.keys(localStorage);
    if (totalKeys.length > MAX_ENTRIES) {
      // 清理掉最早的条目，模拟 LRU
      const firstKey = totalKeys[0];
      localStorage.removeItem(firstKey);
    }

    if (callback) callback();
  },

  get: (keyOrKeys, callback) => {
    const result: { [key: string]: any } = {};

    if (typeof keyOrKeys === 'string') {
      const value = localStorage.getItem(keyOrKeys);
      result[keyOrKeys] = value ? JSON.parse(value) : null;
      callback(result);
    } else if (Array.isArray(keyOrKeys)) {
      keyOrKeys.forEach((key) => {
        const value = localStorage.getItem(key);
        result[key] = value ? JSON.parse(value) : null;
      });
      callback(result);
    }
  },

  remove: (keyOrKeys, callback) => {
    if (typeof keyOrKeys === 'string') {
      localStorage.removeItem(keyOrKeys);
    } else if (Array.isArray(keyOrKeys)) {
      keyOrKeys.forEach((key) => {
        localStorage.removeItem(key);
      });
    }
    if (callback) callback();
  },

  clear: async () => {
    localStorage.clear();
  },
};

// 环境检测：检查浏览器是否支持 IndexedDB
const isWebEnvironment = typeof window !== 'undefined';
const isIndexedDBSupported = isWebEnvironment && 'indexedDB' in window;

// 根据环境选择使用 idbStorage 还是 mockIdbStorage
export const idbstorage: Storage = isIndexedDBSupported ? idbStorage : mockIdbStorage;
