import { validate } from 'bitcoin-address-validation';
import React, { useEffect, useState } from 'react';
import { useKeys } from '../hooks/useKeys';
import styled from 'styled-components';
import tbcCoin from '../assets/TuringLiitleCoin.png';
import turingsLogo from '../assets/TuringHome.png';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { PageLoader } from '../components/PageLoader';
import { SpeedBump } from '../components/SpeedBump';
import { QrCode } from '../components/QrCode';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // 引入轮播图样式
import { useViewport } from '../hooks/useViewport';
import * as tbc from "tbc-js"
import {
  ButtonContainer,
  ConfirmContent,
  FormContainer,
  HeaderText,
  MainContent,
  ReceiveContent,
  Text,
} from '../components/Reusable';
import { Show } from '../components/Show';
import { TopNav } from '../components/TopNav';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useTbc } from '../hooks/useTbc';
import { useSnackbar } from '../hooks/useSnackbar';
import { useTheme } from '../hooks/useTheme';
import { useWeb3Context } from '../hooks/useWeb3Context';
import { ColorThemeProps } from '../theme';
import { TBC_DECIMAL_CONVERSION, DUST, GAME_RECEIVE_ADDRESS } from '../utils/constants';
import { formatUSD } from '../utils/format';
import { sleep } from '../utils/sleep';
import copyIcon from '../assets/copy.svg';
import { AssetRow } from '../components/AssetRow';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storage } from '../utils/storage';
import axios from 'axios';

interface Unspent {
  tx_hash: string,
  tx_pos: number,
  height: number,
  value: number
}

// const Content = styled.div<{ $isMobile?: boolean }>`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: ${(props) => (props.$isMobile ? '100%' : '100%')};
//   height: ${(props) => (props.$isMobile ? '75%' : '100%')};
//   overflow-y: auto;
//   overflow-x: hidden;
// `;
const PreContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`;
const TuringsLogo = styled.img`
  width: 200px;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
`;

const MiddleContainer = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 2.75rem 1rem;
`;

const Icon = styled.img<{ size?: string }>`
  width: ${(props) => props.size ?? '1.5rem'};
  height: ${(props) => props.size ?? '1.5rem'};
  margin: 0 1rem 0 0;
`;

const InputAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CopyAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2rem 0;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`;


type PageState = 'preface' | 'main' | 'receive' | 'send' | 'assemble' | 'multiSend' ;
type AmountType = 'tbc' | 'usd';

export type TbcWalletProps = {
  isOrdRequest: boolean;
};


export const TbcWallet = (props: TbcWalletProps) => {
  const { isOrdRequest } = props;
  const { theme } = useTheme();
  const { isMobile } = useViewport();
  const { setSelected } = useBottomMenu();
  const [pageState, setPageState] = useState<PageState>('main');
  const [showSpeedBump, setShowSpeedBump] = useState(false);
  const [decisionType, setDecisionType] = useState<DecisionType | undefined>();
  const [speedBumpMessage, setSpeedBumpMessage] = useState('');
  const [satSendAmount, setSatSendAmount] = useState<number | null>(null);
  const [usdSendAmount, setUsdSendAmount] = useState<number | null>(null);
  const [receiveAddress, setReceiveAddress] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [amountType, setAmountType] = useState<AmountType>('tbc');
  const [successTxId, setSuccessTxId] = useState('');
  const [screenSwitchCount, setscreenSwitchCount] = useState(0);
  const [assembledTransaction, setAssembledTransaction] = useState<SendTbcResponse | null>(null);
  const { addSnackbar, message } = useSnackbar();
  const { isPasswordRequired } = useWeb3Context();
  const { verifyPassword, tbcPubKey, getMultiSignAddressPubkeys } = useKeys();
  const location = useLocation(); // 用于获取当前页面的URL
  const { t } = useTranslation();
  const [tokenAddress, setTokenAddress] = useState('');
  const [multisigAddress, setMultisigAddress] = useState('');
  const [sendAmount, setSendAmount] = useState<number | undefined>(undefined);
  const { tbcAddress, tbcBalance, isProcessing, setIsProcessing, assembleTbc, sendtbc, updateTbcBalance, updateTbcUtxos, exchangeRate, multiSend,
    sendTbcToMulti
  } = useTbc();

  const [showDetails, setShowDetails] = useState(false);

  const [MultiBalance, setMultiBalance] = useState(0)
  const [MultiFTBalance, setMultiFTBalance] = useState<number | string>(0)
  const [pubkeyCount, setPubkeyCount] = useState(0)
  const [sigCount, setSigCount] = useState(0)


  type SendTbcResponse = {
    txid?: string;
    rawtx?: string;
    amount?: number;
    change?: number;
    fee?: number;
    error?: string;
  };

  type DecisionType = 'password-confirm';
  useEffect(() => {
    storage.get('tbc_balance', (result) => {
      // 判断是否存在 tbc_balance，如果没有则跳转到 preface 页面
      if (result['tbc_balance'] === null) {
        setPageState('preface');
      }
    });

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setscreenSwitchCount((Count) => Count + 1); // 刷新余额
        ////console.log(tbcBalance);
        //updateTbcBalance(true);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange); // 清除事件监听器
    };
  }, []); // 仅在组件初次挂载时调用

  useEffect(() => {
    // // 获取URL中的flag参数
    // const params = new URLSearchParams(location.search);
    // const flagValue = params.get('charge/town') === 'true' ? true : false;// 如果flag为true，设置为true
    // // 获取URL的路径部分
    //const pathname = location.pathname;
    const fullUrl = window.location.href;
    ////console.log("pathname",pathname);
    // 检查路径中是否包含 "charge/town"
    const flagValue = fullUrl.includes('charge/town') ? true : false;
    //const flagValue = pathname.includes('tbc-wallet') ? true : false;

    ////console.log("flagValue",flagValue);
    // 根据flag决定是否自动填充收款地址
    if (flagValue) {
      setPageState('send');
      setReceiveAddress(GAME_RECEIVE_ADDRESS); // 自动填充收款地址
    }
    //  else {
    //   setReceiveAddress(''); // 清空收款地址
    // }
  }, [location]);

  useEffect(() => {
    if (screenSwitchCount > 0) {
      updateTbcBalance();
    }
  }, [screenSwitchCount]);

  useEffect(() => {
    if (isOrdRequest) {
      setSelected('ords');
    } else {
      setSelected('tbc');
    }
  }, [isOrdRequest, setSelected]);

  useEffect(() => {
    if (!successTxId) return;
    if (!message && tbcAddress) {
      setPageState('main')
      resetSendState();
      //setPageState('main');
    }
  }, [successTxId, message, tbcAddress]);

  const resetSendState = () => {
    // storage.remove('paymentUtxos');
    // // Give enough time for storage to remove
    // setTimeout(() => {
    //   updateTbcBalance();//这里是自动更新余额
    // }, 3500);
    updateTbcBalance(true);
    setPasswordConfirm('');
    setSatSendAmount(null);
    //setUsdSendAmount(null);
    //setAmountType('tbc');
    setReceiveAddress('');
    setSuccessTxId('');
    setIsProcessing(false);

    //  setTimeout(() => {//这里是零确认修改的地方
    //   //nukeUtxos();
    //   updateTbcBalance(true);
    //   }, 500);

    // //console.log('进行的本地更新');
  };


  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(tbcAddress).then(() => {
      addSnackbar(`${t('Copied')}!`, 'success');
    });
  };

  const toggleAmountType = () => {
    if (amountType === 'tbc') {
      setAmountType('usd');
    } else {
      setAmountType('tbc');
    }
    setUsdSendAmount(null);
    setSatSendAmount(null);
  };

  const handleSpeedBumpConfirm = async (password?: string) => {
    // if (decisionType === 'password-confirm' && password && assembledTransaction?.rawtx) {
    if (assembledTransaction?.rawtx) {
      //setDecisionType(undefined);
      setShowSpeedBump(false);
      // const sendRes = await sendtbc(assembledTransaction.rawtx, password); // 使用弹窗输入的密码和保存的 rawtx
      const sendRes = await sendtbc(assembledTransaction.rawtx); // 使用弹窗输入的密码和保存的 rawtx
      // setDecisionType(undefined);
      // setShowSpeedBump(false);
      //setIsProcessing(true);
      //await sleep(15);
      if (!sendRes.txid) {
        addSnackbar(t('an-error-occurred-while-sending-tbc-please-try-again'), 'error');
        setIsProcessing(false);
        return;
      }
      //setIsProcessing(false);
      setSuccessTxId(sendRes.txid);
      addSnackbar(t('transaction-successful-please-wait-a-few-minutes-for-your-balance-to-refresh'), 'success');
    }
  };
  const handleCancel = () => {
    setIsProcessing(false);
    setPageState('assemble');
    // storage.remove('paymentUtxos');
    // Give enough time for storage to remove
    setTimeout(() => {
      updateTbcBalance();
    }, 50);
    setShowSpeedBump(false);
  };

  const handleSendTbc = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);
    if (!satSendAmount && !usdSendAmount) {
      addSnackbar(t('you-must-enter-an-amount'), 'info');
      setIsProcessing(false);
      return;
    }

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const isVerified = await verifyPassword(passwordConfirm);
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      setIsProcessing(false);
      return;
    }

    let satoshis = satSendAmount ?? 0;
    if (satoshis < DUST) {
      addSnackbar(`${t('output-is-below-dust-threshold')}${DUST}`, 'error');
      setPasswordConfirm('');
      setIsProcessing(false);
      return;
    }
    if (amountType === 'usd' && usdSendAmount) {
      satoshis = Math.ceil((usdSendAmount / exchangeRate) * TBC_DECIMAL_CONVERSION);
    }

    if (receiveAddress.length !== 34) {
      addSnackbar(t('you-must-enter-a-valid-address'), 'info');
      setIsProcessing(false);
      return;
    } else if (!receiveAddress.startsWith("1")) {
      const sendToMultiRes = await sendTbcToMulti(receiveAddress, satoshis, passwordConfirm);
      if (sendToMultiRes.error) {
        // if (!sendRes.txid ) {
        const message = 'Failed to send'
        addSnackbar(message, 'error');
        setPasswordConfirm('');
        return;
      }
      setIsProcessing(false);
      setSuccessTxId('1');
      addSnackbar(t('transaction-successful-please-wait-a-few-minutes-for-your-balance-to-refresh'), 'success');
    }
    else if (!validate(receiveAddress)) {
      addSnackbar(t("you-must-enter-a-valid-tbc-address-paymail-not-yet-supported"), 'info');
      setIsProcessing(false);
      return;
    } else {

      const assembleRes = await assembleTbc([{ address: receiveAddress, satoshis }], passwordConfirm);
      //const sendRes = await sendTbc([{ address: receiveAddress, satoshis }], passwordConfirm);
      if (assembleRes.rawtx == null || assembleRes.error) {
        // if (!sendRes.txid ) {
        const message =
          assembleRes.error === 'invalid-password'
            ? t('invalid-password')
            : assembleRes.error === 'insufficient-funds'
              ? t('insufficient-funds-including-transaction-fees')
              : assembleRes.error === 'fee-too-high'
                ? t('miner-fee-too-high')
                : assembleRes.error === 'output is below DUST'
                  ? `${t('output-is-below-dust-threshold')}${DUST}`
                  : t('an-unknown-error-has-occurred-try-again');//这里是等待确认错误的地方"output is below DUST"
        addSnackbar(message, 'error');
        setIsProcessing(false);
        setPasswordConfirm('');
        return;

      }
      setAssembledTransaction(assembleRes);  // 将 assembleRes 保存到状态中
      setPageState('send');
      //setDecisionType('password-confirm');
      setSpeedBumpMessage(`${t('Check Transaction Details')}:\n${t("Address")}: ${receiveAddress}\n${t("Amount")}: ${assembleRes.amount ? assembleRes.amount / 1e6 : 0}TBC\n${t("Change")}: ${assembleRes.change ? assembleRes.change / 1e6 : 0}TBC\n${t("Fee")}: ${assembleRes.fee}sats`);
      setShowSpeedBump(true);
    }
  };

  const fillInputWithAllTbc = () => {
    setSatSendAmount(Math.round(tbcBalance * TBC_DECIMAL_CONVERSION));
  };

  useEffect(() => {
    const calcValue = () => {
      return amountType === 'tbc'
        ? satSendAmount
          ? satSendAmount / TBC_DECIMAL_CONVERSION
          : undefined
        : amountType === 'usd'
          ? usdSendAmount
            ? usdSendAmount
            : undefined
          : undefined;
    };

    calcValue();
  }, [satSendAmount, usdSendAmount, amountType]);

  const getLabel = () => {
    return amountType === 'tbc' && satSendAmount
      ? `${t('Send')} ${(satSendAmount / TBC_DECIMAL_CONVERSION).toFixed(6)}`
      : amountType === 'usd' && usdSendAmount
        ? `${t('Send')} ${formatUSD(usdSendAmount)}`
        : t('Enter Send Details');
  };

  const receive = (
    <ReceiveContent>
      <HeaderText style={{ marginTop: '1rem' }} theme={theme}>
        {t("Receive TBC")}
      </HeaderText>
      <Text style={{ marginBottom: '1.25rem' }} theme={theme}>
        {t('only-send-tbc-to-this-address')} {/* <Warning theme={theme}>Do not send Ordinals or TBC20 here.</Warning> */}
      </Text>
      <QrCode address={tbcAddress} onClick={handleCopyToClipboard} />
      <CopyAddressWrapper onClick={handleCopyToClipboard}>
        <StyledCopy src={copyIcon} />
        <Text theme={theme} style={{ margin: '0', color: theme.white, fontSize: '0.75rem' }}>
          {tbcAddress}
        </Text>
      </CopyAddressWrapper>
      <Button
        label={t('Go back')}
        theme={theme}
        type="secondary"
        onClick={() => {
          setPageState('main');
          updateTbcBalance();
        }}
      />
    </ReceiveContent>
  );


  const preface = (
    // <>
    //   <Content>
    //     <img src={turingsLogo} style={{width:'20rem',height:'auto'}} /> 
    //     <div style={{ marginTop: "50px", marginBottom: "40px" }}>
    //       <HeaderText theme={theme}>WELCOME!</HeaderText>
    //       <Text theme={theme} style={{ marginBottom: '1rem' }}>
    //        {t('welcome-to-turing-wallet')} </Text>
    //     </div>
    //     <Button
    //       theme={theme}
    //       type="primary"
    //       label={t('enter')}
    //       onClick={() => {
    //         updateTbcBalance();
    //       }}
    //     />
    //   </Content>
    // </>

    <PreContent>
      <img src={turingsLogo} style={{ marginTop: "6rem", width: '15rem', height: 'auto' }} />
      <div style={{ marginTop: "50px", marginBottom: "40px" }}>
        <HeaderText theme={theme}>WELCOME!!</HeaderText>
        <Text theme={theme} style={{ marginBottom: '1rem' }}>
          {t('welcome-to-turing-wallet')} </Text>
      </div>
      <Button
        theme={theme}
        type="primary"
        label={t('enter')}
        onClick={() => {
          updateTbcBalance();
          setPageState('main');
        }}
      />
    </PreContent>
  );
  const main = (
    <MainContent style={{ marginTop: '-3rem' }}>
      <MiddleContainer theme={theme}>
        <TuringsLogo src={turingsLogo} style={{ cursor: 'pointer' }} title="BlanceFresh" onClick={() => { updateTbcBalance()}} />

        <ButtonContainer>
          <Button theme={theme} type="primary" label={t("Receive")} onClick={() => setPageState('receive')} />
          <Button theme={theme} type="primary" label={t("Send")} onClick={() => { updateTbcUtxos(true); setPageState('assemble'); }} />
        </ButtonContainer>
        <AssetRow balance={tbcBalance} icon={tbcCoin} ticker="TBC" usdBalance={tbcBalance * exchangeRate} />
      </MiddleContainer>
    </MainContent>
  );

  const send = (
    <>
      <ConfirmContent>
        <HeaderText theme={theme}>{t('Send TBC')}</HeaderText>
        <Text
          theme={theme}
          style={{ cursor: 'pointer' }}
          onClick={fillInputWithAllTbc}
        >{`${t('Balance')}: ${tbcBalance}`}</Text>
        <FormContainer noValidate onSubmit={(e) => handleSendTbc(e)}>
          <Input
            theme={theme}
            placeholder={t('enter-address')}
            type="text"
            onChange={(e) => setReceiveAddress(e.target.value)}
            value={receiveAddress}
          />
          <InputAmountWrapper>
            <Input
              theme={theme}
              placeholder={amountType === 'tbc' ? t('enter-tbc-amount') : t('enter-usd-amount')}
              type='number'
              step="0.00000001"
              value={
                satSendAmount !== null && satSendAmount !== undefined
                  ? satSendAmount / TBC_DECIMAL_CONVERSION
                  : usdSendAmount !== null && usdSendAmount !== undefined
                    ? usdSendAmount
                    : ''
              }
              onChange={(e) => {
                const inputValue = e.target.value;
                // Check if the input is empty and if so, set the state to null

                if (inputValue === '') {
                  setSatSendAmount(null);
                  setUsdSendAmount(null);
                } else {
                  // Existing logic for setting state
                  if (amountType === 'tbc') {
                    setSatSendAmount(Math.round(Number(inputValue) * TBC_DECIMAL_CONVERSION));
                  } else {
                    setUsdSendAmount(Number(inputValue));
                  }
                }
              }}
            />
            {/* <Icon
              src={switchAsset}
              size="1rem"
              style={{
                position: 'absolute',
                right: '2.25rem',
                cursor: 'pointer',
              }}
              onClick={toggleAmountType}
            /> */}
          </InputAmountWrapper>
          <Show when={isPasswordRequired}>
            <Input
              theme={theme}
              placeholder={t('enter-wallet-password')}
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Show>
          <Button
            theme={theme}
            type="primary"
            label={getLabel()}
            disabled={isProcessing || (!usdSendAmount && !satSendAmount) ||
              (satSendAmount !== null && satSendAmount <= 0)}
            isSubmit
          />
        </FormContainer>
        <Button
          label={t('Go back')}
          theme={theme}
          type="secondary"
          onClick={() => {
            setPageState('main');
            updateTbcBalance();
            resetSendState();
          }}
        />
        <Text style={{ margin: '1rem' }} theme={theme}>
          ------ OR ------
        </Text>
        <Button
          theme={theme}
          type="primary"
          label={t('from-multiSend')}
          onClick={() => {
            setPageState('multiSend');
          }}
        />
        
        {/* <Button
          label='muli'
          theme={theme}
          type="primary"
        
            onClick={() => navigate('/create-ms-wallet')}
        /> */}
      </ConfirmContent>
    </>
  );

  const handleShowDetails = () => {
    setShowDetails(true);
    setTimeout(() => {
      setShowDetails(false);
    }, 10000);
  };

  const handleSendTransaction = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    if (multisigAddress.length !== 34) {
      addSnackbar(t('you-must-enter-a-valid-address'), 'info');
      setIsProcessing(false);
      return;
    } else if (multisigAddress.startsWith("1")) {
      addSnackbar(t('you-must-enter-a-valid-multisig-address'), 'info');
      setIsProcessing(false);
      return;
    }

    const pubkeys = getMultiSignAddressPubkeys(multisigAddress);
    if (pubkeys.length === 0) {
      addSnackbar(t('you-must-import-the-multisig-wallet'), 'info');
      setIsProcessing(false);
      return;
    } else {
      if (!pubkeys.includes(tbcPubKey)) {
        addSnackbar(t('you-must-use-your-multisig-wallet'), 'info');
        setIsProcessing(false);
        return;
      }
    }

    let multiPublicKeys: tbc.PublicKey[] = [];

    for (let i = 0; i < pubkeys.length; i++) {
      multiPublicKeys.push(tbc.PublicKey.fromString(pubkeys[i]));
    }
    const multisig = new tbc.Multisig();

    // if (!multisig.verifyMultisigAddress(multiPublicKeys, multisigAddress)) {
    //   addSnackbar(t('your-address-does-not-match-pubkeys'), 'info');
    //   setIsProcessing(false);
    //   return;
    // }

    if (receiveAddress.length !== 34) {
      addSnackbar(t('you-must-enter-a-valid-address'), 'info');
      setIsProcessing(false);
      return;
    }

    if (!sendAmount) {
      addSnackbar(t('you-must-enter-an-amount'), 'info');
      setIsProcessing(false);
      return;
    }

    if (!passwordConfirm) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const isVerified = await verifyPassword(passwordConfirm);
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const url = "https://turingwallet.xyz/multy/sig/create/history";

    if (tokenAddress.length === 64) {
      try {
        const { multiTxraw, sigs } = await multiSend(multisigAddress, tokenAddress, receiveAddress, sendAmount, passwordConfirm);
        const ft = new tbc.FT(tokenAddress);
        await ft.initialize()

        const { txraw, amounts } = multiTxraw!;
        const request = {
          unsigned_txid: tbc.crypto.Hash.sha256sha256(Buffer.from(txraw, "hex")).toString("hex"),
          tx_raw: txraw,
          vin_balance_list: amounts,
          multi_sig_address: multisigAddress,
          ft_contract_id: tokenAddress,
          ft_decimal: ft.decimal,
          balance: sendAmount * Math.pow(10, ft.decimal),
          receiver_addresses: [receiveAddress],
          pubkey_list: pubkeys,
          signature_data: {
            pubkey: tbcPubKey,
            sig_list: sigs
          }
        }

        const response = await axios.post(url, request);

        const { status, message } = response.data;

        if (status === 0) {
          addSnackbar(t('send-successful-please-wait-all-sigs-finish'), 'success');
          setSuccessTxId(request.unsigned_txid);
        } else {
          throw new Error(`Transaction failed: ${message}`);
        }

      } catch (error) {
        addSnackbar(t('fail-transfer-token'), 'error');
        setIsProcessing(false);
        return;
      }

    } else if (tokenAddress.length === 0) {
      try {
        const { multiTxraw, sigs } = await multiSend(multisigAddress, undefined, receiveAddress, sendAmount, passwordConfirm);
        const { txraw, amounts } = multiTxraw!;
        const request = {
          unsigned_txid: tbc.crypto.Hash.sha256sha256(Buffer.from(txraw, "hex")).toString("hex"),
          tx_raw: txraw,
          vin_balance_list: amounts,
          multi_sig_address: multisigAddress,
          ft_contract_id: "",
          ft_decimal: 6,
          balance: sendAmount * 1e6,
          receiver_addresses: [receiveAddress],
          pubkey_list: pubkeys,
          signature_data: {
            pubkey: tbcPubKey,
            sig_list: sigs
          }
        }

        const response = await axios.post(url, request);

        const { status, message } = response.data;

        if (status === 0) {
          addSnackbar(t('send-successful-please-wait-all-sigs-finish'), 'success');
          console.log(response.data);

          setSuccessTxId(request.unsigned_txid);
        } else {
          throw new Error(`Transaction failed: ${message}`);
        }
      } catch (error) {
        addSnackbar(t('fail-send-tbc'), 'error');
        setIsProcessing(false);
        return;
      }
    } else {
      addSnackbar(t('you-must-enter-a-valid-token-address'), 'error');
      setIsProcessing(false);
      return;
    }

    // // Mock transaction success
    // setTimeout(() => {
    //   setSuccessTxId('mock-txid-12345');
    //   addSnackbar(t('transaction-successful'), 'success');
    // }, 2000);
  };


  const showMultiDetails = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (multisigAddress.length !== 34) {
      addSnackbar(t('you-must-enter-a-valid-address'), 'info');
      setIsProcessing(false);
      return;
    } else if (multisigAddress.startsWith("1")) {
      addSnackbar(t('you-must-enter-a-valid-multisig-address'), 'info');
      setIsProcessing(false);
      return;
    }
    const pubkeys = getMultiSignAddressPubkeys(multisigAddress);
    if (pubkeys.length === 0) {
      addSnackbar(t('you-must-import-the-multisig-wallet'), 'info');
      setIsProcessing(false);
      return;
    } else {
      if (!pubkeys.includes(tbcPubKey)) {
        addSnackbar(t('you-must-use-your-multisig-wallet'), 'info');
        setIsProcessing(false);
        return;
      }
    }

    setMultiFTBalance(0);
    let multisig;
    if (tokenAddress.length === 64) {
      const ft = new tbc.FT(tokenAddress);
      try { await ft.initialize() }
      catch (error) {
        addSnackbar(t('invaild-ft-address'), 'error');
        setIsProcessing(false);
        return;
      }
      multisig = new tbc.Multisig({ ft });
    } else if (tokenAddress.length === 0) {
      multisig = new tbc.Multisig();
      setMultiFTBalance("no token address");
    } else {
      addSnackbar(t('you-must-enter-a-valid-address'), 'error');
      setIsProcessing(false);
      return;
    }
    let multiPublicKeys: tbc.PublicKey[] = [];

    for (let i = 0; i < pubkeys.length; i++) {
      multiPublicKeys.push(tbc.PublicKey.fromString(pubkeys[i]));
    }
    // if (!multisig.verifyMultisigAddress(multiPublicKeys, multisigAddress)) {
    //   addSnackbar(t('your-address-does-not-match-pubkeys'), 'info');
    //   setIsProcessing(false);
    //   return;
    // }
    const { signatureCount, publicKeyCount } = multisig.getSignatureAndPublicKeyCount(multisigAddress);
    setPubkeyCount(publicKeyCount);
    setSigCount(signatureCount);
    const asmString = multisig.getMultisigLockScript(multisigAddress);
    const multiScript = tbc.Script.fromASM(asmString).toHex();
    const hash_tbc = Buffer.from(tbc.crypto.Hash.sha256(Buffer.from(multiScript, "hex")).toString("hex"), "hex").reverse().toString("hex");
    const url_mainnet_tbc = `https://turingwallet.xyz/v1/tbc/main/script/hash/${hash_tbc}/unspent/`;
    try {
      const response = await fetch(url_mainnet_tbc);
      if (!response.ok) {
        addSnackbar(t('network error'), 'error');
        setIsProcessing(false);
        return;;
      }
      const data_tbc = await response.json();

      const umtxos = data_tbc.map((utxo: Unspent) => {
        return {
          txId: utxo.tx_hash,
          outputIndex: utxo.tx_pos,
          script: multiScript,
          satoshis: utxo.value
        } as tbc.Transaction.IUnspentOutput;
      });
      let balance = 0;
      for (let i = 0; i < umtxos.length; i++) {
        balance += umtxos[i].satoshis;
      }
      setMultiBalance(balance * 1e-6);
    } catch (error) {
      addSnackbar(t('please check your address.'), 'error');
      setIsProcessing(false);
      return;;
    }
    if (multisig.ft) {
      const hash_ft = multisig.getCombineHash(multisigAddress);

      const url_mainnet_ft = `https://turingwallet.xyz/v1/tbc/main/ft/balance/combine/script/${hash_ft}/contract/${tokenAddress}`;
      try {
        const response = await axios.get(url_mainnet_ft);
        const ftBalance = response.data.ftBalance;

        setMultiFTBalance(ftBalance * Math.pow(10, -multisig.ft.decimal));
      } catch (error) {
        addSnackbar(t('please check your token address.'), 'error');
        setIsProcessing(false);
        return;;
      }
    }
    handleShowDetails();
  }

  const multiSendView = (
    <>
      <ConfirmContent style={{marginTop:'2rem',overflowY:'auto',overflowX:'hidden'}}>
        <div >
          <HeaderText theme={theme} style={{ color: theme.primaryButton }}>
            {t('Multi-Signature-Tx')}
          </HeaderText>
          {/* <HeaderText theme={theme} style={{ color: theme.primaryButton, marginBottom: '0.5rem' }}>
            {t('Transaction')}
          </HeaderText> */}
        </div>
        <FormContainer noValidate onSubmit={(e) => handleSendTransaction(e)}>
          <Input
            theme={theme}
            placeholder={t('multisig-address')}
            type="text"
            value={multisigAddress}
            onChange={(e) => setMultisigAddress(e.target.value)}
          />
          <Input
            theme={theme}
            placeholder={t('token-address')}
            type="text"
            value={tokenAddress}
            onChange={(e) => setTokenAddress(e.target.value)}
          />
          <Input
            theme={theme}
            placeholder={t('recipient-address')}
            type="text"
            value={receiveAddress}
            onChange={(e) => setReceiveAddress(e.target.value)}
          />
          <Input
            theme={theme}
            placeholder={t('Amount')}
            type="number"
            value={sendAmount}
            onChange={(e) => setSendAmount(Number(e.target.value))}
          />
          <Show when={isPasswordRequired}>
            <Input
              theme={theme}
              placeholder={t('enter-wallet-password')}
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Show>
          <Show when={showDetails}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              textAlign: 'left',
              padding: '16px',
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            }}>
              <div style={{
                fontSize: '1rem',
                marginBottom: '12px',
                fontWeight: '500',
              }}>
                <span style={{ color: theme.primaryButton, fontWeight: '600' }}>{t('type')}:{sigCount}/{pubkeyCount}</span>
              </div>
              <div style={{
                fontSize: '1rem',
                marginBottom: '12px',
                fontWeight: '500',
              }}>
                <span style={{ color: theme.primaryButton, fontWeight: '600' }}>{t('tbc_balance')}:{MultiBalance}</span>
              </div>
              <div style={{
                fontSize: '1rem',
                marginBottom: '12px',
                fontWeight: '500',
              }}>
                <span style={{ color: theme.primaryButton, fontWeight: '600' }}>{t('ft_balance')}:{MultiFTBalance}</span>
              </div>
            </div>
          </Show>
          <Button theme={theme} type="primary" label={t('confirm')} disabled={isProcessing} isSubmit />
        </FormContainer>
        <Button
          label={t('Go back')}
          theme={theme}
          type="secondary"
          onClick={() => {
            setPageState('send');
            setShowDetails(false);
          }}
        />
         <Text style={{ margin: '1rem' }} theme={theme}>
          ------ AND ------
        </Text>
        <Button
          label={t('multiSig Detail')}
          theme={theme}
          type="secondary"
          onClick={(e) => showMultiDetails(e)}
        />
      </ConfirmContent >
    </>
  );

  return (
    <Show
      when={!showSpeedBump}
      whenFalseContent={
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <SpeedBump
            theme={theme}
            message={speedBumpMessage}
            onCancel={handleCancel}
            onConfirm={(password?: string) => handleSpeedBumpConfirm(password)}
            showSpeedBump={showSpeedBump}
            withPassword={decisionType === 'password-confirm'}
          />
        </div>
      }
    >
      {/* <Content $isMobile={isMobile}> */}
      <Content>
        <TopNav />
        <Show when={isProcessing && pageState === 'main'}>
          <PageLoader theme={theme} message={t('loading-wallet')} />
        </Show>
        <Show when={isProcessing && pageState === 'assemble'}>
          <PageLoader theme={theme} message={t('assembling-transaction')} />
        </Show>
        <Show when={isProcessing && pageState === 'send'}>
          <PageLoader theme={theme} message={t('sending-tbc')} />
        </Show>
        <Show when={!isProcessing && pageState === 'preface'}>{preface}</Show>
        <Show when={!isProcessing && pageState === 'main'}>{main}</Show>
        <Show when={!isProcessing && pageState === 'receive'}>{receive}</Show>
        <Show when={!isProcessing && pageState === 'assemble'}>{send}</Show>
        <Show when={!isProcessing && pageState === 'send'}>{send}</Show>
        <Show when={!isProcessing && pageState === 'multiSend'}>{multiSendView}</Show>
      </Content>
    </Show>
  );
};
