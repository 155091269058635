import { useState } from 'react';
import { styled } from 'styled-components';
import { useKeys } from '../hooks/useKeys';
import { useTheme } from '../hooks/useTheme';
import { useViewport } from '../hooks/useViewport';
import { ColorThemeProps } from '../theme';
import { storage } from '../utils/storage';
import { Button } from './Button';
import { Input } from './Input';
import { PageLoader } from '../components/PageLoader';
import turingsLogo from '../assets/TuringBox.png';
import { FormContainer, HeaderText, Text, BoxLogo } from './Reusable';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../hooks/useSnackbar';
import { sleep } from '../utils/sleep';

const Container = styled.div<ColorThemeProps & { $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${(props) => (props.$isMobile ? '100vw' : '22.5rem')};
  height: ${(props) => (props.$isMobile ? '100vh' : '33.75rem')};
  margin: 0;
  background-color: ${({ theme }) => theme.mainBackground};
  color: ${({ theme }) => theme.white};
  z-index: 100;
`;

export type UnlockWalletProps = {
  onUnlock: () => void;
};

export const UnlockWallet = (props: UnlockWalletProps) => {
  const { onUnlock } = props;
  const { theme } = useTheme();
  const [password, setPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const { isMobile } = useViewport();
  const [loading, setLoading] = useState(false);
  const { addSnackbar} = useSnackbar();
  const { verifyPassword } = useKeys();
  const { t } = useTranslation();

  const handleUnlock = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setIsProcessing(true);
    await sleep(25);
    const isVerified = await verifyPassword(password);
    if (isVerified) {
      setVerificationFailed(false);
      const timestamp = Date.now();
      storage.set({ lastActiveTime: timestamp });
      onUnlock();
    } else {
      addSnackbar(`${t('Invalid Password')}!`, 'error');
      setVerificationFailed(true);
      setPassword('');
      setVerificationFailed(false);
      setIsProcessing(false);
      setLoading(false);
    }
  };


  return (
    <>
    {loading? <PageLoader theme={theme} message={t('unlocking')} /> :(
    <Container $isMobile={isMobile} theme={theme}>
      <BoxLogo src={turingsLogo} style={{width:"auto"}}/>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <HeaderText style={{ fontSize: '1.75rem' }} theme={theme}>
          {t('unlock-wallet')} </HeaderText>
        <Text theme={theme}>{t('use-password-to-unlock-your-wallet')}</Text>
      </div>
      <FormContainer onSubmit={handleUnlock}>
        <Input
          theme={theme}
          placeholder={t('password')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          shake={verificationFailed ? 'true' : 'false'}
        />
        <Button
          theme={theme}
          type="primary"
          label={isProcessing ? t('unlocking') : t('unlock')}
          disabled={isProcessing}
          isSubmit
        />
        {/* <Button
          theme={theme}
          type="secondary-outline"
          label={'Sign Out'}
          onClick={() => {
          storage.clear(); 
          window.location.reload();}}
        /> */}
      </FormContainer>
    </Container>)}
    </>
  );
};
