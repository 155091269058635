// IMPORTANT NOTE: Uncomment everything that is commented back out to re-enable the sponser page should it ever make sense
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import externalLink from '../assets/external-link.svg';
import { Button } from '../components/Button';
import magnifier from '../assets/magnifier.svg';
import { Input } from '../components/Input';
import { PageLoader } from '../components/PageLoader';
// import turingsLogo from '../assets/turings-logo.png';
// import { HeaderText, Text, TuringsLogo } from '../components/Reusable';
// import { HeaderText, Text } from '../components/Reusable';
import { Text } from '../components/Reusable';
import { Show } from '../components/Show';
import { NFTTxo } from '../hooks/ordTypes';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useTbc } from '../hooks/useTbc';
import { useContracts } from '../hooks/useContracts';
import { useTBChainPyPool } from '../hooks/useTBChainPyPool';
import { usePasswordSetting } from '../hooks/usePasswordSetting';
import { useSnackbar } from '../hooks/useSnackbar';
import { useTheme } from '../hooks/useTheme';
import { useTuringBitChain } from '../hooks/useTuringBitChain';
import { ColorThemeProps } from '../theme';
import { TBC_DECIMAL_CONVERSION, featuredApps, TURINGS_GITHUB_REPOS} from '../utils/constants';
import { truncate } from '../utils/format';
import { sleep } from '../utils/sleep';
// import { TbcSendRequest } from './requests/TbcSendRequest';
import { TopNav } from '../components/TopNav';
import { AssetRow } from '../components/AssetRow';
import tbcCoin from '../assets/TuringLiitleCoin.png';
// import turingTownLogo1 from '../assets/turing-town-1.jpg'; // 示例图片
import { useTranslation } from 'react-i18next';

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const PageWrapper = styled.div<{ $marginTop: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.$marginTop};
  width: 100%;
`;

const SearchBarContainer = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.darkAccent};
  border-radius: 8px;
  margin: 2px 0;
  width: 90%;
  max-width: 600px;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px); /* Adjust height based on other components */
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  transform-origin: top left;
  transform: scale(1);
`;
const SearchImg = styled.img`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 8px;
  font-size: 18px;
`;

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
`;

const DiscoverAppsRow = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.darkAccent};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.25rem;
  width: 80%;
  cursor: pointer;
`;

const ImageAndDomain = styled.div`
  display: flex;
  align-items: center;
`;

const AppIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
`;

const DiscoverAppsText = styled(Text)<ColorThemeProps>`
  color: ${({ theme }) => theme.white};
  margin: 0;
  font-weight: 600;
  text-align: left;
`;

const ExternalLinkIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const LockDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  width: 80%;
`;

const SearchHistoryContainer = styled.div<ColorThemeProps>`
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 8px;
`;

const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const HistoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background: #fff;
  margin-bottom: 4px;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
`;

const SelectAllButton = styled.button`
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
`;

const TrashButton = styled.button`
  background: none;
  border: none;
  color: #ff4d4f;
  font-size: 20px;
  cursor: pointer;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;
const LockDetailsText = styled(Text)<ColorThemeProps>`
  margin: 0;
  color: ${(props) => props.theme.white};
`;

const LockDetailsHeaderText = styled(LockDetailsText)`
  font-size: 1rem;
  font-weight: 600;
`;

type AppsPage = 'main' | 'sponsor' | 'sponsor-thanks' | 'discover-apps' | 'unlock';

export const AppsAndTools = () => {
  const { tbcAddress } = useTbc();
  const { theme } = useTheme();
  const { setSelected } = useBottomMenu();
  // const { exchangeRate, identityAddress } = useTbc();
  const { identityAddress } = useTbc();
  const { getLockedUtxos, getSpentTxids } = useTBChainPyPool();
  const { getChainInfo } = useTuringBitChain();
  const { isPasswordRequired } = usePasswordSetting();
  const { addSnackbar } = useSnackbar();
  const { unlock, isProcessing, setIsProcessing } = useContracts();
  const [page, setPage] = useState<AppsPage>('main');
  // const [otherIsSelected, setOtherIsSelected] = useState(false);
  // const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  // const [satAmount, setSatAmount] = useState(0);
  // const [didSubmit, setDidSubmit] = useState(false);
  const [lockedUtxos, setLockedUtxos] = useState<NFTTxo[]>([]);
  const [currentBlockHeight, setCurrentBlockHeight] = useState(0);
  const [totalLocked, setTotalLocked] = useState(0);
  const [totalUnlockable, setTotalUnlockable] = useState(0);
  const [showingLockDetails, setShowingLockDetails] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [query, setQuery] = useState(''); // 用于存储搜索输入框的值
  const [iframeSrc, setIframeSrc] = useState(''); // 记录要加载的 iframe URL
  const [history, setHistory] = useState<string[]>([]); // 搜索历史记录
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set()); 
  const { t } = useTranslation();

  const getLockData = async () => {
    setIsProcessing(true);
    if (!identityAddress) throw Error(t('identity-address-missing'));
    const chainInfo = await getChainInfo();
    let lockedTxos = await getLockedUtxos(identityAddress);
    const blockHeight = Number(chainInfo?.blocks);
    if (blockHeight) setCurrentBlockHeight(blockHeight);
    const outpoints = lockedTxos.map((txo) => txo.outpoint.toString());
    const spentTxids = await getSpentTxids(outpoints);
    lockedTxos = lockedTxos.filter((txo) => !spentTxids.get(txo.outpoint.toString()));
    if (lockedTxos.length > 0) setLockedUtxos(lockedTxos);
    const lockTotal = lockedTxos.reduce((a: number, utxo: NFTTxo) => a + utxo.satoshis, 0);

    let unlockableTotal = 0;
    lockedTxos.forEach((txo) => {
      const theBlockCoinsUnlock = Number(txo?.data?.lock?.until);
      if (theBlockCoinsUnlock <= blockHeight) {
        unlockableTotal += txo.satoshis;
      }
    });

    setTotalLocked(lockTotal);
    setTotalUnlockable(unlockableTotal);
    setIsProcessing(false);
  };

  useEffect(() => {
    if (page === 'unlock' && identityAddress) {
      getLockData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityAddress, page]);

  useEffect(() => {
    setSelected('apps');
  }, [setSelected]);

  // useEffect(() => {
  //   if (!satAmount) return;
  //   setDidSubmit(true);
  // }, [satAmount]);

  // const handleSubmit = (amount: number) => {
  //   if (!amount || !exchangeRate) return;

  //   const satAmount = Math.round((amount / exchangeRate) * TBC_DECIMAL_CONVERSION);
  //   setSatAmount(satAmount);
  // };

  const toggleShowingLockDetails = () => setShowingLockDetails(!showingLockDetails);

  const handleUnlock = async () => {
    setIsProcessing(true);
    setIsUnlocking(true);
    await sleep(25);

    if (!totalUnlockable) {
      addSnackbar(t('there-are-no-coins-to-unlock'), 'info');
      setIsProcessing(false);
      setIsUnlocking(false);
      return;
    }

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      setIsUnlocking(false);
      return;
    }

    const txos = lockedUtxos.filter((i) => Number(i.data?.lock?.until) <= currentBlockHeight);
    const unlockRes = await unlock(txos, passwordConfirm, currentBlockHeight);
    if (!unlockRes.txid || unlockRes.error) {
      const message =
        unlockRes.error === 'invalid-password'
          ? t('invalid-password')
          : unlockRes.error === 'insufficient-funds'
            ? t('insufficient-funds')
            : unlockRes.error === 'broadcast-error'
              ? t('there-was-an-error-broadcasting-the-tx')
              : t('an-unknown-error-has-occurred-try-again');

      addSnackbar(message, 'error');
      setPasswordConfirm('');
      setIsUnlocking(false);
      return;
    }

    addSnackbar(t('coins-unlocked-successfully'), 'success');
    setIsUnlocking(false);
    setTimeout(getLockData, 2550);
  };

  const handleCarouselClick = (url: string) => {
    window.open(url, '_blank'); // 点击时跳转到新标签页
  };
  const turingTownUrl = "https://game.turingtown.org/index.html?tbcAddress=" + tbcAddress;


  useEffect(() => {
    // 初始化加载搜索历史
    const savedHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]');
    setHistory(savedHistory);
  }, []);

  const addToHistory = (query: string) => {
    const updatedHistory = [query, ...history.filter((item) => item !== query)];
    setHistory(updatedHistory);
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory)); // 保存到 localStorage
  };

  const deleteSingleItem = (item: string) => {
    const updatedHistory = history.filter((entry) => entry !== item);
    setHistory(updatedHistory);
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
  };

  const deleteSelectedItems = () => {
    const updatedHistory = history.filter((item) => !selectedItems.has(item));
    setHistory(updatedHistory);
    setSelectedItems(new Set());
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
  };

  const toggleSelectItem = (item: string) => {
    const newSelectedItems = new Set(selectedItems);
    if (selectedItems.has(item)) {
      newSelectedItems.delete(item);
    } else {
      newSelectedItems.add(item);
    }
    setSelectedItems(newSelectedItems);
  };

  const selectAllItems = () => {
    if (selectedItems.size === history.length) {
      setSelectedItems(new Set()); // 全取消
    } else {
      setSelectedItems(new Set(history)); // 全选
    }
  };

  const searchAndOpenUrl = () => {
    if (!query) return;
    addToHistory(query); // 将搜索添加到历史记录
    let searchUrl;
    if (query.startsWith('http')) {
      searchUrl = query.replace(/^https?:\/\/(www\.)?/, 'https://m.');
    } else {
      searchUrl = `https://m.baidu.com/s?wd=${encodeURIComponent(query)}`;
    }
    setIframeSrc(searchUrl);
  };
  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchAndOpenUrl();
    }
  };



  const main = (
    <>
      {/* <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div style={{ padding: '10px', background: '#f1f1f1', borderBottom: '1px solid #ccc' }}>
        <input
          type="text"
          placeholder="请输入网址或搜索内容"
          onKeyDown={handleSearch}
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '16px',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
      </div>

      <div style={{ flex: 1 }}>
        <iframe
          src={url}
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="Internal Browser"
        />
      </div>
    </div> */}

      <SearchBarContainer>
        <Input
          type="text"
          theme={theme}
          placeholder={t('link-or-search')}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleSearchKeyDown} // 按下 Enter 键时调用搜索逻辑
        />
        <SearchImg onClick={searchAndOpenUrl} src={magnifier}></SearchImg>
      </SearchBarContainer>
      {/* <SearchHistoryContainer>
        <HistoryHeader>
          <Text style={{ fontWeight: 'bold' }}>{t('search-history')}</Text>
          <div>
            <SelectAllButton onClick={selectAllItems}>
              {selectedItems.size === history.length ? t('deselect-all') : t('select-all')}
            </SelectAllButton>
            <TrashButton onClick={deleteSelectedItems}>🗑️</TrashButton>
          </div>
        </HistoryHeader>
        {history.map((item) => (
          <HistoryItem key={item}>
            <Checkbox
              type="checkbox"
              checked={selectedItems.has(item)}
              onChange={() => toggleSelectItem(item)}
            />
            <Text style={{ flex: 1, margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {item}
            </Text>
            <DeleteButton onClick={() => deleteSingleItem(item)}>✖</DeleteButton>
          </HistoryItem>
        ))}
      </SearchHistoryContainer>
      */}
      <IframeContainer>
        <Show when={iframeSrc !== ''}>
          <Iframe src={iframeSrc} title="Internal Browser" />
        </Show>
      </IframeContainer>
      
        {/* <div style={{ position: 'relative', cursor: 'pointer', marginTop: '20px', textAlign: 'center' }} onClick={() => handleCarouselClick(turingTownUrl)}>
        <img src={turingTownLogo1} alt="Turing Town" style={{ maxWidth: '97%', height: 'auto' }} />
        <p className="legend" style={{ fontSize: '14px', marginTop: '10px' }}>Play now!</p>

        <div style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: 'bold',
        }}>
          Ad
        </div>
      </div> */}
    </>
  );

  const headerLockDetailsRow = (
    <LockDetailsContainer>
      <LockDetailsHeaderText style={{ textAlign: 'left' }} theme={theme}>
      {t('txid')}
      </LockDetailsHeaderText>
      <LockDetailsHeaderText style={{ textAlign: 'right' }} theme={theme}>
        {t('blocks-left')} </LockDetailsHeaderText>
      <LockDetailsHeaderText style={{ textAlign: 'right' }} theme={theme}>
        {t('Amount')} </LockDetailsHeaderText>
    </LockDetailsContainer>
  );

  const unlockPage = (
    <PageWrapper $marginTop={'7rem'}>
      <AssetRow
        ticker={t('total-locked')}
        balance={totalLocked / TBC_DECIMAL_CONVERSION}
        usdBalance={Number((totalUnlockable / TBC_DECIMAL_CONVERSION).toFixed(3))}
        icon={tbcCoin}
        isUnlockPage
      />
      <Show when={isPasswordRequired}>
        <Input
          theme={theme}
          placeholder={t('enter-wallet-password')}
          type="password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
      </Show>
      <Button
        theme={theme}
        type="primary"
        label={`${t('unlock')} ${totalUnlockable / TBC_DECIMAL_CONVERSION} TBC`}
        onClick={handleUnlock}
      />
      <Button
        theme={theme}
        type="secondary"
        label={showingLockDetails ? t('hide-pending') : t('show-pending')}
        onClick={toggleShowingLockDetails}
        style={{ marginBottom: showingLockDetails ? '1rem' : '0' }}
      />
      <Show when={!showingLockDetails}>
        <Button
          style={{ marginTop: '0.5rem' }}
          theme={theme}
          type="secondary"
          label={t('Go back')}
          onClick={() => setPage('main')}
        />
      </Show>
      <Show when={showingLockDetails}>
        {headerLockDetailsRow}
        {lockedUtxos
          .sort((a, b) => Number(a.data?.lock?.until) - Number(b.data?.lock?.until))
          .map((u) => {
            const blocksRemaining = Number(u.data?.lock?.until) - currentBlockHeight;
            return (
              <LockDetailsContainer key={u.nftUtxoId}>
                <LockDetailsText style={{ textAlign: 'left' }} theme={theme}>
                  {truncate(u.nftUtxoId, 4, 4)}
                </LockDetailsText>
                <LockDetailsText style={{ textAlign: 'center' }} theme={theme}>
                  {blocksRemaining < 0 ? '0' : blocksRemaining}
                </LockDetailsText>
                <LockDetailsText style={{ textAlign: 'right' }} theme={theme}>
                  {u.satoshis < 1000
                    ? `${u.satoshis} ${u.satoshis > 1 ? 'sats' : 'sat'}`
                    : `${u.satoshis / TBC_DECIMAL_CONVERSION} TBC`}
                </LockDetailsText>
              </LockDetailsContainer>
            );
          })}
      </Show>
    </PageWrapper>
  );

  const discoverAppsPage = (
    <PageWrapper $marginTop={featuredApps.length === 0 ? '10rem' : '0'}>
      <Show when={featuredApps.length > 0} whenFalseContent={<Text theme={theme}>No apps</Text>}>
        <Text theme={theme} style={{ marginBottom: 0 }}>
          {t('if-your-app-has-integrated-turing-wallet-but-is-not-listed')},{' '}
          <a href={TURINGS_GITHUB_REPOS} rel="noreferrer" target="_blank" style={{ color: theme.white }}>
            {t('let-us-know')} </a>
        </Text>
        <ScrollableContainer>
          {featuredApps.map((app, idx) => {
            return (
              <DiscoverAppsRow key={app.name + idx} theme={theme} onClick={() => window.open(app.link, '_blank')}>
                <ImageAndDomain>
                  <AppIcon src={app.icon} />
                  <DiscoverAppsText theme={theme}>{app.name}</DiscoverAppsText>
                </ImageAndDomain>
                <ExternalLinkIcon src={externalLink} />
              </DiscoverAppsRow>
            );
          })}
          <Button
            style={{ marginTop: '2rem' }}
            theme={theme}
            type="secondary"
            label={t('Go back')}
            onClick={() => setPage('main')}
          />
        </ScrollableContainer>
      </Show>
    </PageWrapper>
  );

  return (
    <Content>
      <TopNav />
      <Show when={isProcessing && page === 'unlock'}>
        <PageLoader theme={theme} message={isUnlocking ? t('unlocking-coins') : t('gathering-info')} />
      </Show>
      <Show when={page === 'main'}>{main}</Show>
      {/* <Show when={page === 'sponsor' && !didSubmit}>{sponsorPage}</Show> */}
      {/* <Show when={page === 'sponsor-thanks'}>{thankYouSponsorPage}</Show> */}
      <Show when={!isProcessing && page === 'unlock'}>{unlockPage}</Show>
      <Show when={page === 'discover-apps'}>{discoverAppsPage}</Show>
      {/* <Show when={page === 'sponsor' && didSubmit}>
        <TbcSendRequest
          web3Request={[{ address: TURING_DEV_WALLET, satoshis: satAmount }]}
          popupId={undefined}
          onResponse={() => {
            setDidSubmit(false);
            setPage('sponsor-thanks');
          }}
          requestWithinApp
        />
      </Show> */}
    </Content>
  );
};
