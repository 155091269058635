import init, { P2PKHAddress, PrivateKey, Script, SigHash, Transaction, TxIn, TxOut, Hash } from 'bsv-wasm-web';
import { FT } from 'tbc-js'
import * as tbc from 'tbc-js';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  FEE_PER_BYTE,
  FEE_SATS,
  GP_BASE_URL,
  GP_TESTNET_BASE_URL,
  MAX_FEE_PER_TX,
  O_LOCK_SUFFIX,
  P2PKH_INPUT_SIZE,
  P2PKH_OUTPUT_SIZE,
  NFT_CONTRACT_SIZE,
  MAX_BYTES_PER_TX,
  SCRYPT_PREFIX,
  DUST,
  NFT_LOCKING_AMOUNT,
  CONTRACT_LOCKING_AMOUNT,
  PAGE_SIZE,
} from '../utils/constants';
import { NetWork } from '../utils/network';
import { useTBChainPyPool } from './useTBChainPyPool';
import { useKeys } from './useKeys';
import { useNetwork } from './useNetwork';
import { useTuringBitChain } from './useTuringBitChain';
import { Outpoint } from '../utils/outpoint';
import { NFTTxo, NftData, TBC20Txo, CollectionData, COLLECTIONTxo } from './ordTypes';
import { UTXO } from './useTbc';
import axios from 'axios';
import { generateThumbnail } from '../utils/thumbnail'; // 引入生成缩略图的工具函数
import { idbstorage } from '../utils/idbstorage';
import { sleep } from '../utils/sleep';
import validate from 'bitcoin-address-validation';


export class InscriptionData {
  type?: string = '';
  data?: Buffer = Buffer.alloc(0);
}

export type OrdOperationResponse = {
  txid?: string;
  error?: string;
};

export type ChangeInfo = { change: number; changeVout: number };

export type BuildAndBroadcastResponse = {
  txid: string;
  rawTx: string;
  changeInfo: ChangeInfo;
};

export type GPArcResponse = {
  blockHash: string;
  blockHeight: number;
  extraInfo: string;
  status: number;
  timestamp: string;
  title: string;
  txStatus: string;
  txid: string;
};

export interface TBC20 {
  id: string;
  code: string;
  tape: string;
  name?: string;
  sym?: string;
  dec: number;
  all?: Balance;
  totalSupply?: number;
  balance: bigint;
  listed?: Balance;
  icon?: string;
}

export interface Balance {
  confirmed: bigint;
  pending: bigint;
}

export type Web3TransferOrdinalRequest = {
  address: string;
  origin: string;
  outpoint: string;
};

export type ListOrdinal = {
  outpoint: string;
  price: number;
  password: string;
};

export type PurchaseOrdinal = {
  outpoint: string;
  password: string;
  marketplaceRate: number;
  marketplaceAddress: string;
};

export interface TBC20Data {
  initialized: boolean;
  data: TBC20Txo[];
}

export interface OrdinalData {
  initialized: boolean;
  data: NFTTxo[];
}

export interface Collection {
  initialized: boolean;
  data: COLLECTIONTxo[];
}

export interface FThistoryData {
  txid: string;
  ft_contract_id: string;
  ft_balance_change: number;
  ft_decimal: number;
  sender_combine_script: string[];
  recipient_combine_script: string[];
  time_stamp: number;
}

export interface NFThistoryData {
  txid: string;
  collection_id: string;
  collection_index: number;
  collection_name: string;
  nft_contract_id: string;
  nft_name: string;
  nft_symbol: string;
  nft_description: string;
  sender_addresses: string[];
  recipient_addresses: string[];
  time_stamp: number;
  nft_icon: string;
}

export const useOrds = () => {
  const { ordAddress, retrieveKeys, verifyPassword, ordPubKey, tbcAddress } = useKeys();

  const [ordinals, setOrdinals] = useState<OrdinalData>({
    initialized: false,
    data: [],
  });
  const [nftsInCollection, setnftsInCollection] = useState<OrdinalData>({
    initialized: false,
    data: [],
  });
  const [collections, setCollections] = useState<Collection>({
    initialized: false,
    data: [],
  });
  const [tbc20s, setTBC20s] = useState<TBC20Data>({
    initialized: false,
    data: [],
  });
  const [fts, setFts] = useState<TBC20Data>({
    initialized: false,
    data: [],
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [nftCount, setNftCount] = useState(0);
  const [collectionCount, setCollectionCount] = useState(0);
  const [OrdCount, setOrdCount] = useState(0);
  const [ftCount, setFtCount] = useState(0);
  const { network } = useNetwork();
  const { getUtxos, getRawTxById, getSuitableUtxo, getInputs } = useTuringBitChain();
  const {
    getFtBalance,
    getOrdUtxos,
    getNftUtxos,
    getCollectionUtxos,
    broadcastWithTBChainPyPool,
    getUtxoByOutpoint,
    getMarketData,
    getFtInputs,
    getTBC20Utxos,
    getTbc20Details,
    getTokenPriceInSats,
  } = useTBChainPyPool();
  const [nftscriptHash, setnftScriptHash] = useState<string | null>(null);
  const [collscriptHash, setcollScriptHash] = useState<string | null>(null);
  const [nftMintScript, setNftMintScript] = useState<Script | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [ftChanged, setFtChanged] = useState(false); // 触发标志
  const [ftHistory, setFtHistory] = useState<FThistoryData[]>([]);
  const [ftHistoryCount, setFtHistoryCount] = useState(0);
  const [nftHistory, setNftHistory] = useState<NFThistoryData[]>([]);
  const [nftHistoryCount, setNftHistoryCount] = useState(0);
  const getOrdinalsBaseUrl = () => {
    return network === NetWork.Mainnet ? GP_BASE_URL : GP_TESTNET_BASE_URL;
  };

  const createCollection = async (collectionData: CollectionData, password: string) => {
    try {
      await init();

      // 获取钱包密钥和地址信息
      const { walletAddress, walletWif } = await retrieveKeys(password);

      // 检查密钥是否存在
      if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

      // 获取钱包地址的未花费 UTXO（用于支付发行费用）
      const fundingUtxos = await getUtxos(walletAddress);
      if (!fundingUtxos || fundingUtxos.length === 0) throw new Error('Insufficient funds!');

      const fundingPrivateKey = PrivateKey.from_wif(walletWif);

      // 计算费用
      let feeSats = 80; // 最小手续费

      const collectionHex = jsonToHex(collectionData);  // 创建集合的锁定脚本
      const collectionFlag = Buffer.from('NColl').toString('hex');
      const collectionScript = Script.from_asm_string(`OP_0 OP_RETURN ${collectionHex} ${collectionFlag}`);  // 创建集合的锁定脚本

      // 计算集合锁定脚本的大小和 Dust 值
      // const collectionSize = collectionScript.to_bytes().byteLength + 8 + 1;
      // const collectionDust = Math.ceil((collectionSize + P2PKH_INPUT_SIZE) * FEE_PER_BYTE * 3);  // Dust 值计算

      // 创建新交易对象
      const tx = new Transaction(10, 0);

      // 添加第一个输出：锁定脚本为集合数据
      tx.add_output(new TxOut(BigInt(0), collectionScript));

      // 添加 supply 个相同的输出，每个输出的金额为 100 sats
      for (let i = 0; i < collectionData.supply; i++) {
        const nftMintScript = Script.from_asm_string(
          `${P2PKHAddress.from_string(walletAddress).get_locking_script().to_asm_string()} OP_RETURN ${Buffer.from('V0 Mint NHold').toString('hex')}`
        );
        tx.add_output(new TxOut(BigInt(NFT_LOCKING_AMOUNT), nftMintScript));  // 每个输出锁定 100 sats
      }
      // 计算交易的总输出金额
      let totalOutSats = NFT_LOCKING_AMOUNT * collectionData.supply;
      const inputs = getInputs(fundingUtxos, totalOutSats + feeSats);  // 获取合适的 UTXO 用于支付
      if ('error' in inputs) {
        return { error: inputs.error };
      }

      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);
      const estimatedOutputSize = tx.to_bytes().byteLength + P2PKH_OUTPUT_SIZE; // 估算输出的大小
      const estimatedInputSize = inputs.length * P2PKH_INPUT_SIZE; // 估算每个输入的大小
      const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
      feeSats = Math.max(feeSats, Math.ceil(estimatedTotalSize * FEE_PER_BYTE + 1));


      // 计算找零金额
      const change = totalInputSats - totalOutSats - feeSats;

      // 如果找零金额大于 Dust 限制，则添加找零输出
      if (change >= DUST) {
        const changeScript = P2PKHAddress.from_string(walletAddress).get_locking_script();
        tx.add_output(new TxOut(BigInt(change), changeScript));
      }

      // build txins from our inputs
      let idx = 0;
      for (let u of inputs || []) {
        // @ts-ignore
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));

        inTx.set_satoshis(BigInt(u.satoshis));
        tx.add_input(inTx);

        const sig = tx.sign(fundingPrivateKey, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(u.satoshis));//签名

        inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${fundingPrivateKey.to_public_key().to_hex()}`));
        tx.set_input(idx, inTx);
        idx++;
      }

      // 检查费用是否合理
      const finalSatsIn = tx.satoshis_in() ?? 0n;
      const finalSatsOut = tx.satoshis_out() ?? 0n;
      if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' };

      // 检查交易大小是否超出限制
      const bytes = tx.to_bytes().byteLength;
      if (bytes > MAX_BYTES_PER_TX) return { error: 'tx-size-too-large' };

      // 广播交易
      const txHex = tx.to_hex();
      //console.log('txHex:', txHex);
      const { txid } = await broadcastWithTBChainPyPool(txHex);
      if (!txid) throw new Error('Failed to broadcast transaction!');

      return { txid };
    } catch (error: any) {
      //console.log('error:', error);
      return { error: error.message ?? 'unknown' };
    }
  }
  const transferNFT = async (Ordinal: NFTTxo, RecieveordiAddress: string, password: string) => {
    try {
      await init();
      // 1. 获取密钥和地址信息
      const { walletAddress, walletWif, ordAddress, ordWif } = await retrieveKeys(password);
      if (!walletAddress || !walletWif || !ordAddress || !ordWif) throw new Error('Invalid keys!');

      const fundingUtxos = await getUtxos(walletAddress);
      if (!fundingUtxos || fundingUtxos.length === 0) throw new Error('Insufficient funds!');

      const fundingPrivateKey = PrivateKey.from_wif(walletWif); // 钱包私钥

      // 2. 获取合约交易的第三个输出锁定脚本和公钥哈希
      const { PpreTxDataScript, PreTxDataScript, prefirstOutputSatoshis, prefirstOutputScript, presecondOutputScript, nftScript, presecondOutputSatoshis, nftSatoshis, nftSize } = await getOriginalTxInfo(Ordinal);
      if (!PpreTxDataScript || !PreTxDataScript || !prefirstOutputSatoshis || !prefirstOutputScript || !presecondOutputScript || !nftScript || !presecondOutputSatoshis || !nftSize) throw new Error('Invalid original transaction!');
      // 3. 构建新交易
      const tx = new Transaction(10, 0); // 初始化交易对象
      let feeSats = 80; // 初始最小手续费
      //const NFT_INTERFER_SIZE = 33+8;

      // 3.1 第一个输出：复制合约锁定脚本，并锁定 100 sats
      const firstOutputSatoshis = prefirstOutputSatoshis;
      const firstOutputScript = prefirstOutputScript;
      tx.add_output(new TxOut(firstOutputSatoshis, firstOutputScript));

      // 3.2 第二个输出：锁定到新地址（ordiAddress）的 P2PKH 脚本，锁定 100 sats
      const secondScript = Script.from_asm_string(
        `${P2PKHAddress.from_string(RecieveordiAddress).get_locking_script().to_asm_string()} OP_RETURN ${Buffer.from('V0 Curr NHold').toString('hex')}`
      );
      tx.add_output(new TxOut(presecondOutputSatoshis, secondScript));

      // 3.3 第三个输出：合约交易的第三个输出锁定脚本，
      tx.add_output(new TxOut(BigInt(nftSatoshis), nftScript));

      // 构建第一个输入的临时解锁脚本用于计算fee
      const TcodesatBuffer = Buffer.alloc(8);
      TcodesatBuffer.writeBigUInt64LE(BigInt(firstOutputSatoshis));
      const TcodescriptBuffer = Buffer.from(firstOutputScript.to_hex(), 'hex');
      const ToutputsCombinedBuffers: Buffer[] = [];
      for (let i = 1; i < tx.get_noutputs(); i++) {
        const output = tx.get_output(i);
        if (!output) {
          throw new Error('Output is undefined');
        }
        const satoshisBuffer = Buffer.alloc(8);
        satoshisBuffer.writeBigUInt64LE(BigInt(output.get_satoshis()));
        const scriptBuffer = Buffer.from(output.get_script_pub_key().to_hex(), 'hex');
        // @ts-ignore
        const scriptHash = Buffer.from(Hash.sha_256(scriptBuffer).to_hex(), 'hex');
        ToutputsCombinedBuffers.push(satoshisBuffer);
        ToutputsCombinedBuffers.push(scriptHash);
      }
      // @ts-ignore
      const ToutputsData = Buffer.concat(ToutputsCombinedBuffers).toString('hex');
      const TCurrentTxDataScript = Script.from_asm_string(`${TcodesatBuffer.toString('hex')} ${TcodescriptBuffer.toString('hex')} ${ToutputsData}`);

      let totalOutSats = Number(prefirstOutputSatoshis) + Number(presecondOutputSatoshis) + Number(nftSatoshis);
      const inputs = getInputs(fundingUtxos, totalOutSats + feeSats - Number(prefirstOutputSatoshis) - Number(presecondOutputSatoshis)); // 获取合适的 UTXO 用于支付
      if ('error' in inputs) {
        return { error: inputs.error };
      }
      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);
      const firstInputScriptSize = 73 + 20 + TCurrentTxDataScript.to_bytes().byteLength + 40 + PpreTxDataScript.to_bytes().byteLength + PreTxDataScript.to_bytes().byteLength + 8 + 1;
      const estimatedOutputSize = NFT_CONTRACT_SIZE + P2PKH_OUTPUT_SIZE + nftSize + P2PKH_OUTPUT_SIZE; // 估算输出的大小
      const estimatedInputSize = firstInputScriptSize + (inputs.length + 1) * P2PKH_INPUT_SIZE; // 估算每个输入的大小
      const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
      feeSats = Math.max(feeSats, Math.ceil(estimatedTotalSize * FEE_PER_BYTE + 1));
      // 3.4 第四个输出：找零输出
      const change = totalInputSats - totalOutSats - feeSats;
      if (change >= DUST) {
        const changeScript = P2PKHAddress.from_string(walletAddress).get_locking_script();
        tx.add_output(new TxOut(BigInt(change), changeScript));
      }

      //第一个输入：来自传入的 txid 和 vout
      //@ts-ignore
      const inTx0 = new TxIn(Buffer.from(Ordinal.nftUtxoId, 'hex'), Ordinal.vout, Script.from_hex(''));
      const firstInputSatoshi = prefirstOutputSatoshis;
      inTx0.set_satoshis(firstInputSatoshi);
      const firstInputScript = prefirstOutputScript;
      inTx0.set_locking_script(firstInputScript);
      tx.add_input(inTx0);

      //第二个输入：来自署名utxo的 txid 和 vout
      //@ts-ignore
      const inTx1 = new TxIn(Buffer.from(Ordinal.nftUtxoId, 'hex'), Ordinal.vout + 1, Script.from_hex(''));
      inTx1.set_satoshis(BigInt(presecondOutputSatoshis));
      const secondInputScript = presecondOutputScript;
      inTx1.set_locking_script(secondInputScript);
      tx.add_input(inTx1);

      //签名并设置解锁脚本
      const sig1 = tx.sign(fundingPrivateKey, SigHash.InputOutputs, 1, secondInputScript, BigInt(presecondOutputSatoshis)); // 签名第二个输入（署名UTXO）
      inTx1.set_unlocking_script(Script.from_asm_string(`${sig1.to_hex()} ${fundingPrivateKey.to_public_key().to_hex()}`));
      tx.set_input(1, inTx1); // 设置第二个输入

      // build txins from our inputs
      let idx = 2;
      for (let u of inputs || []) {
        // @ts-ignore
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));
        inTx.set_satoshis(BigInt(u.satoshis));
        tx.add_input(inTx);
        const sig = tx.sign(fundingPrivateKey, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(u.satoshis));//签名
        inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${fundingPrivateKey.to_public_key().to_hex()}`));
        tx.set_input(idx, inTx);
        idx++;
      }

      // 构建第一个输入的正式解锁脚本
      const codesatBuffer = Buffer.alloc(8);
      codesatBuffer.writeBigUInt64LE(BigInt(firstOutputSatoshis));
      const codescriptBuffer = Buffer.from(firstOutputScript.to_hex(), 'hex');
      const outputsCombinedBuffers: Buffer[] = [];
      for (let i = 1; i < tx.get_noutputs(); i++) {
        const output = tx.get_output(i);
        if (!output) {
          throw new Error('Output is undefined');
        }
        const satoshisBuffer = Buffer.alloc(8);
        satoshisBuffer.writeBigUInt64LE(BigInt(output.get_satoshis()));
        const scriptBuffer = Buffer.from(output.get_script_pub_key().to_hex(), 'hex');
        // @ts-ignore
        const scriptHash = Buffer.from(Hash.sha_256(scriptBuffer).to_hex(), 'hex');
        outputsCombinedBuffers.push(satoshisBuffer);
        outputsCombinedBuffers.push(scriptHash);
      }
      // @ts-ignore
      const outputsData = Buffer.concat(outputsCombinedBuffers).toString('hex');
      const CurrentTxDataScript = Script.from_asm_string(`${codesatBuffer.toString('hex')} ${codescriptBuffer.toString('hex')} ${outputsData}`);
      const sig0 = tx.sign(fundingPrivateKey, SigHash.InputOutputs, 0, firstInputScript, BigInt(firstInputSatoshi));
      const unlockingScript = Script.from_asm_string(`${sig0.to_hex()} ${fundingPrivateKey.to_public_key().to_hex()} ${CurrentTxDataScript.to_asm_string()} ${PpreTxDataScript.to_asm_string()} ${PreTxDataScript.to_asm_string()}`);
      inTx0.set_unlocking_script(unlockingScript);
      tx.set_input(0, inTx0);
      const txHex = tx.to_hex();
      console.log('txHex:', txHex);
      const { txid: newTxid } = await broadcastWithTBChainPyPool(txHex); // 广播交易并获取 txid
      if (!newTxid) throw new Error('Failed to broadcast transaction!');

      return { txid: newTxid }; // 返回新生成的交易 ID
    } catch (error: any) {
      console.error('Transfer NFT failed:', error);
      return { error: error.message ?? 'unknown' };
    }
  };

  /**
   * 获取原始交易信息（第三个输出的锁定脚本和公钥哈希）
   * @param {string} txid - 合约交易的 txid。
   * @returns {Promise<object>} - 返回锁定脚本和公钥哈希。
   */

  async function getOriginalTxInfo(Ordinal: NFTTxo) {
    //const Response1 = await axios.get(`https://turingwallet.xyz/v1/tbc/main/tx/hex/${Ordinal.txid}`);
    const Response1 = await axios.get(`https://turingwallet.xyz/v1/tbc/main/tx/hex/${Ordinal.nftUtxoId}`);

    const rawTx1 = Response1.data; // 获取原始交易数据
    if (!rawTx1) throw new Error('Failed to fetch transaction data!');
    const pretx = Transaction.from_hex(rawTx1);
    // 获取输出脚本
    const prefirstInput = pretx.get_input(0);
    const prefirstOutput = pretx.get_output(0);
    const presecondOutput = pretx.get_output(1);
    const txidbuffer = Buffer.from(Ordinal.nftContractId, 'hex');
    const voutBuffer = Buffer.alloc(4);
    voutBuffer.writeUInt32LE(Ordinal.nftContractVout);
    //@ts-ignore
    const nftfile = Buffer.concat([txidbuffer, voutBuffer]).toString('hex');

    let nftData = {
      nftName: Ordinal.nftName,
      symbol: Ordinal.nftSymbol,
      file: nftfile,
    }
    const nftHex = jsonToHex(nftData)
    const nftFlag = Buffer.from('NTape').toString('hex');
    const nftScript = Script.from_asm_string(`OP_0 OP_RETURN ${nftHex} ${nftFlag}`);
    const nftSize = nftScript.to_bytes().byteLength + 8 + 1;
    // const nftSatoshis=Math.ceil((nftSize+ P2PKH_INPUT_SIZE) * FEE_PER_BYTE * 3);
    const nftSatoshis = 0n;
    if (!prefirstInput || !prefirstOutput || !presecondOutput) {
      throw new Error('Invalid transaction! Missing outputs.');
    }

    // 获取输出的锁定脚本
    const prefirstOutputScript = prefirstOutput.get_script_pub_key();
    const prefirstOutputSatoshis = prefirstOutput.get_satoshis();
    const presecondOutputSatoshis = presecondOutput.get_satoshis();
    const presecondOutputScript = presecondOutput.get_script_pub_key();
    // const pubKeyHash =presecondOutputScript.to_hex().slice(6, 46);
    const pvlioBuffer = Buffer.alloc(16);
    pvlioBuffer.writeUInt32LE(pretx.get_version(), 0);
    pvlioBuffer.writeUInt32LE(pretx.get_n_locktime(), 4);
    pvlioBuffer.writeUInt32LE(pretx.get_ninputs(), 8);
    pvlioBuffer.writeUInt32LE(pretx.get_noutputs(), 12);

    const pintputsCombinedBuffers: Buffer[] = [];
    const pscriptCombinedBuffers: Buffer[] = [];
    for (let i = 0; i < pretx.get_ninputs(); i++) {
      const input = pretx.get_input(i);
      if (!input) {
        throw new Error('Input is undefined');
      }
      pintputsCombinedBuffers.push(Buffer.from(input.get_prev_tx_id_hex(), 'hex').reverse());
      const pvoutBuffer = Buffer.alloc(4);
      pvoutBuffer.writeUInt32LE(input.get_vout());
      pintputsCombinedBuffers.push(pvoutBuffer);
      const pseqBuffer = Buffer.alloc(4);
      pseqBuffer.writeUInt32LE(input.get_sequence());
      pintputsCombinedBuffers.push(pseqBuffer);

      const scriptBuffer = Buffer.from(input.get_unlocking_script().to_hex(), 'hex');
      // @ts-ignore
      const scriptHash = Buffer.from(Hash.sha_256(scriptBuffer).to_hex(), 'hex');
      pscriptCombinedBuffers.push(scriptHash);
    }
    // @ts-ignore
    const preintputsData = Buffer.concat(pintputsCombinedBuffers).toString('hex');
    // @ts-ignore
    const prescriptData = Hash.sha_256(Buffer.concat(pscriptCombinedBuffers)).to_hex();
    const precodesatBuffer = Buffer.alloc(8);
    precodesatBuffer.writeBigUInt64LE(BigInt(prefirstOutputSatoshis));
    const precodescriptBuffer = Buffer.from(prefirstOutputScript.to_hex(), 'hex');
    const pretapesatBuffer = Buffer.alloc(8);
    pretapesatBuffer.writeBigUInt64LE(BigInt(presecondOutputSatoshis));
    const pretapescriptBuffer = Buffer.from(presecondOutputScript.to_hex(), 'hex');

    const poutputsCombinedBuffers: Buffer[] = [];
    for (let i = 2; i < pretx.get_noutputs(); i++) {
      const output = pretx.get_output(i);
      if (!output) {
        throw new Error('Output is undefined');
      }
      const satoshisBuffer = Buffer.alloc(8);
      satoshisBuffer.writeBigUInt64LE(BigInt(output.get_satoshis()));

      const scriptBuffer = Buffer.from(output.get_script_pub_key().to_hex(), 'hex');
      // @ts-ignore
      const scriptHash = Buffer.from(Hash.sha_256(scriptBuffer).to_hex(), 'hex');
      poutputsCombinedBuffers.push(satoshisBuffer);
      poutputsCombinedBuffers.push(scriptHash);
    }
    // @ts-ignore
    const preoutputsData = Buffer.concat(poutputsCombinedBuffers).toString('hex');
    const PreTxDataScript = Script.from_asm_string(`${pvlioBuffer.toString('hex')} ${preintputsData} ${prescriptData} ${precodesatBuffer.toString('hex')} ${precodescriptBuffer.toString('hex')} ${pretapesatBuffer.toString('hex')} ${pretapescriptBuffer.toString('hex')} ${preoutputsData}`);


    const pretxid = prefirstInput.get_prev_tx_id_hex();
    const Response2 = await axios.get(`https://turingwallet.xyz/v1/tbc/main/tx/hex/${pretxid}`);
    const rawTx2 = Response2.data;
    if (!rawTx2) throw new Error('Failed to fetch transaction data!');
    const prepretx = Transaction.from_hex(rawTx2);

    const ppvlioBuffer = Buffer.alloc(16);
    ppvlioBuffer.writeUInt32LE(prepretx.get_version(), 0);
    ppvlioBuffer.writeUInt32LE(prepretx.get_n_locktime(), 4);
    ppvlioBuffer.writeUInt32LE(prepretx.get_ninputs(), 8);
    ppvlioBuffer.writeUInt32LE(prepretx.get_noutputs(), 12);
    const ppintputsCombinedBuffers: Buffer[] = [];
    const ppscriptCombinedBuffers: Buffer[] = [];
    for (let i = 0; i < prepretx.get_ninputs(); i++) {
      const input = prepretx.get_input(i);
      if (!input) {
        throw new Error('Input is undefined');
      }
      ppintputsCombinedBuffers.push(Buffer.from(input.get_prev_tx_id_hex(), 'hex').reverse());
      const ppvoutBuffer = Buffer.alloc(4);
      ppvoutBuffer.writeUInt32LE(input.get_vout());
      ppintputsCombinedBuffers.push(ppvoutBuffer);
      const ppseqBuffer = Buffer.alloc(4);
      ppseqBuffer.writeUInt32LE(input.get_sequence());
      ppintputsCombinedBuffers.push(ppseqBuffer);
      const ppscriptBuffer = Buffer.from(input.get_unlocking_script().to_hex(), 'hex');
      // @ts-ignore
      const ppscriptHash = Buffer.from(Hash.sha_256(ppscriptBuffer).to_hex(), 'hex');
      ppscriptCombinedBuffers.push(ppscriptHash);
    }
    // @ts-ignore
    const ppreintputsData = Hash.sha_256(Buffer.concat(ppintputsCombinedBuffers)).to_hex();
    // @ts-ignore
    const pprescriptData = Hash.sha_256(Buffer.concat(ppscriptCombinedBuffers)).to_hex();

    const preprecodeOutput = prepretx.get_output(0);
    if (!preprecodeOutput) {
      throw new Error('Invalid transaction! Missing outputs.');
    }
    const pprecodesatBuffer = Buffer.alloc(8);
    pprecodesatBuffer.writeBigUInt64LE(BigInt(preprecodeOutput.get_satoshis()));
    const pprecodescriptBuffer = Buffer.from(preprecodeOutput.get_script_pub_key().to_hex(), 'hex')
    const ppoutputsCombinedBuffers: Buffer[] = [];
    for (let i = 1; i < prepretx.get_noutputs(); i++) {
      const output = prepretx.get_output(i);
      if (!output) {
        throw new Error('Output is undefined');
      }
      const satoshisBuffer = Buffer.alloc(8);
      satoshisBuffer.writeBigUInt64LE(BigInt(output.get_satoshis()));
      const scriptBuffer = Buffer.from(output.get_script_pub_key().to_hex(), 'hex');
      // @ts-ignore
      const scriptHash = Buffer.from(Hash.sha_256(scriptBuffer).to_hex(), 'hex');
      ppoutputsCombinedBuffers.push(satoshisBuffer);
      ppoutputsCombinedBuffers.push(scriptHash);
    }
    // @ts-ignore
    const ppreoutputsData = Buffer.concat(ppoutputsCombinedBuffers).toString('hex');
    const PpreTxDataScript = Script.from_asm_string(`${ppvlioBuffer.toString('hex')} ${ppreintputsData} ${pprescriptData} ${pprecodesatBuffer.toString('hex')} ${pprecodescriptBuffer.toString('hex')} ${ppreoutputsData}`);

    return { PpreTxDataScript, PreTxDataScript, prefirstOutputSatoshis, prefirstOutputScript, presecondOutputScript, presecondOutputSatoshis, nftScript, nftSatoshis, nftSize };
  }



  useEffect(() => {
    if (!ordAddress || !tbcAddress || isInitialized) return; // 如果 `ordAddress` 为空或者已经初始化，则不执行
    const nftMintScript = Script.from_asm_string(
      `${P2PKHAddress.from_string(tbcAddress).get_locking_script().to_asm_string()} OP_RETURN ${Buffer.from('V0 Mint NHold').toString('hex')}`
    );
    setNftMintScript(nftMintScript); // 更新 nftMintScript 状态
    const collscriptBuffer = Buffer.from(nftMintScript.to_hex(), 'hex');
    // //console.log('collscriptBuffer:', collscriptBuffer.toString('hex'));

    const collscriptHash = Buffer.from(Hash.sha_256(collscriptBuffer).to_hex(), 'hex').reverse().toString('hex');
    setcollScriptHash(collscriptHash); // 更新 scriptHash 状态
    // //console.log('collscriptHash:', collscriptHash);
    const nftCurrScript = Script.from_asm_string(
      `${P2PKHAddress.from_string(tbcAddress).get_locking_script().to_asm_string()} OP_RETURN ${Buffer.from('V0 Curr NHold').toString('hex')}`
    );
    const nftscriptBuffer = Buffer.from(nftCurrScript.to_hex(), 'hex');
    // @ts-ignore
    const nftscripthash = Buffer.from(Hash.sha_256(nftscriptBuffer).to_hex(), 'hex').reverse().toString('hex');
    setnftScriptHash(nftscripthash); // 更新 scriptHash 状态
    // 设置为已经初始化
    getFts(false);
    setIsInitialized(true);
  }, [ordAddress, isInitialized]); // 依赖于 `ordAddress` 和 `isInitialized` 状态

  useEffect(() => {
    if (!collscriptHash || !nftscriptHash) return; // 确保 `collscriptHash` 和 `nftscripthash` 已经更新
    getCollection(0, false); // 调用 getCollection
    getOrdinals(0, false);   // 调用 getOrdinals
  }, [collscriptHash, nftscriptHash]); // 依赖 `collscriptHash` 和 `nftscripthash`

  // 监听 ftChanged 状态的变化
  useEffect(() => {
    if (!tbcAddress) return;
    getFts();
  }, [ftChanged]); // 当 ftChanged 变化时触发

  function jsonToHex(jsonObj: object): string {
    const jsonString = JSON.stringify(jsonObj); // 将对象转为 JSON 字符串
    let hexString = jsonString.split('')
      .map(char => char.charCodeAt(0).toString(16).padStart(2, '0')) // 转换为十六进制并确保每个字符为两位
      .join('');

    // 如果长度是奇数，前面补 '0'
    if (hexString.length % 2 !== 0) {
      hexString = '0' + hexString;
    }
    return hexString;
  }


  // //这里是之后的序列化和反序列化优化
  // import * as msgpack from 'msgpack-lite';
  // import * as zlib from 'zlib';
  // // 序列化方法：Gzip 压缩并使用 MessagePack 编码
  // function serializeNftDataMsgPack(nftData: NftData): string {
  //   const compressedFile = zlib.gzipSync(Buffer.from(nftData.file, 'base64')); // 压缩 Base64 文件
  //   nftData.file = compressedFile.toString('hex'); // 转为十六进制表示

  //   const msgpackEncoded = msgpack.encode(nftData); // 使用 MessagePack 编码
  //   return msgpackEncoded.toString('hex'); // 将二进制编码转换为十六进制表示
  // }

  // // 反序列化方法：解压缩并使用 MessagePack 解码
  // function deserializeNftDataMsgPack(serializedData: string): NftData {
  //   const msgpackBuffer = Buffer.from(serializedData, 'hex'); // 从十六进制转换为 Buffer
  //   const decodedData = msgpack.decode(msgpackBuffer); // 使用 MessagePack 解码

  //   const compressedFileBuffer = Buffer.from(decodedData.file, 'hex'); // 从十六进制转换为 Buffer
  //   const decompressedFile = zlib.gunzipSync(compressedFileBuffer); // 解压缩文件

  //   return {
  //     ...decodedData,
  //     file: decompressedFile.toString('base64'),  // 恢复为 Base64 字符串
  //   };
  // }
  const IssueNFT = async (SelectedCollection: COLLECTIONTxo, nftData: NftData, password: string) => {
    try {
      await init();
      const { walletAddress, walletWif } = await retrieveKeys(password);

      if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

      const fundingUtxos = await getUtxos(walletAddress);
      if (!fundingUtxos || fundingUtxos.length === 0) throw new Error('Insufficient funds!');
      const { data } = await axios.get(`https://turingwallet.xyz/v1/tbc/main/script/hash/${collscriptHash}/unspent`);
      const createNftUtxo = data.find((utxo: any) => utxo.tx_hash === SelectedCollection.collectionId);
      if (!createNftUtxo) throw new Error('UTXO not found for the selected collection!');

      const fundingPrivateKey = PrivateKey.from_wif(walletWif);

      let feeSats = 80;
      let change = 0;
      const nftHex = jsonToHex(nftData);

      const nftFlag = Buffer.from('NTape').toString('hex');
      const thirdOutputScript = Script.from_asm_string(`OP_0 OP_RETURN ${nftHex} ${nftFlag}`);
      const thirdOutputSize = thirdOutputScript.to_bytes().byteLength + 8 + 1;
      // const thirdOutputDust = Math.ceil((thirdOutputSize+ P2PKH_INPUT_SIZE) * FEE_PER_BYTE * 3); 

      const tx = new Transaction(10, 0);

      const voutHex = Buffer.alloc(4);
      voutHex.writeUInt32LE(createNftUtxo.tx_pos, 0);
      //@ts-ignore
      const outpoint = Buffer.concat([Buffer.from(createNftUtxo.tx_hash, 'hex').reverse(), voutHex]).toString('hex');
      //利用from_asm_string函数不用管数据推送前的字节数 函数内部会加 直接按代码写就行
      //const firstOutputScript = Script.from_asm_string(`OP_TOALTSTACK OP_DUP OP_HASH160 OP_FROMALTSTACK OP_EQUALVERIFY OP_CHECKSIG OP_1 OP_EQUALVERIFY OP_6 OP_PUSH_META OP_EQUALVERIFY OP_7 OP_PUSH_META OP_EQUALVERIFY 36 OP_SPLIT OP_SWAP OP_TOALTSTACK OP_TOALTSTACK 36 OP_SPLIT OP_FROMALTSTACK OP_EQUAL OP_IF OP_DROP OP_TRUE OP_RETURN OP_ELSE OP_DUP OP_FROMALTSTACK OP_EQUALVERIFY ${outpoint} OP_EQUAL OP_ENDIF`);
      ////console.log('firstOutputScript:', firstOutputScript.to_hex());//利用hex写就需要自己加字节数 下面的数字是十进制
      //const firstOutputScript = Script.from_asm_string(`OP_1 OP_PICK OP_3 OP_SPLIT 20 OP_SPLIT OP_DROP OP_TOALTSTACK OP_DROP OP_TOALTSTACK OP_SHA256 OP_CAT OP_FROMALTSTACK OP_CAT OP_1 OP_PICK OP_TOALTSTACK OP_TOALTSTACK OP_SHA256 OP_CAT OP_FROMALTSTACK OP_CAT OP_SHA256 OP_CAT OP_1 OP_PICK 36 OP_SPLIT OP_DROP OP_TOALTSTACK OP_TOALTSTACK OP_SHA256 OP_CAT OP_FROMALTSTACK OP_CAT OP_SHA256 OP_SHA256 OP_6 OP_PUSH_META 32 OP_SPLIT OP_DROP OP_EQUALVERIFY OP_1 OP_PICK OP_TOALTSTACK OP_TOALTSTACK OP_SHA256 OP_CAT OP_FROMALTSTACK OP_CAT OP_SHA256 OP_CAT OP_CAT OP_CAT OP_SHA256 OP_SHA256 OP_FROMALTSTACK OP_FROMALTSTACK OP_DUP 32 OP_SPLIT OP_DROP OP_3 OP_ROLL OP_EQUALVERIFY OP_SWAP OP_FROMALTSTACK OP_DUP OP_TOALTSTACK OP_EQUAL OP_IF OP_DROP OP_ELSE ${outpoint} OP_EQUALVERIFY OP_ENDIF OP_1 OP_PICK OP_FROMALTSTACK OP_EQUALVERIFY OP_TOALTSTACK OP_SHA256 OP_CAT OP_FROMALTSTACK OP_CAT OP_SHA256 OP_7 OP_PUSH_META OP_EQUALVERIFY OP_DUP OP_HASH160 OP_FROMALTSTACK OP_EQUALVERIFY OP_CHECKSIG OP_RETURN ${Buffer.from('3Code').toString('hex')}`);
      const firstOutputScript = Script.from_hex(`5179537f01147f756b756ba87e6c7e51796b6ba87e6c7ea87e517901247f756b6ba87e6c7ea8a856ba01207f758851796b6ba87e6c7ea87e7e7ea8a86c6c7601207f75537a887c6c766b8763756724${outpoint}886851796c886ba87e6c7ea857ba8876a96c88ac6a05${Buffer.from('3Code').toString('hex')}`);//这里加入新的操作码解析

      const codeSize = firstOutputScript.to_bytes().byteLength + 8 + 1;
      const codeDust = Math.ceil((codeSize + P2PKH_INPUT_SIZE) * FEE_PER_BYTE * 3);  // Dust 值计算
      //第一个输出：锁定脚本是合约脚本
      tx.add_output(new TxOut(BigInt(codeDust), firstOutputScript));
      // 第二个输出：锁定到 的 P2PKH 锁定脚本
      const secondScript = Script.from_asm_string(
        `${P2PKHAddress.from_string(walletAddress).get_locking_script().to_asm_string()} OP_RETURN ${Buffer.from('V0 Curr NHold').toString('hex')}`
      );

      tx.add_output(new TxOut(BigInt(NFT_LOCKING_AMOUNT), secondScript));

      // 第三个输出：锁定脚本是（op_false op_return 加序列化的 JSON）
      tx.add_output(new TxOut(BigInt(0), thirdOutputScript));

      const totalOutSats = CONTRACT_LOCKING_AMOUNT + NFT_LOCKING_AMOUNT; // 3 个输出
      const inputs = getInputs(fundingUtxos, totalOutSats + feeSats - NFT_LOCKING_AMOUNT);  // 获取合适的 UTXO 用于支付
      if ('error' in inputs) {
        return { error: inputs.error };
      }
      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);
      const estimatedOutputSize = NFT_CONTRACT_SIZE + P2PKH_OUTPUT_SIZE + thirdOutputSize + P2PKH_OUTPUT_SIZE; // 估算输出的大小
      const estimatedInputSize = (inputs.length + 1) * P2PKH_INPUT_SIZE; // 估算每个输入的大小
      const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
      feeSats = Math.max(feeSats, Math.ceil(estimatedTotalSize * FEE_PER_BYTE + 1));

      // 计算找零金额，第四个输出（找零到钱包地址）
      if (totalInputSats - totalOutSats - feeSats >= DUST) { // 如果找零金额大于 Dust 限制，则添加找零输出
        change = totalInputSats - totalOutSats - feeSats;
        const changeScript = P2PKHAddress.from_string(walletAddress).get_locking_script();
        tx.add_output(new TxOut(BigInt(change), changeScript));
      }

      // 添加输入
      // @ts-ignore
      const inTx0 = new TxIn(Buffer.from(createNftUtxo.tx_hash, 'hex'), createNftUtxo.tx_pos, Script.from_hex(''));
      inTx0.set_satoshis(BigInt(createNftUtxo.value));
      // const fundingScript =Script.from_asm_string(
      //   `${P2PKHAddress.from_string(walletAddress).get_locking_script().to_asm_string()} OP_RETURN ${Buffer.from('NFTv0Mint').toString('hex')}`
      // );
      if (!nftMintScript) {
        throw new Error('nftMintScript is null');
      }
      const fundingScript = Script.from_hex(nftMintScript.to_hex());
      inTx0.set_locking_script(fundingScript);
      tx.add_input(inTx0);

      // 为输入签名（使用钱包私钥）
      const sig0 = tx.sign(fundingPrivateKey, SigHash.InputOutputs, 0, fundingScript, BigInt(createNftUtxo.value));
      inTx0.set_unlocking_script(Script.from_asm_string(`${sig0.to_hex()} ${fundingPrivateKey.to_public_key().to_hex()}`));
      tx.set_input(0, inTx0);

      // build txins from our inputs
      let idx = 1;
      for (let u of inputs || []) {
        // @ts-ignore
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));

        inTx.set_satoshis(BigInt(u.satoshis));
        tx.add_input(inTx);

        const sig = tx.sign(fundingPrivateKey, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(u.satoshis));//签名

        inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${fundingPrivateKey.to_public_key().to_hex()}`));
        tx.set_input(idx, inTx);
        idx++;
      }
      // Fee checker
      const finalSatsIn = tx.satoshis_in() ?? 0n;
      const finalSatsOut = tx.satoshis_out() ?? 0n;
      if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' };

      // Size checker
      const bytes = tx.to_bytes().byteLength;
      if (bytes > MAX_BYTES_PER_TX) return { error: 'tx-size-too-large' };

      // 广播交易
      const txHex = tx.to_hex();
      console.log('txHex:', txHex);
      const { txid } = await broadcastWithTBChainPyPool(txHex);
      if (!txid) throw new Error('Failed to broadcast transaction!');
      return { txid }; // 返回交易 ID
    } catch (error: any) {
      console.error('Issue NFT failed:', error);
      //console.log('error:',error);
      return { error: error.message ?? 'unknown' };
    }
  };
  const getNftbycollection = async (collectionId: string, page: number, showProcessing?: boolean) => {
    try {
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setIsProcessing(showProcessing);
      setNftCount(0);
      await sleep(50);

      // 先从缓存中获取 Collection 内的 NFT 数据和 nftCount
      const cacheKey = `nft_collection_${collectionId}_page_${page}`;
      //const cacheKey = `nft_collection_page_${page}`;
      idbstorage.get(cacheKey, async (cachedData: any) => {
        if (cachedData && cachedData[cacheKey]) {
          const parsedData = JSON.parse(cachedData[cacheKey]);
          setnftsInCollection({ initialized: true, data: parsedData.nftList });
          setNftCount(parsedData.nftCount);
          setIsProcessing(false);
        }
        else {
          setIsProcessing(true);
          // //console.log('未命中缓存');
        }
      });

      // 发起 API 请求以获取最新数据
      const { nftCount, nftList } = await getNftUtxos(collectionId, page);
      if (nftCount === -1) {
        throw new Error('Network error!');
      }
      setNftCount(nftCount);
      const decodedData = await Promise.all(nftList.map(async (item) => {
        const thumbnail = await generateThumbnail(item.nftIcon);
        const outpoint = new Outpoint(Buffer.from(item.nftUtxoId, 'hex'), item.vout);
        return {
          ...item,
          image: thumbnail,
          outpoint: outpoint,
        };
      }));

      // 比较新数据和缓存中的数据
      idbstorage.get(cacheKey, (cachedData: any) => {
        const cachedDataObj = cachedData[cacheKey] ? JSON.parse(cachedData[cacheKey]) : { nftList: [], nftCount: -1 }; // 确保是对象
        if (JSON.stringify(decodedData) !== JSON.stringify(cachedDataObj.nftList) || nftCount !== cachedDataObj.nftCount) {
          // 如果新数据与缓存数据不相同，则更新缓存和 UI
          setnftsInCollection({ initialized: true, data: decodedData });
          setNftCount(nftCount);

          // 存储时序列化为 JSON 字符串，包含 nftCount 和 nftList
          idbstorage.set({ [cacheKey]: JSON.stringify({ nftList: decodedData, nftCount }) });
        }
      });
    } catch (error) {
      console.error('Failed to fetch NFT data:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const getOrdinals = async (page: number, showProcessing?: boolean) => {
    try {
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setIsProcessing(showProcessing);
      setOrdCount(0);
      await sleep(50);
      // 先从缓存中获取 Ordinals 数据和 nftCount
      const cacheKey = `ordinals_page_${page}`;
      idbstorage.get(cacheKey, async (cachedData: any) => {
        if (cachedData && cachedData[cacheKey]) {
          const parsedData = JSON.parse(cachedData[cacheKey]); // 反序列化为对象
          setOrdinals({ initialized: true, data: parsedData.nftList });
          setOrdCount(parsedData.nftCount); // 从缓存中恢复 nftCount
          setIsProcessing(false);
        } else {
          setIsProcessing(true);
          // //console.log('未命中缓存');
        }

      });

      const { nftCount, nftList } = await getOrdUtxos(tbcAddress, page);
      if (nftCount === -1) {
        throw new Error('Network error!');
      }
      setOrdCount(nftCount);

      const decodedData = await Promise.all(nftList.map(async (item) => {
        const thumbnail = await generateThumbnail(item.nftIcon);
        const outpoint = new Outpoint(Buffer.from(item.nftUtxoId, 'hex'), item.vout);
        return {
          ...item,
          image: thumbnail,
          vout: 0,
          nftContractVout: 0,
          outpoint: outpoint,
        };
      }));

      // 比较新数据和缓存中的数据
      idbstorage.get(cacheKey, (cachedData: any) => {
        const cachedDataObj = cachedData[cacheKey] ? JSON.parse(cachedData[cacheKey]) : { nftList: [], nftCount: -1 }; // 确保是对象
        if (JSON.stringify(decodedData) !== JSON.stringify(cachedDataObj.nftList) || nftCount !== cachedDataObj.nftCount) {
          // 如果新数据与缓存数据不相同，则更新缓存和 UI
          setOrdinals({ initialized: true, data: decodedData });
          setOrdCount(nftCount);

          // 存储时序列化为 JSON 字符串，包含 nftCount 和 nftList
          idbstorage.set({ [cacheKey]: JSON.stringify({ nftList: decodedData, nftCount }) });
        }
      });
      setIsProcessing(false);
    } catch (error) {
      console.error('Failed to fetch NFT data:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const getCollection = async (page: number, showProcessing?: boolean) => {
    try {
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setIsProcessing(showProcessing);
      setCollectionCount(0);
      await sleep(50);
      // 先从缓存中获取 Collection 数据和 collectionCount
      const cacheKey = `collections_page_${page}`;
      idbstorage.get(cacheKey, async (cachedData: any) => {
        if (cachedData && cachedData[cacheKey]) {
          const parsedData = JSON.parse(cachedData[cacheKey]); // 反序列化为对象
          setCollections({ initialized: true, data: parsedData.collectionList });
          setCollectionCount(parsedData.collectionCount); // 从缓存中恢复 collectionCount
          setIsProcessing(false);
        } else {
          // //console.log('未命中缓存');
          setIsProcessing(true);
        }
      });


      const { collectionCount, collectionList } = await getCollectionUtxos(page, tbcAddress);
      if (collectionCount === -1) {
        throw new Error('Network error!');
      }
      setCollectionCount(collectionCount);

      const decodedData = await Promise.all(collectionList.map(async (item) => {
        const thumbnail = await generateThumbnail(item.collectionIcon);
        const outpoint = new Outpoint(Buffer.from(item.collectionId, 'hex'), item.vout);
        return {
          ...item,
          image: thumbnail,
          vout: 0,
          outpoint: outpoint,
        };
      }));

      // 比较新数据和缓存中的数据 这里有问题
      idbstorage.get(cacheKey, (cachedData: any) => {
        const cachedDataObj = cachedData[cacheKey] ? JSON.parse(cachedData[cacheKey]) : { collectionList: [], collectionCount: -1 }; // 确保是对象
        if (JSON.stringify(decodedData) !== JSON.stringify(cachedDataObj.collectionList) || collectionCount !== cachedDataObj.collectionCount) {
          // 如果新数据与缓存数据不相同，则更新缓存和 UI
          setCollections({ initialized: true, data: decodedData });
          setCollectionCount(collectionCount);
          // 存储时序列化为 JSON 字符串，包含 collectionCount 和 collectionList
          idbstorage.set({ [cacheKey]: JSON.stringify({ collectionList: decodedData, collectionCount }) });
        }
      });
      setIsProcessing(false);
    } catch (error) {
      console.error('Failed to fetch collection data:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const getFts = async (showProcessing?: boolean) => {
    try {
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setIsProcessing(showProcessing);
      setFtCount(0);
      const cacheKey = 'user_ftAddresses';

      // 从缓存中获取所有 ftAddresses
      idbstorage.get(cacheKey, async (cachedData: any) => {
        const ftAddresses = cachedData?.[cacheKey] || [];
        if (ftAddresses.length === 0) {
          setFts({ initialized: true, data: [] });
          setFtCount(0);
          setIsProcessing(false);
          return;
        }
        const ftDetailKeys = ftAddresses
          .filter((address: string | undefined) => address !== undefined) // 过滤掉 undefined 的地址
          .map((address: string) => `ft_details_${address}`);

        // 从缓存中获取每个 ft 地址的详细信息
        idbstorage.get(ftDetailKeys, async (ftDetailsData: any) => {
          let ftList: TBC20Txo[] = ftAddresses.map((address: string) => {
            const details = ftDetailsData[`ft_details_${address}`];
            return details ? JSON.parse(details) : null;
          }).filter((item: any) => item !== null);
          // 获取每个 ft 地址的最新余额
          setFts({ initialized: true, data: ftList });
          setFtCount(ftList.length);
          const balances = await getFtBalance(tbcAddress, ftAddresses);
          // 更新 ftList 中每个项目的 ftBalance
          if (balances) {
            ftList = ftList.map((ftItem: TBC20Txo) => ({
              ...ftItem,
              ftBalance: balances[ftItem.ftContractId]
            }));
            // 序列化并将更新后的 ft 详细信息保存回缓存
            ftList.forEach((ftItem: TBC20Txo) => {
              const ftDetailCacheKey = `ft_details_${ftItem.ftContractId}`;
              idbstorage.set({ [ftDetailCacheKey]: JSON.stringify(ftItem) });
            });
            // 更新状态并设置 ftList 和 ftCount

            setFts({ initialized: true, data: ftList });
            setFtCount(ftList.length);
          }
        });
      });
      setIsProcessing(false);
    } catch (error) {
      return { result: false, error: (error as any).message ?? 'unknown' };
    } finally {
      setIsProcessing(false);
    }
  };

  const getFtHistory = async (contractId: string, page: number, showProcessing?: boolean) => {
    try {
      // 显示加载状态
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setIsProcessing(showProcessing);
      setFtHistoryCount(0);
      await sleep(50);
      const cacheKey = `FtHistory_page_${contractId}_${page}`; // 缓存的唯一键
      const apiUrl = `https://turingwallet.xyz/v1/tbc/main/ft/history/address/${tbcAddress}/contract/${contractId}/page/${page}/size/${PAGE_SIZE}`; // API URL

      // 从缓存中获取数据
      idbstorage.get(cacheKey, async (cachedData: any) => {
        if (cachedData && cachedData[cacheKey]) {
          const parsedData = JSON.parse(cachedData[cacheKey]);
          setFtHistory(parsedData.historyData);
          setFtHistoryCount(parsedData.historyCount);
          setIsProcessing(false);
        } else {
          setIsProcessing(true);
        }
      });

      // 调用 API 获取最新数据
      const response = await axios.get(apiUrl);
      const historyData = response.data?.result || [];
      const historyCount = response.data?.history_count || 0;
      idbstorage.get(cacheKey, (cachedData: any) => {
        const cachedDataObj = cachedData[cacheKey] ? JSON.parse(cachedData[cacheKey]) : { historyData: [], historyCount: -1 }; // 确保是对象
        if (JSON.stringify(historyData) !== JSON.stringify(cachedDataObj.historyData) || historyCount !== cachedDataObj.historyCount) {
          // 如果新数据与缓存数据不相同，则更新缓存和 UI
          setFtHistory(historyData);
          setFtHistoryCount(historyCount);

          // 存储时序列化为 JSON 字符串，包含 nftCount 和 nftList
          idbstorage.set({ [cacheKey]: JSON.stringify({ historyData, historyCount }) });
        }
      });
    } catch (error) {
      console.error('Failed to fetch history:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const getNftHistory = async (page: number, showProcessing?: boolean) => {
    try {
      // 显示加载状态
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setNftHistoryCount(0);
      setIsProcessing(showProcessing);
      await sleep(50);
      const cacheKey = `NftHistory_page_${page}`; // 缓存的唯一键
      const apiUrl = `https://turingwallet.xyz/v1/tbc/main/nft/history/address/${tbcAddress}/page/${page}/size/${PAGE_SIZE}`; // API URL

      // 从缓存中获取数据
      idbstorage.get(cacheKey, async (cachedData: any) => {
        if (cachedData && cachedData[cacheKey]) {
          const parsedData = JSON.parse(cachedData[cacheKey]);
          setNftHistory(parsedData.historyData);
          setNftHistoryCount(parsedData.historyCount);
          setIsProcessing(false);
        } else {
          setIsProcessing(true);
        }
      });

      // 调用 API 获取最新数据
      const response = await axios.get(apiUrl);
      const historyData = response.data?.result || [];
      const historyCount = response.data?.history_count || 0;
      idbstorage.get(cacheKey, (cachedData: any) => {
        const cachedDataObj = cachedData[cacheKey] ? JSON.parse(cachedData[cacheKey]) : { historyData: [], historyCount: -1 }; // 确保是对象
        if (JSON.stringify(historyData) !== JSON.stringify(cachedDataObj.historyData) || historyCount !== cachedDataObj.historyCount) {
          // 如果新数据与缓存数据不相同，则更新缓存和 UI
          setNftHistory(historyData);
          setNftHistoryCount(historyCount);

          // 存储时序列化为 JSON 字符串，包含 nftCount 和 nftList
          idbstorage.set({ [cacheKey]: JSON.stringify({ historyData, historyCount }) });
        }
      });
    } catch (error) {
      console.error('Failed to fetch history:', error);
    } finally {
      setIsProcessing(false);
    }
  };
  const addFT = async (ftaddress: string, showProcessing?: boolean) => {
    try {
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setIsProcessing(showProcessing);
      await sleep(50);

      const cacheKey = `user_ftAddresses`;
      const ftDetailCacheKey = `ft_details_${ftaddress}`;

      // 使用 Promise 包装 idbstorage.get 以便等待
      const cachedData = await new Promise<any>((resolve, reject) => {
        idbstorage.get(cacheKey, (data: any) => {
          if (data) {
            resolve(data);
          } else {
            reject(new Error('Failed to get data from cache'));
          }
        });
      });

      const ftAddresses = cachedData?.[cacheKey] || []; // 若未存储则使用空数组
      // 检查当前 ftaddress 是否已存在
      if (ftAddresses.includes(ftaddress)) {
        return { result: false };  // 如果已经存在，直接返回
      }
      const updatedFtAddresses = [...ftAddresses, ftaddress];
      idbstorage.set({ [cacheKey]: updatedFtAddresses });
      // 获取新 ftaddress 的详细信息
      try {
        const response = await axios.get(`https://turingwallet.xyz/v1/tbc/main/ft/info/contract/id/${ftaddress}`);
        if (!response) throw new Error('Failed to fetch FT details');

        const ftDetails = response.data;
        // 更新 ftList 中每个项目的 ftBalance
        const decodedData = {
          ...ftDetails,
          ftBalance: 0, // 添加新成员
        };
        // 序列化和存储 FT 详细信息到缓存
        idbstorage.set({ [ftDetailCacheKey]: JSON.stringify(decodedData) });
        // 添加新的 ftaddress 到 ftAddresses 数组并更新缓存
        setFtChanged((prev) => !prev);
        return { result: true };  // 返回结果
      } catch (error) {
        console.error('Failed to fetch FT details:', error);
        return { result: false, error: (error as any).message ?? 'unknown' };
      }

    } catch (error: any) {
      console.error('Failed to fetch or store collection data:', error);
      return { error: error.message ?? 'unknown' };
    } finally {
      setIsProcessing(false);
    }
  };



  // SWR fetcher 通用函数，使用 axios 发起 API 请求
  const fetcher = (url: string) => axios.get(url).then(res => res.data);

  // 获取 collectionId 对应的 NFT 数据
  const useNftByCollection = (collectionId: string, page: number) => {
    const cacheKey = `nft_collection_${collectionId}_page_${page}`;
    const { data, error } = useSWR(
      cacheKey,
      () => fetcher(
        `https://turingwallet.xyz/v1/tbc/main/nft/by/collection/id/${collectionId}/page/${page}`),
      {
        revalidateOnFocus: false, // 页面重新聚焦时不自动重新获取数据
        dedupingInterval: 60000,  // 一分钟内重复请求只会触发一次
      }
    );
    return {
      nfts: data?.nftList || [],
      nftCount: data?.nftCount || 0,
      isLoading: !error && !data,
      isError: error,
    };
  };

  // 获取 ordinals 的数据
  const useOrdinals = (page: number) => {
    const cacheKey = `ordinals_page_${page}`;
    const { data, error } = useSWR(
      cacheKey,
      () => fetcher(
        `https://turingwallet.xyz/v1/tbc/main/nft/script/hash/${nftscriptHash}/page/${page}`),
      {
        revalidateOnFocus: false,
        dedupingInterval: 60000,
      }
    );

    return {
      ordinals: data?.nftList || [],
      ordCount: data?.nftCount || 0,
      isLoading: !error && !data,
      isError: error,
    };

  };

  // 获取 Collection 数据
  const useCollections = (page: number) => {
    const cacheKey = `collections_page_${page}`;
    const { data, error } = useSWR(
      cacheKey,
      () => fetcher(`https://turingwallet.xyz/v1/tbc/main/nft/collection/script/hash/${collscriptHash}/page/${page}`),
      {
        revalidateOnFocus: false,
        dedupingInterval: 60000,
      }
    );
    return {
      collections: data?.collectionList || [],
      collectionCount: data?.collectionCount || 0,
      isLoading: !error && !data,
      isError: error,
    };

  };

  // const getOrdinals = async (showProcessing?: boolean) => {
  //   try {
  //     showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;

  //     setIsProcessing(showProcessing); // TODO: set this to true if call is taking more than a second
  //     //TODO: Implement infinite scroll to handle instances where user has more than 100 items.
  //     const ordList = await getOrdUtxos(ordAddress);
  //     setOrdinals({
  //       initialized: true,
  //       // data: ordList.filter((o) => o.satoshis === 1),
  //       data: ordList,
  //     });

  // const tbc20List: Array<TBC20> = await getTbc20Balances(ordAddress);

  //     // All the information currently used has been obtained from `getTbc20Balances`.
  //     // If other information is needed later, call `cacheTokenInfos` to obtain more Tokens information.
  //     // await cacheTokenInfos(tbc20List.map((tbc20) => tbc20.id));

  //     const data = tbc20List.filter((o) => o.all.confirmed + o.all.pending > 0n && typeof o.dec === 'number');
  //     setTBC20s({
  //       initialized: true,
  //       data: data,
  //     });
  //   } catch (error) {
  //     console.error('getOrdinals failed:', error);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  const transferOrdinal = async (
    destinationAddress: string,  // 目标接收地址
    outpoint: string,  // Ordinal UTXO 的 outpoint，表示要转移的特定 Ordinal
    password: string,  // 用户的钱包密码，用于解锁密钥
  ): Promise<OrdOperationResponse> => {
    try {
      await init();  // 初始化 BSV WASM 库，用于构建和处理比特币交易
      setIsProcessing(true);  // 设置处理状态为 true，通知界面操作正在进行中

      const isAuthenticated = await verifyPassword(password);  // 验证用户输入的密码
      if (!isAuthenticated) {
        return { error: 'invalid-password' };  // 如果密码不正确，返回错误
      }

      const keys = await retrieveKeys(password);  // 使用密码从钱包中检索私钥和地址
      if (!keys?.ordAddress || !keys.ordWif || !keys.walletAddress || !keys.walletWif) {
        throw Error('No keys');  // 如果没有获取到必要的密钥和地址，抛出错误
      }
      const ordinalAddress = keys.ordAddress;  // Ordinal NFT 地址
      const ordWifPk = keys.ordWif;  // 用于签名 Ordinal NFT 的私钥（WIF格式）
      const fundingAndChangeAddress = keys.walletAddress;  // 用于支付交易费用的地址（钱包地址）
      const payWifPk = keys.walletWif;  // 用于支付费用的私钥（WIF格式）

      const fundingUtxos = await getUtxos(fundingAndChangeAddress);  // 获取钱包地址的 UTXO，用于支付手续费

      if (!fundingUtxos || fundingUtxos.length === 0) {
        return { error: 'insufficient-funds' };  // 如果没有可用的 UTXO 来支付手续费，返回错误
      }

      const ordUtxos = await getOrdinalUtxos(ordinalAddress, 0);  // 获取与 Ordinal 地址相关的 UTXO //这里是获取的都是对应第一个合约输出
      if (!ordUtxos) throw Error('No ord utxos!');  // 如果没有找到与该地址相关的 UTXO，抛出错误
      const ordUtxo = ordUtxos.find((o) => o.outpoint.toString() === outpoint);  // 从 Ordinal UTXO 列表中找到指定的 outpoint 对应的 UTXO

      if (!ordUtxo) {
        return { error: 'no-ord-utxo' };  // 如果没有找到指定的 Ordinal UTXO，返回错误
      }

      if (!ordUtxo.script) {  // 如果 Ordinal UTXO 没有相关联的锁定脚本 //这里是要改为合约脚本
        const ordRawTx = await getRawTxById(ordUtxo.nftUtxoId);  // 获取该 UTXO 所在交易的原始交易数据
        if (!ordRawTx) throw Error('Could not get raw tx');  // 如果获取不到原始交易数据，抛出错误
        const tx = Transaction.from_hex(ordRawTx);  // 通过原始交易数据构建交易对象
        const out = tx.get_output(ordUtxo.vout);  // 从交易输出中获取指定的 vout（输出）
        const script = out?.get_script_pub_key();  // 获取该输出的锁定脚本
        if (script) {
          ordUtxo.script = script.to_hex();  // 将脚本转换为十六进制字符串并保存到 UTXO
        }
      }

      const fundingUtxo = getSuitableUtxo(fundingUtxos, FEE_SATS);  // 从可用 UTXO 中选择一个 UTXO，用于支付交易费用

      if (!fundingUtxo?.script) {  // 如果支付 UTXO 没有相关联的锁定脚本
        const fundingRawTx = await getRawTxById(fundingUtxo.txid);  // 获取支付 UTXO 所在交易的原始交易数据
        if (!fundingRawTx) throw Error('Could not get raw tx');  // 如果获取不到原始交易数据，抛出错误
        const tx = Transaction.from_hex(fundingRawTx);  // 通过原始交易数据构建交易对象
        const out = tx.get_output(ordUtxo.vout);  // 从交易输出中获取指定的 vout（输出）
        const script = out?.get_script_pub_key();  // 获取该输出的锁定脚本
        if (script) {
          fundingUtxo.script = script.to_hex();  // 将脚本转换为十六进制字符串并保存到支付 UTXO
        }
      }

      if (!fundingUtxo.script || !ordUtxo.script) throw Error('Missing scripts!');  // 如果没有找到锁定脚本，抛出错误

      const payPrivateKey = PrivateKey.from_wif(payWifPk);  // 从 WIF 格式的私钥创建支付地址的私钥对象
      const ordPrivateKey = PrivateKey.from_wif(ordWifPk);  // 从 WIF 格式的私钥创建 Ordinal 地址的私钥对象

      const formattedOrdUtxo: UTXO = {
        satoshis: ordUtxo.satoshis,  // Ordinal UTXO 中的比特币数量（通常是1个 satoshi）
        script: ordUtxo.script,  // Ordinal UTXO 的锁定脚本
        txid: ordUtxo.nftUtxoId,  // Ordinal UTXO 所在交易的 txid
        vout: ordUtxo.vout,  // Ordinal UTXO 的输出序号
      };

      const broadcastResponse = await buildAndBroadcastOrdinalTx(
        fundingUtxo,  // 用于支付手续费的 UTXO
        formattedOrdUtxo,  // 要转移的 Ordinal UTXO
        payPrivateKey,  // 支付地址的私钥
        fundingAndChangeAddress,  // 找零和支付地址
        ordPrivateKey,  // Ordinal 地址的私钥
        destinationAddress,  // 目标接收地址
      );

      if (broadcastResponse?.txid) {
        return { txid: broadcastResponse.txid };  // 如果广播成功，返回交易 ID
      }

      return { error: 'broadcast-failed' };  // 如果广播失败，返回错误信息
    } catch (error) {
      console.error('transferOrdinal failed:', error);  // 捕获并记录错误
      return { error: JSON.stringify(error) };  // 返回错误信息
    } finally {
      setIsProcessing(false);  // 处理完毕，将处理状态设为 false
    }
  };


  const buildAndBroadcastOrdinalTx = async (
    fundingUtxo: UTXO,
    ordUtxo: UTXO,
    payPrivateKey: PrivateKey,
    fundingAndChangeAddress: string,
    ordPrivateKey: PrivateKey,
    destination: string,
  ): Promise<BuildAndBroadcastResponse | undefined> => {
    fundingUtxo.script = Script.from_hex(fundingUtxo.script).to_asm_string();
    ordUtxo.script = Script.from_hex(ordUtxo.script).to_asm_string();
    const sendRes = "";
    // const sendRes = await sendOrdinal(
    //   fundingUtxo,
    //   ordUtxo,
    //   payPrivateKey,
    //   fundingAndChangeAddress,
    //   FEE_PER_BYTE,
    //   ordPrivateKey,
    //   destination,
    // );

    const rawTx = "";
    const tx = Transaction.from_hex(rawTx);

    const changeVout = tx.get_noutputs() ? tx.get_noutputs() - 1 : 1; // The change should be at vout position 1 if the other requests fail
    const change = Number(tx.get_output(changeVout)?.get_satoshis()) ?? 0;
    const { txid } = await broadcastWithTBChainPyPool(rawTx);

    if (txid) {
      return { txid, rawTx, changeInfo: { change, changeVout } };
    }
  };

  const getOrdinalUtxos = async (address: string, page: number): Promise<NFTTxo[] | undefined> => {
    try {
      if (!address) {
        return [];
      }
      const { nftList: preutxos } = await getOrdUtxos(ordAddress, page);
      const utxos = await Promise.all(
        preutxos.map(async (item) => {
          // 创建 Outpoint 实例，并将其赋值给 item.outpoint 属性
          const outpoint = new Outpoint(Buffer.from(item.nftUtxoId, 'hex'), item.vout);

          return {
            ...item,
            outpoint: outpoint, // 设置 Outpoint 实例
          };
        })
      );

      return utxos;
    } catch (error) {
      console.error('getOrdinalUtxos failed:', error);
    }
  };

  interface TapeAmountResult {
    amountHex: string;
    changeHex: string;
  }

  const buildTapeAmount = (amount: bigint, tapeAmountSet: bigint[]): TapeAmountResult => {
    const amountwriter = Buffer.alloc(48);
    const changewriter = Buffer.alloc(48);
    for (let i = 0; i < 6 && amount > 0n; i++) {
      if (tapeAmountSet[i] < amount) {
        amountwriter.writeBigUInt64LE(tapeAmountSet[i], i * 8);
        changewriter.writeBigUInt64LE(BigInt(0), i * 8);
        amount -= tapeAmountSet[i];
      } else {
        amountwriter.writeBigUInt64LE(amount, i * 8);
        changewriter.writeBigUInt64LE(tapeAmountSet[i] - amount, i * 8);
        amount = 0n;
      }
    }
    const amountHex = amountwriter.toString('hex');
    const changeHex = changewriter.toString('hex');
    return { amountHex, changeHex };
  };

  const buildFTtransferCode = (code: string, destinationAddressOrHash: string): Script => {
    if (validate(destinationAddressOrHash)) {
      const publicKeyHashBuffer = P2PKHAddress.from_string(destinationAddressOrHash).get_locking_script().to_bytes().slice(3, 23);
      //@ts-ignore
      const hashBuffer = Buffer.concat([publicKeyHashBuffer, Buffer.from([0x00])]);
      const codeBuffer = Buffer.from(code, 'hex');
      //@ts-ignore
      hashBuffer.copy(codeBuffer, 1537, 0, 21); // Replace the hash in the code script
      const codeScript = Script.from_hex(codeBuffer.toString('hex'));
      return codeScript;
    } else {
      if (destinationAddressOrHash.length !== 40) {
        throw new Error('Invalid address or hash');
      }
      const hash = destinationAddressOrHash + String('01');
      const hashBuffer = Buffer.from(hash, 'hex');
      const codeBuffer = Buffer.from(code, 'hex');
      //@ts-ignore
      hashBuffer.copy(codeBuffer, 1537, 0, 21); // Replace the hash in the code script
      const codeScript = Script.from_hex(codeBuffer.toString('hex'));
      return codeScript;
    }
  }

  const buildFTtransferTape = (tape: string, amountHex: string): Script => {
    const amountHexBuffer = Buffer.from(amountHex, 'hex');
    const tapeBuffer = Buffer.from(tape, 'hex');
    //@ts-ignore
    amountHexBuffer.copy(tapeBuffer, 3, 0, 48); // Replace the amount in the tape script
    const tapeScript = Script.from_hex(tapeBuffer.toString('hex'));
    return tapeScript;
  }

  const sendFtToMulti = async (contractId: string, destinationAddress: string, amount: number, passwordConfirm: string) => {
    try {
      const { walletAddress, walletWif } = await retrieveKeys(passwordConfirm);
      if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

      const payWifPk = walletWif;
      const paymentPk = PrivateKey.from_wif(payWifPk).to_hex();
      const tbcPrivateKey = tbc.PrivateKey.fromString(paymentPk);
      const ft = new tbc.FT(contractId);
      await ft.initialize();

      const multisig = new tbc.Multisig({ ft });
      await multisig.p2pkhToMultiFtTransfer(tbcPrivateKey, destinationAddress, amount);
      return {
        result: true
      };
    } catch (error: any) {
      console.log(error.message);
      return { error: error.message ?? 'send fail' };
    }
  }

  const sendTBC20 = async (ft: TBC20Txo, destinationAddress: string, amount: number, password: string) => {
    try {
      setIsProcessing(true);
      await init();

      const { walletAddress, walletWif } = await retrieveKeys(password);
      if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

      const payWifPk = walletWif;
      const paymentPk = PrivateKey.from_wif(payWifPk).to_hex();

      const tbcPrivateKey = tbc.PrivateKey.fromString(paymentPk);

      const Token = new FT(ft.ftContractId);
      await Token.initialize(); // Step 5.2: Initialize token parameters
      const txhex = await Token.transfer(tbcPrivateKey, destinationAddress, Number(amount)); // Step 5.3: Create transfer transaction
      const txid = await Token.broadcastTXraw(txhex);;
      if (!txid) return { error: 'broadcast-transaction-failed' };
      return { txid };
    } catch (error: any) {
      return { error: error.message ?? 'unknown' };
    } finally {
      setIsProcessing(false);
    }
  };
  // const sendTBC20 = async (ft: TBC20Txo, destinationAddress: string, amount: bigint,password: string) => {
  //   let feeSats =100;
  //   const code=ft.ftCodeScript;
  //   const tape=ft.ftTapeScript;
  //   try {
  //     setIsProcessing(true);  
  //     await init(); 

  //     const { walletAddress, walletWif } = await retrieveKeys(password);
  //     if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

  //     const fundingUtxos = await getUtxos(walletAddress);
  //     if (!fundingUtxos || fundingUtxos.length === 0) throw new Error('Insufficient funds!');

  //     const fundingAndChangeAddress = walletAddress;  
  //     const payWifPk = walletWif; 
  //     const paymentPk = PrivateKey.from_wif(payWifPk);  

  //     const tbc20Utxos = await getTBC20Utxos(ft.ftContractId, tbcAddress);  
  //     if (!tbc20Utxos || tbc20Utxos.length === 0) throw Error('no-tbc20-utxo'); 

  //     const ftInputs =getFtInputs(tbc20Utxos,amount,5);
  //     if (!ftInputs || ftInputs.length === 0) throw new Error('UTXO balance is less than the amount to send');

  //     let tapeAmountSetIn:bigint[] = [];
  //     ftInputs.forEach(item => {
  //       tapeAmountSetIn.push(BigInt(item.ftBalance));
  //     });

  //     const tapeAmountSum = ftInputs.reduce((a, item) => {
  //       return a + BigInt(item.ftBalance); 
  //     }, 0n);

  //     const { amountHex, changeHex } = buildTapeAmount(amount, tapeAmountSetIn);
  //     const codeScript = buildFTtransferCode(code, destinationAddress);
  //     const tapeScript = buildFTtransferTape(tape, amountHex);
  //     const tx = new Transaction(10, 0); 
  //     tx.add_output(
  //       new TxOut(
  //         BigInt(FT_LOCKING_AMOUNT),
  //         codeScript
  //       ),
  //     );
  //     tx.add_output(
  //       new TxOut(
  //         0n,
  //         tapeScript
  //       ),
  //     );

  //     if( amount< tapeAmountSum){
  //       const changeCodeScript = buildFTtransferCode(tape, tbcAddress);
  //       tx.add_output(
  //         new TxOut(
  //           BigInt(FT_LOCKING_AMOUNT),
  //           changeCodeScript
  //         ),
  //       );
  //       const changeTapeScript = buildFTtransferTape(tape, changeHex);
  //       tx.add_output(
  //         new TxOut(
  //           0n,
  //           changeTapeScript
  //         ),
  //       );
  //     }

  //     const estimatedOutputSize = tx.to_bytes().byteLength+P2PKH_OUTPUT_SIZE;
  //     const estimatedInputSize = P2PKH_INPUT_SIZE; 
  //     const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
  //     feeSats = Math.max(feeSats,  Math.ceil(estimatedTotalSize*FEE_PER_BYTE+1));

  //     // tx.add_output(
  //     //   new TxOut(BigInt(indexFee), P2PKHAddress.from_string(tokenDetails.fundAddress).get_locking_script()),
  //     // );

  //     const fundingUtxo = getSuitableUtxo(fundingUtxos,feeSats);  
  //     const totalInputSats = fundingUtxo.satoshis;  
  //     const change = totalInputSats - FT_LOCKING_AMOUNT - feeSats;  

  //     if (change >= DUST) { 
  //       tx.add_output(
  //         new TxOut(BigInt(change), P2PKHAddress.from_string(fundingAndChangeAddress).get_locking_script()),
  //       );
  //     }

  //     // @ts-ignore
  //     const inTx = new TxIn(Buffer.from(fundingUtxo.txid, 'hex'), fundingUtxo.vout, Script.from_hex(''));
  //     inTx.set_satoshis(BigInt(fundingUtxo.satoshis));
  //     const fundingScript = Script.from_hex(fundingUtxo.script);
  //     inTx.set_locking_script(fundingScript);
  //     tx.add_input(inTx);

  //     const sig = tx.sign(paymentPk, SigHash.InputOutputs,ftInputs.length, fundingScript, BigInt(fundingUtxo.satoshis));
  //     inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
  //     tx.set_input(ftInputs.length, inTx); 

  //     const finalSatsIn = tx.satoshis_in() ?? 0n
  //     const finalSatsOut = tx.satoshis_out() ?? 0n;
  //     if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' }; 

  //     const txhex = tx.to_hex(); 
  //     //console.log('txhex:', txhex);
  //     const { txid } = await broadcastWithTBChainPyPool(txhex);  
  //     if (!txid) return { error: 'broadcast-transaction-failed' };  
  //     return { txid };  
  //   } catch (error: any) {  
  //     console.error('sendTBC20 failed:', error);
  //     return { error: error.message ?? 'unknown' }; 
  //   } finally {
  //     setIsProcessing(false); 
  //   }
  // };

  const listOrdinalOnGlobalOrderbook = async (listing: ListOrdinal): Promise<OrdOperationResponse> => {
    try {
      const { outpoint, price, password } = listing;

      setIsProcessing(true);
      await init();

      const isAuthenticated = await verifyPassword(password);
      if (!isAuthenticated) {
        return { error: 'invalid-password' };
      }
      const keys = await retrieveKeys(password);

      if (!keys.walletWif || !keys.ordWif) return { error: 'no-keys' };

      const fundingAndChangeAddress = tbcAddress;
      const paymentPk = PrivateKey.from_wif(keys.walletWif);
      const ordPk = PrivateKey.from_wif(keys.ordWif);

      const paymentUtxos = await getUtxos(fundingAndChangeAddress);

      if (!paymentUtxos.length) {
        throw new Error('Could not retrieve paymentUtxos');
      }

      const totalSats = paymentUtxos.reduce((a: number, utxo: UTXO) => a + utxo.satoshis, 0);

      if (totalSats < FEE_SATS) {
        return { error: 'insufficient-funds' };
      }

      const paymentUtxo = getSuitableUtxo(paymentUtxos, FEE_SATS);

      const ordUtxo = await getUtxoByOutpoint(outpoint);

      if (!ordUtxo) return { error: 'no-ord-utxo' };

      const rawTx = await listOrdinal(
        paymentUtxo,
        ordUtxo,
        paymentPk,
        fundingAndChangeAddress,
        ordPk,
        ordAddress,
        fundingAndChangeAddress,
        Number(price),
      );

      const { txid } = await broadcastWithTBChainPyPool(rawTx);
      if (!txid) return { error: 'broadcast-error' };
      return { txid };
    } catch (error) {
      //console.log(error);
      return { error: JSON.stringify(error) };
    } finally {
      setIsProcessing(false);
    }
  };

  const createChangeOutput = (tx: Transaction, changeAddress: string, paymentSatoshis: number) => {
    const changeaddr = P2PKHAddress.from_string(changeAddress);
    const changeScript = changeaddr.get_locking_script();
    const emptyOut = new TxOut(BigInt(1), changeScript);
    const fee = Math.ceil(FEE_PER_BYTE * (tx.get_size() + emptyOut.to_bytes().byteLength));
    const change = paymentSatoshis - fee;
    const changeOut = new TxOut(BigInt(change), changeScript);
    return changeOut;
  };

  const listOrdinal = async (
    paymentUtxo: UTXO,
    ordinal: NFTTxo,
    paymentPk: PrivateKey,
    changeAddress: string,
    ordPk: PrivateKey,
    ordAddress: string,
    payoutAddress: string,
    satoshisPayout: number,
  ) => {
    const tx = new Transaction(1, 0);
    const t = ordinal.nftUtxoId;
    const txBuf = Buffer.from(t, 'hex');
    // @ts-ignore
    const ordIn = new TxIn(txBuf, ordinal.vout, Script.from_hex(''));
    tx.add_input(ordIn);
    // @ts-ignore
    let utxoIn = new TxIn(Buffer.from(paymentUtxo.txid, 'hex'), paymentUtxo.vout, Script.from_hex(''));

    tx.add_input(utxoIn);

    const payoutDestinationAddress = P2PKHAddress.from_string(payoutAddress);
    const payOutput = new TxOut(BigInt(satoshisPayout), payoutDestinationAddress.get_locking_script());

    const destinationAddress = P2PKHAddress.from_string(ordAddress);
    const addressHex = destinationAddress.get_locking_script().to_asm_string().split(' ')[2];

    const ordLockScript = `${Script.from_hex(
      SCRYPT_PREFIX,
    ).to_asm_string()} ${addressHex} ${payOutput.to_hex()} ${Script.from_hex(O_LOCK_SUFFIX).to_asm_string()}`;

    const satOut = new TxOut(BigInt(1), Script.from_asm_string(ordLockScript));
    tx.add_output(satOut);

    const changeOut = createChangeOutput(tx, changeAddress, paymentUtxo.satoshis);
    tx.add_output(changeOut);

    if (!ordinal.script) {
      const ordRawTxHex = await getRawTxById(ordinal.nftUtxoId);
      if (!ordRawTxHex) throw new Error('Could not get raw hex');
      const tx = Transaction.from_hex(ordRawTxHex);
      const out = tx.get_output(ordinal.vout);
      ordinal.satoshis = Number(out?.get_satoshis());

      const script = out?.get_script_pub_key();
      if (script) {
        ordinal.script = script.to_hex();
      }
    }

    if (!ordinal.script) throw new Error('Script not found');

    const sig = tx.sign(
      ordPk,
      SigHash.ALL | SigHash.FORKID,
      0,
      Script.from_hex(ordinal.script),
      BigInt(ordinal.satoshis),
    );

    ordIn.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${ordPk.to_public_key().to_hex()}`));

    tx.set_input(0, ordIn);

    const sig2 = tx.sign(
      paymentPk,
      SigHash.ALL | SigHash.FORKID,
      1,
      P2PKHAddress.from_string(payoutAddress).get_locking_script(),
      BigInt(paymentUtxo.satoshis),
    );

    utxoIn.set_unlocking_script(Script.from_asm_string(`${sig2.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
    tx.set_input(1, utxoIn);
    return tx.to_hex();
  };

  const cancelGlobalOrderbookListing = async (outpoint: string, password: string): Promise<OrdOperationResponse> => {
    try {
      setIsProcessing(true);
      await init();

      const isAuthenticated = await verifyPassword(password);
      if (!isAuthenticated) {
        return { error: 'invalid-password' };
      }
      const keys = await retrieveKeys(password);

      if (!keys.walletWif || !keys.ordWif) return { error: 'no-keys' };
      const fundingAndChangeAddress = tbcAddress;

      const paymentUtxos = await getUtxos(fundingAndChangeAddress);

      if (!paymentUtxos.length) {
        throw new Error('Could not retrieve paymentUtxos');
      }

      const paymentUtxo = getSuitableUtxo(paymentUtxos, FEE_SATS);

      const paymentPk = PrivateKey.from_wif(keys.walletWif);
      const ordinalPk = PrivateKey.from_wif(keys.ordWif);

      const listingTxid = outpoint.split('_')[0];
      if (!listingTxid) {
        throw new Error('No listing txid');
      }

      const cancelTx = new Transaction(1, 0);

      const { script } = await getMarketData(outpoint);
      // @ts-ignore
      const ordIn = new TxIn(Buffer.from(listingTxid, 'hex'), 0, Script.from_hex(''));
      cancelTx.add_input(ordIn);
      // @ts-ignore
      let utxoIn = new TxIn(Buffer.from(paymentUtxo.txid, 'hex'), paymentUtxo.vout, Script.from_hex(''));
      cancelTx.add_input(utxoIn);

      const destinationAddress = P2PKHAddress.from_string(ordAddress);
      const satOut = new TxOut(BigInt(1), destinationAddress.get_locking_script());
      cancelTx.add_output(satOut);

      const changeOut = createChangeOutput(cancelTx, fundingAndChangeAddress, paymentUtxo.satoshis);
      cancelTx.add_output(changeOut);

      // sign listing to cancel
      const sig = cancelTx.sign(
        ordinalPk,
        SigHash.SINGLE | SigHash.ANYONECANPAY | SigHash.FORKID,
        0,
        // @ts-ignore
        Script.from_bytes(Buffer.from(script, 'base64')),
        BigInt(1),
      );

      ordIn.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${ordinalPk.to_public_key().to_hex()} OP_1`));

      cancelTx.set_input(0, ordIn);

      const sig2 = cancelTx.sign(
        paymentPk,
        SigHash.ALL | SigHash.FORKID,
        1,
        P2PKHAddress.from_string(fundingAndChangeAddress).get_locking_script(),
        BigInt(paymentUtxo.satoshis),
      );

      utxoIn.set_unlocking_script(Script.from_asm_string(`${sig2.to_hex()} ${paymentPk.to_public_key().to_hex()}`));

      cancelTx.set_input(1, utxoIn);
      const rawTx = cancelTx.to_hex();

      const { txid } = await broadcastWithTBChainPyPool(rawTx);
      if (!txid) return { error: 'broadcast-error' };
      return { txid };
    } catch (error) {
      //console.log(error);
      return { error: JSON.stringify(error) };
    } finally {
      setIsProcessing(false);
    }
  };

  const purchaseGlobalOrderbookListing = async (purchaseOrdinal: PurchaseOrdinal) => {
    try {
      const { marketplaceAddress, marketplaceRate, outpoint, password } = purchaseOrdinal;
      setIsProcessing(true);
      await init();

      const isAuthenticated = await verifyPassword(password);
      if (!isAuthenticated) {
        return { error: 'invalid-password' };
      }
      const keys = await retrieveKeys(password);

      if (!keys.walletWif || !keys.ordWif) return { error: 'no-keys' };
      const fundingAndChangeAddress = tbcAddress;

      const fundingUtxos = await getUtxos(fundingAndChangeAddress);

      if (!fundingUtxos.length) {
        throw new Error('Could not retrieve funding UTXOs');
      }

      const payPk = PrivateKey.from_wif(keys.walletWif);
      const listing = await getUtxoByOutpoint(outpoint);
      const price = Number(listing.data?.list?.price);
      const payout = listing.data?.list?.payout;

      if (!price || !payout) throw Error('Missing information!');
      let satsIn = 0;
      let satsOut = 0;

      const purchaseTx = new Transaction(1, 0);
      // @ts-ignore
      const listingInput = new TxIn(Buffer.from(listing.txid, 'hex'), listing.vout, Script.from_hex(''));
      purchaseTx.add_input(listingInput);
      satsIn += listing.satoshis;

      // output 0
      const buyerOutput = new TxOut(BigInt(1), P2PKHAddress.from_string(ordAddress).get_locking_script());
      purchaseTx.add_output(buyerOutput);
      satsOut += 1;

      // output 1
      const payOutput = TxOut.from_hex(Buffer.from(payout, 'base64').toString('hex'));
      purchaseTx.add_output(payOutput);
      satsOut += price;

      // output 2 - change
      const dummyChangeOutput = new TxOut(
        BigInt(0),
        P2PKHAddress.from_string(fundingAndChangeAddress).get_locking_script(),
      );
      purchaseTx.add_output(dummyChangeOutput);

      // output 3 - marketFee
      let marketFee = Math.ceil(price * marketplaceRate);
      const dummyMarketFeeOutput = new TxOut(
        BigInt(marketFee),
        P2PKHAddress.from_string(marketplaceAddress).get_locking_script(),
      );
      purchaseTx.add_output(dummyMarketFeeOutput);
      satsOut += marketFee;

      let listingScript = listing.script!;
      let preimage = purchaseTx.sighash_preimage(
        SigHash.InputOutput,
        0,
        // @ts-ignore
        Script.from_bytes(Buffer.from(listingScript, 'hex')),
        BigInt(1), //TODO: use amount from listing
      );

      listingInput.set_unlocking_script(
        Script.from_asm_string(
          `${purchaseTx.get_output(0)!.to_hex()} ${purchaseTx.get_output(2)!.to_hex()}${purchaseTx
            .get_output(3)!
            .to_hex()} ${Buffer.from(preimage).toString('hex')} OP_0`,
        ),
      );
      purchaseTx.set_input(0, listingInput);

      let size = purchaseTx.to_bytes().length + P2PKH_INPUT_SIZE + P2PKH_OUTPUT_SIZE;
      let fee = Math.ceil(size * FEE_PER_BYTE);
      let inputs: UTXO[] = [];
      while (satsIn < satsOut + fee) {
        const utxo = fundingUtxos.pop();
        if (!utxo) {
          return { error: 'insufficient-funds' };
        }
        // @ts-ignore
        const fundingInput = new TxIn(Buffer.from(utxo.txid, 'hex'), utxo.vout, Script.from_hex(utxo.script));
        purchaseTx.add_input(fundingInput);
        inputs.push(utxo);
        satsIn += utxo.satoshis;
        size += P2PKH_INPUT_SIZE;
        fee = Math.ceil(size * FEE_PER_BYTE);
      }

      let changeAmt = satsIn - (satsOut + fee);
      const changeOutput = new TxOut(
        BigInt(changeAmt),
        P2PKHAddress.from_string(fundingAndChangeAddress).get_locking_script(),
      );

      purchaseTx.set_output(2, changeOutput);

      preimage = purchaseTx.sighash_preimage(
        SigHash.InputOutputs,
        0,
        // @ts-ignore
        Script.from_bytes(Buffer.from(listingScript, 'hex')),
        BigInt(1),
      );

      listingInput.set_unlocking_script(
        Script.from_asm_string(
          `${purchaseTx.get_output(0)!.to_hex()} ${purchaseTx.get_output(2)!.to_hex()}${purchaseTx
            .get_output(3)!
            .to_hex()} ${Buffer.from(preimage).toString('hex')} OP_0`,
        ),
      );
      purchaseTx.set_input(0, listingInput);

      inputs.forEach((utxo, idx) => {
        const fundingInput = purchaseTx.get_input(idx + 1)!;
        const sig = purchaseTx.sign(
          payPk,
          SigHash.InputOutputs,
          1 + idx,
          Script.from_hex(utxo.script),
          BigInt(utxo.satoshis),
        );

        fundingInput.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${payPk.to_public_key().to_hex()}`));

        purchaseTx.set_input(1 + idx, fundingInput);
      });

      const rawTx = purchaseTx.to_hex();

      const broadcastRes = await broadcastWithTBChainPyPool(rawTx);
      if (!broadcastRes.txid) return { error: 'broadcast-error' };
      return { txid: broadcastRes.txid };
    } catch (error) {
      //console.log(error);
      return { error: JSON.stringify(error) };
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    tbc20s,
    ordinals,
    nftsInCollection,
    collections,
    fts,
    ftHistory,
    nftHistory,
    ordAddress,
    ordPubKey,
    getFtHistory,
    getNftHistory,
    IssueNFT,
    createCollection,
    getCollection,
    getNftbycollection,
    getFts,
    addFT,
    transferNFT,
    getOrdinals,
    useNftByCollection,
    useOrdinals,
    useCollections,
    isProcessing,
    nftCount,
    OrdCount,
    collectionCount,
    ftCount,
    ftHistoryCount,
    nftHistoryCount,
    transferOrdinal,
    setIsProcessing,
    getOrdinalsBaseUrl,
    sendTBC20,
    sendFtToMulti,
    listOrdinalOnGlobalOrderbook,
    cancelGlobalOrderbookListing,
    purchaseGlobalOrderbookListing,
    getTokenPriceInSats,
  };
};

export function getTokenName(b: TBC20Txo): string {
  return b.ftName || 'Null';
}
