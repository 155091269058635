import { Buffer } from 'buffer';
import axios from 'axios';
import process from 'process';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ThemeProvider } from './contexts/ColorThemeContext';
import { WalletLockProvider } from './contexts/WalletLockContext';
import { Web3Provider } from './contexts/Web3Context';
import "./utils/i18n";
import './index.css';
global.Buffer = Buffer;
global.process = process;
window.Buffer = Buffer;
axios.defaults.timeout = 300000;


// 创建一个全局请求缓存，用于记录请求时间戳
const requestCache: Record<string, number> = {};
const REQUEST_INTERVAL = 10000; // 10 秒
const SPECIFIC_REQUEST_INTERVAL = 2500; // 特定 URL 请求间隔限制，1秒
// const REQUEST_INTERVAL = 0; // 10 秒
// const SPECIFIC_REQUEST_INTERVAL = 0; // 特定 URL 请求间隔限制，1秒

// Axios 请求拦截器
axios.interceptors.request.use(
  (config) => {
    const requestKey = `${config.method}:${config.url}`;
    const currentTime = Date.now();
    if (config.url &&((config.url.endsWith('/get/balance')))) {
      return config; // 直接返回 config，不进行频率限制
    }
    if (config.url &&((config.url.endsWith('/contract/ids')))) {
      return config; // 直接返回 config，不进行频率限制
    }
    if (config.url &&((config.url.endsWith('/broadcast/tx/raw')))) {
      return config; // 直接返回 config，不进行频率限制
    }
    // 检查 URL 是否包含 "/get/balance" 或 "/unspent/"，应用特定的频率限制
    if (config.url && config.url.endsWith('/unspent/')) {
      if (requestCache[requestKey] && currentTime - requestCache[requestKey] < SPECIFIC_REQUEST_INTERVAL) {
        return Promise.reject(new Error(`Too many requests to ${config.url} within 1 second.`));
      }
      // 更新时间戳缓存
      requestCache[requestKey] = currentTime;
      return config;
    }

    // 应用通用的频率限制
    if (requestCache[requestKey] && currentTime - requestCache[requestKey] < REQUEST_INTERVAL) {
      return Promise.reject(new Error(`Too many requests to ${config.url} within ${REQUEST_INTERVAL / 1000} seconds.`));
    }

    // 更新缓存中的时间戳
    requestCache[requestKey] = currentTime;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



axios.interceptors.response.use(//这里是axios的拦截器，用来处理请求和响应的错误
  response => response,
  error => {
    // console.error("API call failed:", error.config ? error.config.url : 'unknown URL');
    // console.error("Error message:", error.message);
    // console.error("Error stack:", error.stack);
    //alert("Network error, please try again later.");
    return Promise.reject(error);
  }
);


declare global {
  interface Window {
    TelegramGameProxy: { receiveEvent(): void };
  }
}
window.TelegramGameProxy = { receiveEvent() {} };//这里是对tg移动端的优化 ios

const root = document.getElementById('root')!;
const rootDiv = ReactDOM.createRoot(root);
rootDiv.render(
  <ThemeProvider>
    <WalletLockProvider>
      <Web3Provider>
        <App />
      </Web3Provider>
    </WalletLockProvider>
  </ThemeProvider>,
);

