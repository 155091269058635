import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";
import zh_hk from "../locales/zh_hk.json";
import fr from "../locales/fr.json";
import ru from "../locales/ru.json";
import ar from "../locales/ar.json";
import es from "../locales/es.json";
import de from "../locales/de.json";
import pt from "../locales/pt.json";
import ja from "../locales/ja.json";
import hi from "../locales/hi.json";
import ko from "../locales/ko.json";
import my from "../locales/my.json";
import vi from "../locales/vi.json";
import lo from "../locales/lo.json";
import th from "../locales/th.json";
import ms from "../locales/ms.json";

const resources = {
  en,
  zh_hk, 
  fr,
  ru,
  ar,
  es,
  de,
  pt,
  ja,
  hi,
  ko,
  my,
  vi,
  lo,
  th,
  ms,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;