import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WhitelistedApp } from '../App';
import x from '../assets/x.svg';
import axios from 'axios';
import leftArrow from '../assets/left-arrow.svg';
import rightArrow from '../assets/right-arrow.svg';
import { sleep } from '../utils/sleep';
import { BackButton } from '../components/BackButton';
import { Button } from '../components/Button';
import { ForwardButton } from '../components/ForwardButton';
import { Input } from '../components/Input';
import { QrCode } from '../components/QrCode';
import { Text, HeaderText } from '../components/Reusable';
import { SettingsRow } from '../components/SettingsRow';
import { Show } from '../components/Show';
import { SpeedBump } from '../components/SpeedBump';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { SelectSwitchRow } from '../components/SelectSwitchRow';
import { TopNav } from '../components/TopNav';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useKeys } from '../hooks/useKeys';
import { useSnackbar } from '../hooks/useSnackbar';
import { useSocialProfile } from '../hooks/useSocialProfile';
import { useTheme } from '../hooks/useTheme';
import { useWalletLockState } from '../hooks/useWalletLockState';
import { useWeb3Context } from '../hooks/useWeb3Context';
import { ColorThemeProps } from '../theme';
import { storage } from '../utils/storage';
import { idbstorage } from '../utils/idbstorage';
import { truncate } from '../utils/format';
import copyIcon from '../assets/copy-green.svg';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { PageLoader } from '../components/PageLoader';
import { CreateMsWallet } from './onboarding/CreateMsWallet'
import { MsTransaction } from './onboarding/MsTransaction';
import * as tbc from "tbc-js";

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const ConnectedAppRow = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.darkAccent};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.25rem;
  width: 80%;
`;

const SettingsText = styled(Text) <ColorThemeProps>`
  color: ${({ theme }) => theme.white};
  margin: 0;
  font-weight: 600;
  text-align: left;
`;

const XIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const AppIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
`;
const DetailText = styled.p<ColorThemeProps>`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.gray ?? theme.white};
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin:0 0 0 0.5rem;
  text-align: left;
  width: 100%;
`;

const ImageAndDomain = styled.div`
  display: flex;
  align-items: center;
`;

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; 
  overflow-x: hidden;
  width: 100%;
  scrollbar-width: none;
  msOverflow-style: none;
`;

const ExportKeysAsQrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
`;

const PageWrapper = styled.div<{ $marginTop: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.$marginTop};
  width: 100%;
  height: calc(100% - 8rem);
  overflow-y: auto;
`;

const CopyWrapper = styled.div`
  text-align: left;
  padding: 0.5rem;
  cursor: pointer;
`;

const QrCodeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 3.75rem);
  margin-top: -3rem;
`;
const TransactionRow = styled.div`
  display: flex;
  align-items: center;
  width: 93%;
  background-color: ${({ theme }) => theme.darkAccent};
  margin: 0.3rem ;
  height: 4rem; 
  padding: 0.5rem; 
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  transform: scale(1);

  &:active {
    transform: scale(0.95);
    background-color: ${({ theme }) => theme.primaryButton + '50'}; 
  }
`;
const DetailContainer = styled.div`
  width: 90%;
  background-color: ${({ theme }) => theme.darkAccent};
  margin-left: 1.25rem ;
  border-radius: 1rem;
  margin-top: 0.75rem;
`;
const LockDetailsText = styled(Text) <ColorThemeProps>`
  margin: 0;
  color: ${(props) => props.theme.white};
  width: 100%;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
`;

type SettingsPage =
  | 'main'
  | 'connected-apps'
  | 'social-profile'
  | 'export-keys-options'
  | 'create-ms-wallet'
  | 'ms-wallet-details'
  | 'export-historic-transactions'
  | 'export-keys-qr'
  | 'export-pubkey-qr'
  | 'export-mnemonic-words'
  | 'preferences'
  | 'export-transaction-details'
  | 'multi-wallet'
  | 'existed-ms-wallet';
type DecisionType = 'sign-out' | 'export-keys' | 'export-keys-qr-code' | 'export-mnemonic-words' | 'export-pubkey-qr-code';

export const Settings = () => {
  const { theme } = useTheme();
  const { setSelected, hideMenu, showMenu } = useBottomMenu();
  const { lockWallet } = useWalletLockState();
  const [showSpeedBump, setShowSpeedBump] = useState(false);
  const { addSnackbar } = useSnackbar();
  const { tbcPubKey } = useKeys()
  const {
    isPasswordRequired,
    updatePasswordRequirement,
    updateNoApprovalLimit,
    noApprovalLimit,
  } = useWeb3Context();
  const [page, setPage] = useState<SettingsPage>('main');
  const [connectedApps, setConnectedApps] = useState<WhitelistedApp[]>([]);
  const [speedBumpMessage, setSpeedBumpMessage] = useState('');
  const [decisionType, setDecisionType] = useState<DecisionType | undefined>();
  const [history, setHistory] = useState<historyData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [historyCount, setHistoryCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const { retrieveKeys, tbcAddress } = useKeys();
  const { socialProfile, storeSocialProfile } = useSocialProfile();
  const [mnemonicWords, setMnemonicWords] = useState('');
  const [exportKeysQrData, setExportKeysAsQrData] = useState('');
  const [shouldVisibleExportedKeys, setShouldVisibleExportedKeys] = useState(false);
  const [enteredSocialDisplayName, setEnteredSocialDisplayName] = useState(socialProfile.displayName);
  const [enteredSocialAvatar, setEnteredSocialAvatar] = useState(socialProfile?.avatar);
  const [currentTransaction, setCurrentTransaction] = useState<historyData | null>(null);
  const [msWalletData, setMsWalletData] = useState<{ [key: string]: string[] }>({})
  const { t, i18n } = useTranslation();
  const [langselected, setLangSelected] = useState(i18n.language);




  useEffect(() => {
    const getWhitelist = (): Promise<string[]> => {
      return new Promise((resolve, reject) => {
        storage.get(['whitelist'], async (result) => {
          try {
            const { whitelist } = result;
            setConnectedApps(whitelist ?? []);
            resolve(whitelist ?? []);
          } catch (error) {
            reject(error);
          }
        });
      });
    };

    getWhitelist();
  }, []);

  useEffect(() => {
    if (!tbcAddress) return;
    getHistory(currentPage);
  }, [currentPage]);

  useEffect(() => {
    storage.get('i18nextLng', (result) => {
      const savedLanguage = result['i18nextLng'];
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
        setSelected(savedLanguage);
      }
    })
  }, []);
  const handleCopyToClipboard = (value: string | number) => {
    const textToCopy = typeof value === 'number' ? value.toString() : value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };

  const handleRemoveDomain = (domain: string) => {
    const newList = connectedApps.filter((app) => app.domain !== domain);
    storage.set({ whitelist: newList });
    setConnectedApps(newList);
  };

  const handleSignOutIntent = () => {
    setDecisionType('sign-out');
    setSpeedBumpMessage(t('make-sure-you-have-your-seed-phrase-backed-up'));
    setShowSpeedBump(true);
  };

  const handleExportKeysIntent = () => {
    setDecisionType('export-keys');
    setSpeedBumpMessage(
      t('you-are-about-to-download-your-private-keys-make-sure-you-are-in-a-safe-place-and-no-one-is-watching'),
    );
    setShowSpeedBump(true);
  };

  const handleExportMnemonicWords = () => {
    setDecisionType('export-mnemonic-words');
    setSpeedBumpMessage(
      t('you-are-about-to-display-your-mnemonic-words-make-sure-you-are-in-a-safe-place-and-no-one-is-watching'),
    );
    setShowSpeedBump(true);
  };

  const handleExportKeysAsQrCodeIntent = () => {
    setDecisionType('export-keys-qr-code');
    setSpeedBumpMessage(
      t('you-are-about-to-make-your-private-keys-visible-in-qr-code-format-make-sure-you-are-in-a-safe-place-and-no-one-is-watching'),
    );
    setShowSpeedBump(true);
  };

  const handleExportPubKeyAsQrCodeIntent = () => {
    setDecisionType('export-pubkey-qr-code');
    setSpeedBumpMessage(
      t('you-are-about-to-make-your-pub-keys-visible-in-qr-code-format'),
    );
    setShowSpeedBump(true);
  };
  const handleSocialProfileSave = () => {
    storeSocialProfile({
      displayName: enteredSocialDisplayName,
      avatar: enteredSocialAvatar,
    });
    setPage('main');
  };

  const handleTransactionDetails = (historyData: historyData) => {
    setCurrentTransaction(historyData);
    setPage('export-transaction-details');
  };


  useEffect(() => {
    if (!socialProfile) return;
    setEnteredSocialDisplayName(socialProfile.displayName);
    setEnteredSocialAvatar(socialProfile.avatar);
  }, [socialProfile]);

  const exportKeys = async (password: string) => {
    const keys = await retrieveKeys(password);

    const keysToExport = {
      mnemonic: keys.mnemonic,
      Privatekey: keys.walletWif,
      PublicKey: keys.walletPubKey,
      DerivationPath: keys.walletDerivationPath,
      // ordPk: keys.ordWif,
      // ordDerivationPath: keys.ordDerivationPath,
      // identityPk: keys.identityWif,
      // identityDerivationPath: keys.identityDerivationPath,
    };

    const jsonData = JSON.stringify(keysToExport, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', 'turing_wallet_keys.json');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(url);
  };
  interface historyData {
    tx_hash: string;
    recipient_addresses: string[];
    sender_addresses: string[];
    banlance_change: string;
    fee: string;
    time_stamp: number;
  }

  const handleMsWalletDetails = (multisigAddress: string) => {
    const multisig = new tbc.Multisig();
    const { signatureCount, publicKeyCount } = multisig.getSignatureAndPublicKeyCount(multisigAddress);
    return { signatureCount, publicKeyCount };
  }
  const getHistory = async (page: number, showProcessing?: boolean) => {
    try {
      // 显示加载状态
      showProcessing = typeof showProcessing === 'boolean' ? showProcessing : true;
      setIsProcessing(showProcessing);
      await sleep(50);
      const cacheKey = `History_page_${page}`; // 缓存的唯一键
      const apiUrl = `https://turingwallet.xyz/v1/tbc/main/address/${tbcAddress}/history/page/${page}`; // API URL

      // 从缓存中获取数据
      idbstorage.get(cacheKey, async (cachedData: any) => {
        if (cachedData && cachedData[cacheKey]) {
          const parsedData = JSON.parse(cachedData[cacheKey]);
          setHistory(parsedData.historyData);
          setHistoryCount(parsedData.historyCount);
          setIsProcessing(false);
        }
        else {
          setIsProcessing(true);
        }
      });

      // 调用 API 获取最新数据
      const response = await axios.get(apiUrl);
      const historyData = response.data?.result || [];
      const historyCount = response.data?.history_count || 0;
      idbstorage.get(cacheKey, (cachedData: any) => {
        const cachedDataObj = cachedData[cacheKey] ? JSON.parse(cachedData[cacheKey]) : { historyData: [], historyCount: -1 }; // 确保是对象
        if (JSON.stringify(historyData) !== JSON.stringify(cachedDataObj.historyData) || historyCount !== cachedDataObj.historyCount) {
          // 如果新数据与缓存数据不相同，则更新缓存和 UI
          setHistory(historyData);
          setHistoryCount(historyCount);

        // 存储时序列化为 JSON 字符串，包含 nftCount 和 nftList
        idbstorage.set({ [cacheKey]: JSON.stringify({ historyData, historyCount }) });
      }
    });
  } catch (error) {
    console.error('Failed to fetch history:', error);

  } finally {
    setIsProcessing(false);
  }
};
  const exportMnemonicWords = async (password: string) => {
    try {
      const keys = await retrieveKeys(password);
      if (keys && keys.mnemonic) {
        // 将助记词保存到状态中，以便显示
        setMnemonicWords(keys.mnemonic);
        // 跳转到显示助记词的页面
        setPage('export-mnemonic-words');
      } else {
        // 错误处理：未能获取到助记词
        addSnackbar(t('failed-to-retrieve-mnemonic-words-the-current-keys-may-not-be-derived-from-the-mnemonic'), 'error', 5000);
      }
    } catch (error) {
      // 错误处理
      console.error('Error exporting mnemonic words:', error);
      addSnackbar(t('error-exporting-mnemonic-words'), 'error');
    }
  };

  const exportKeysAsQrCode = async (password: string) => {
    const keys = await retrieveKeys(password);

    const keysToExport = {
      mnemonic: keys.mnemonic,
      Privatekey: keys.walletWif,
      PublicKey: keys.walletPubKey,
      DerivationPath: keys.walletDerivationPath,
      // ordPk: keys.ordWif,
      // ordDerivationPath: keys.ordDerivationPath,
    };

    const jsonData = JSON.stringify(keysToExport, null, 2);
    setExportKeysAsQrData(jsonData);

    setPage('export-keys-qr');
    setShouldVisibleExportedKeys(true);
    setTimeout(() => {
      setShouldVisibleExportedKeys(false);
    }, 10000);
  };
  const exportPubKeyAsQrCode = async (password: string) => {
    const keys = await retrieveKeys(password);

    const keysToExport = {
      PublicKey: keys.walletPubKey,
      // ordPk: keys.ordWif,
      // ordDerivationPath: keys.ordDerivationPath,
    };

    const jsonData = JSON.stringify(keysToExport, null, 2);
    setExportKeysAsQrData(jsonData);

    setPage('export-pubkey-qr');
    setShouldVisibleExportedKeys(true);
    setTimeout(() => {
      setShouldVisibleExportedKeys(false);
    }, 10000);
  };

  const exportHistoricPage = (
    <PageWrapper $marginTop="0rem" style={{ height: '88%' }}>
      <BackButton onClick={() => { showMenu(); setPage('main') }} />
      <Text
        style={{
          color: theme.white,
          fontSize: '1.5rem',
          fontWeight: 'bold',
          marginBottom: '0rem',
        }}
        theme={theme}
      >
        {t('historical-transactions')}
      </Text>
      <ScrollableContainer  >
      {history.length === 0 ? (
  <div style={{ textAlign: 'center', marginTop: '4rem' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24" fill="#F57B1F" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10" stroke="#F57B1F" fill="none" />
      <line x1="9" y1="9" x2="15" y2="15" stroke="#F57B1F" />
      <line x1="15" y1="9" x2="9" y2="15" stroke="#F57B1F" />
    </svg>
    <p style={{ color: 'white', marginTop: '1rem',fontSize:'1.2rem' }}>{t('no-history')}</p>
  </div>
) : (history.slice().map((transaction, index) => (
          <TransactionRow
            key={index}
            theme={theme}
            onClick={() => handleTransactionDetails(transaction)}
            style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
          >
            {/* 动态添加箭头 */}
            {parseFloat(transaction.banlance_change) > 0 ? (
              <div style={{ marginRight: '8px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <circle cx="18" cy="18" r="18" fill="black" />
                  <path d="M18 18H8v-2h7.59L6 6.41 7.41 5 18 15.59V8h2v10z" fill="green" transform="translate(5, 5) scale(1.1)" />
                </svg>
              </div>
            ) : (
              <div style={{ marginRight: '8px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <circle cx="18" cy="18" r="18" fill="black" />
                  <path d="M6 6h10v2H8.41l9.59 9.59-1.41 1.41L7 9.41V16H6V6z" fill="#F57B1F" transform="translate(5, 5) scale(1.1)" />
                </svg>
              </div>
            )}
            <div>
              {parseFloat(transaction.banlance_change) > 0 ? (
                <LockDetailsText theme={theme} style={{ fontSize: "0.9rem" }}>
                  {truncate(transaction.sender_addresses[0], 7, 7)}
                </LockDetailsText>
              ) : (
                <LockDetailsText theme={theme} style={{ fontSize: "0.9rem" }}>
                  {truncate(transaction.recipient_addresses[0], 7, 7)}
                </LockDetailsText>
              )}
              <LockDetailsText theme={theme}
                style={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  fontSize: '0.8rem',
                  textAlign: 'left',
                }}>
                {transaction.time_stamp !== null
                  ? new Date(transaction.time_stamp * 1000)
                    .toLocaleDateString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                      // year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: 'UTC',
                    })
                    .replace(',', '')
                  : t('waiting-for-chain')}
              </LockDetailsText>
            </div>
            <LockDetailsText
              theme={theme}
              style={{
                color:
                  parseFloat(transaction.banlance_change) > 0 ? 'green' : theme.primaryButton,
                fontSize: '1rem',
                fontWeight: 'bold',
                marginRight: '-2rem',
              }}
            >
              {transaction.banlance_change} TBC
            </LockDetailsText>
          </TransactionRow>
        )))}
          {Math.ceil(historyCount / 10)>1&&<div style={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
          }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
            opacity: currentPage === 0 ? 0.5 : 1,
            // marginLeft: '-0.5rem' // 向左对齐
          }}
          onClick={() => currentPage > 0 && setCurrentPage((prev) => Math.max(prev - 1, 0))}
        >
          <img src={leftArrow} alt={t('last-page')} style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem' }} />
          <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
        </div>

        {/* 当前页面/总页面数指示器 */}
        <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>
          {currentPage + 1}/{Math.ceil(historyCount / 10)}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: currentPage === Math.ceil(historyCount / 10) - 1 ? 'not-allowed' : 'pointer',
            opacity: currentPage === Math.ceil(historyCount / 10) - 1 ? 0.5 : 1,
            // marginLeft: 'auto' // 向右对齐
          }}
          onClick={() => currentPage < Math.ceil(historyCount / 10) - 1 && setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(historyCount / 10) - 1))}
        >
          <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')} </span>
          <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
        </div>
      </div>}
      </ScrollableContainer>
      
    </PageWrapper>
  );



  const signOut = async () => {
    await idbstorage.clear();
    await storage.clear();
    setDecisionType(undefined);
    window.location.reload();

  };

  const handleCancel = () => {
    showMenu();
    setShowSpeedBump(false);
  };

  // useEffect(() => {
  //   setSelected('settings');
  // }, [setSelected]);

  // const handleNetworkChange = (e: any) => {
  //   const network = e.target.checked ? NetWork.Testnet : NetWork.Mainnet;
  //   updateNetwork(network);

  //   // The provider relies on appState in local storage to accurately return addresses. This is an easy way to handle making sure the state is always up to date.
  //   addSnackbar(`Switching to ${network}`, 'info');
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, SNACKBAR_TIMEOUT - 500);

  //   chrome.runtime.sendMessage({
  //     action: 'networkChanged',
  //     params: {
  //       network,
  //     },
  //   });
  // };
  const handleExistedMsWallet=()=>{
    const cacheKey=`multiSignAddresses`
    // 获取现有的数据
  const MsWalletData = JSON.parse(localStorage.getItem(cacheKey) || '{}');
   setMsWalletData(MsWalletData);
   hideMenu();
   setPage('existed-ms-wallet');
  }
  const handleSpeedBumpConfirm = (password?: string) => {
    if (decisionType === 'sign-out') {
      signOut();
    }

    if (decisionType === 'export-keys' && password) {
      exportKeys(password);
      setDecisionType(undefined);
      setShowSpeedBump(false);
    }
    if (decisionType === 'export-keys-qr-code' && password) {
      exportKeysAsQrCode(password);
      setDecisionType(undefined);
      setShowSpeedBump(false);
    }
    if (decisionType === 'export-pubkey-qr-code' && password) {
      exportPubKeyAsQrCode(password);
      setDecisionType(undefined);
      setShowSpeedBump(false);
    }
    if (decisionType === 'export-mnemonic-words' && password) {
      exportMnemonicWords(password);//这里是导出助记词的函数
      setDecisionType(undefined);
      setShowSpeedBump(false);
    }
  };
  const options = [
    { value: 'zh_hk', label: '繁中' },
    { value: 'en', label: 'Eng' },
    { value: 'fr', label: 'Français' },
    { value: 'ru', label: 'Русский' },
    { value: 'ar', label: 'العربية' },
    { value: 'es', label: 'Español' },
    { value: 'de', label: 'Deutsch' },
    { value: 'pt', label: 'Português' },
    { value: 'ja', label: '日本語' },
    { value: 'hi', label: 'हिन्दी' },
    { value: 'ko', label: '한국어' },
    { value: 'my', label: 'မြန်မာ' },
    { value: 'vi', label: 'Tiếng Việt' },
    { value: 'lo', label: 'ລາວ' },
    { value: 'th', label: 'ไทย' },
    { value: 'ms', label: 'Bahasa Malaysia' }
  ];
  // const [selected, setSelectSwitched] = useState(options[0].value); // 默认选中第一个选项

  const handleLanguageChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption && selectedOption.value !== i18n.language) {
      i18n.changeLanguage(selectedOption.value);
      storage.set({ i18nextLng: selectedOption.value });
      setLangSelected(selectedOption.value);  // 更新选中的语言
    }
  };

  const main = (
    <div style={{overflowY:'auto',height:'100%'}}>
      {/* <SettingsRow
        name="Connected Apps"
        description="Manage the apps you are connected to"
        onClick={() => setPage('connected-apps')}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        name="Preferences"
        description="Manage your wallet preferences"
        onClick={() => setPage('preferences')}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        name="Testnet Mode"
        description="Applies to balances and app connections"
        jsxElement={<ToggleSwitch theme={theme} on={network === NetWork.Testnet} onChange={handleNetworkChange} />}
      /> */}
       <div style={{overflowY:'auto',height:'85%'}}>
      <SettingsRow
        lable="earth.png"
        name={t('language')}
        description={t('select-your-preferred-language')}
        jsxElement={<SelectSwitchRow options={options}
          selected={langselected}
          theme={theme}
          onChange={handleLanguageChange} />}
      />
      {tbcAddress?.startsWith('1') && (
        <SettingsRow
          lable="export.png"
          name={t('export-keys')}
          description={t('download-keys-or-mnerm0nics-words')}
          onClick={() => setPage('export-keys-options')}
          jsxElement={<ForwardButton />}
        />
      )}
      <SettingsRow
        lable="multiwallet.png"
        name={t('multi-wallet')}
        description={t('create-or-view-your-multi-signature-wallet')}
        onClick={() => { setPage('multi-wallet') }}
        jsxElement={<ForwardButton />}
      />
      {/* <SettingsRow
        lable="multiwallet.png"
        name={t('create-import-multi-sig-wallet')}
        description={t('create-or-import-your-multisignature-wallet-for-transactions')}
        onClick={() => { setPage('create-ms-wallet') }}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        lable="multitransaction.png"
        name={t('multisig-transaction-details')}
        description={t('to-view-the-transaction-details-of-imported-multisig-wallets')}
        onClick={() => { setPage('ms-wallet-details') }}
        jsxElement={<ForwardButton />}
      /> */}
      <SettingsRow
        lable="historical.png"
        name={t('historical-transactions')}
        description={t('view-the-lastest-transactions')}
        onClick={() => { getHistory(0, true); hideMenu(); setPage('export-historic-transactions') }}
        jsxElement={<ForwardButton />}
      />

      <SettingsRow lable="lock.png" name={t('lock-wallet')} description={t('immediately-lock-the-wallet')} onClick={() => { hideMenu(); lockWallet() }} />
      <SettingsRow lable="signout.png" name={t('sign-out')} description={t('sign-out-of-turing-wallet-completely')} onClick={() => { hideMenu(); handleSignOutIntent() }} />
      </div>
    </div>
  );

  const connectedAppsPage = (
    <PageWrapper $marginTop={connectedApps.length === 0 ? '10rem' : '-1rem'}>
      <Show when={connectedApps.length > 0} whenFalseContent={<Text theme={theme}>{t('no-apps-connected')}</Text>}>
        <ScrollableContainer>
          {connectedApps.map((app, idx) => {
            return (
              <ConnectedAppRow key={app.domain + idx} theme={theme}>
                <ImageAndDomain>
                  <AppIcon src={app.icon} />
                  <SettingsText theme={theme}>{app.domain}</SettingsText>
                </ImageAndDomain>
                <XIcon src={x} onClick={() => handleRemoveDomain(app.domain)} />
              </ConnectedAppRow>
            );
          })}
        </ScrollableContainer>
      </Show>
      <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setPage('main')} />
    </PageWrapper>
  );

  const exportPubKeysAsQrCodePage = (
    <QrCodeContent>
      <HeaderText style={{ marginTop: '1rem' }} theme={theme}>
        {t('publickey')}
      </HeaderText>
      <Show when={shouldVisibleExportedKeys} whenFalseContent={<Text theme={theme}>{t('timed-out-please-try-again')}</Text>}>
        <ExportKeysAsQrCodeContainer>
          <QrCode address={exportKeysQrData} />
        </ExportKeysAsQrCodeContainer>
      </Show>
      <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setPage('main')} />
    </QrCodeContent>
  );
  const exportPrivKeysAsQrCodePage = (
    <QrCodeContent>
      <HeaderText style={{ marginTop: '1rem' }} theme={theme}>
        {t('privatekey')}
      </HeaderText>
      <Show when={shouldVisibleExportedKeys} whenFalseContent={<Text theme={theme}>{t('timed-out-please-try-again')}</Text>}>
        <ExportKeysAsQrCodeContainer>
          <QrCode address={exportKeysQrData} />
        </ExportKeysAsQrCodeContainer>
      </Show>
      <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setPage('main')} />
    </QrCodeContent>
  );
  const mnemonicWordsPage = (
    <PageWrapper $marginTop="5rem">
      <BackButton onClick={() => setPage('main')} />
      <Text theme={theme}>{t('your-mnemonic-words')}</Text>
      <CopyWrapper onClick={() => handleCopyToClipboard(mnemonicWords)}>
        <StyledCopy src={copyIcon} />
        <Text theme={theme} style={{ margin: '0', color: theme.white }}>{mnemonicWords}</Text>
      </CopyWrapper>
      <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setPage('main')} />
    </PageWrapper>
  );



  const exportKeyOptionsPage = (
    <>
      <SettingsRow
        lable="download.png"
        name={t('download-keys')}
        description={t('download-your-seed-private-and-public-keys')}
        onClick={handleExportKeysIntent}
      />
      <SettingsRow
        lable="words.png"
        name={t('export-mnemonics-words')}//这里是导出助记词
        description={t('disaplay-your-mnemonics-words')}
        onClick={handleExportMnemonicWords}
      />
      <SettingsRow
        lable="Qr.png"
        name={t('export-keys-as-qr-code')}
        description={t('display-private-keys-as-qr-code-for-mobile-import')}
        onClick={handleExportKeysAsQrCodeIntent}
      />
      <SettingsRow
        lable="Qr.png"
        name={t('export-pubkey-as-qr-code')}
        description={t('display-public-keys-as-qr-code')}
        onClick={handleExportPubKeyAsQrCodeIntent}
      />
      <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setPage('main')} />
    </>
  );

  const mulitiWalletPage = (
    <>
      <SettingsRow
        lable="multiwallet.png"
        name={t('imported multi-signature wallets')}
        description={t('view-your-multi-signature-wallet')}
        onClick={() => { handleExistedMsWallet() }}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        lable="multiwallet.png"
        name={t('create-import-multi-sig-wallet')}
        description={t('create-or-import-your-multisignature-wallet-for-transactions')}
        onClick={() => { setPage('create-ms-wallet') }}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        lable="multitransaction.png"
        name={t('multisig-transaction-details')}
        description={t('to-view-the-transaction-details-of-imported-multisig-wallets')}
        onClick={() => { setPage('ms-wallet-details') }}
        jsxElement={<ForwardButton />}
      />
      <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setPage('main')} />
    </>
  );
  const existedMsPage = (
        <div style={{
          width: '100%',
          color: theme.white,
          borderRadius: '0.5rem',
          marginTop: '2rem',
          height: '100%'
      }}>
        <BackButton onClick={() => {showMenu();setPage('multi-wallet')}} />
        <Text style={{ fontSize: '1.5em', fontWeight: 'bold',marginTop:'0', marginBottom: '0.25rem', color: theme.white }}>
          {t('imported multi-signature wallets')}
            </Text>
        <div style={{ marginTop: '0', marginLeft: '0', overflowY: 'auto', height: '85%', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                {Object.entries(msWalletData).map(([msAddress, pubkeys]) => (
                  <DetailContainer theme={theme} key={msAddress}>
                    {msAddress && (
                      <>
                        <CopyWrapper style={{ marginBottom: '0.25rem',display:'flex' }}>
                        <DetailText style={{
                          color: 'rgba(255, 255, 255, 0.75)',
                          fontSize: '0.8rem',
                        }}>
                          {t('type')}: {handleMsWalletDetails(msAddress).signatureCount}/{handleMsWalletDetails(msAddress).publicKeyCount} 
                        </DetailText>
                        {/* <DetailText style={{ fontSize: '1rem',marginLeft:'rem' }} >
                          {handleMsWalletDetails(msAddress).signatureCount}/{handleMsWalletDetails(msAddress).publicKeyCount} 
                        </DetailText> */}
                      </CopyWrapper>
                      <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                        <DetailText style={{
                          color: 'rgba(255, 255, 255, 0.75)',
                          fontSize: '0.8rem',
                        }}>
                          {t('mswalletAddress')}:
                        </DetailText>
                        <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(msAddress)}>
                          <span>
                            {truncate(msAddress, 12, 12)}
                            <StyledCopy src={copyIcon} />
                          </span>
                        </DetailText>
                      </CopyWrapper>
                      </>
                    )}
                        {pubkeys.map((pubkey, index) => (
                          <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                                <DetailText style={{
                                    color: 'rgba(255, 255, 255, 0.75)',
                                    fontSize: '0.8rem',
                                }}>
                                   {t('pubkey')}{index+1}:
                                </DetailText>
                                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(pubkey)}>
                                    <span>
                                        {truncate(pubkey, 12, 12)}
                                        <StyledCopy src={copyIcon} />
                                    </span>
                                </DetailText>
                            </CopyWrapper>
                        ))}
                        {/* <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                            <DetailText style={{
                                color: 'rgba(255, 255, 255, 0.75)',
                                fontSize: '0.8rem',
                            }}>
                                {t('Balance')}:
                            </DetailText>
                        </CopyWrapper> */}
                    </DetailContainer>
                ))}
            </div>
         </div>
  );
  const transactionDetailsPage = (
    <PageWrapper $marginTop="0rem">
      {currentTransaction && (
        <div style={{
          width: '100%',
          color: theme.white, // 假设你的主题指定白色文字颜色
          borderRadius: '0.5rem',
          marginTop: '0rem'
        }}>
          <BackButton onClick={() => setPage('export-historic-transactions')} />
          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0.25rem', color: theme.white }}>
            {t('transaction-details')}
          </Text>
          <div style={{ marginRight: '8px' }}>
          <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg">
  <circle cx="30" cy="30" r="20" fill="green" />
  <circle cx="30" cy="30" r="20" fill="none" stroke="white" stroke-width="2"/>
  <path d="M24 30 l6 6 l12 -12" stroke="white" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" transform="translate(-1.5,0)"/>
</svg>
          <Text style={{ fontSize: '1rem',  marginTop:'0',marginBottom: '0.5rem', color:theme.primaryButton }}>
            {t('transaction-success')}!
          </Text>
              </div>
          <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', color: theme.white }}>
            {currentTransaction.banlance_change} TBC
          </Text>
          <DetailContainer theme={theme}>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('sender-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(currentTransaction.sender_addresses[0])}>
                <span>
                  {truncate(currentTransaction.sender_addresses[0], 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('recipient-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(currentTransaction.recipient_addresses[0])}>
                <span>
                  {truncate(currentTransaction.recipient_addresses[0], 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('Fee')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }}>
                {parseFloat(currentTransaction.fee)} TBC
              </DetailText>
            </CopyWrapper>
          </DetailContainer>
          <DetailContainer theme={theme}>
          <CopyWrapper style={{ marginBottom: '0.25rem' }}>
           <DetailText  style={{
              color: 'rgba(255, 255, 255, 0.75)',  
              fontSize: '0.8rem',  
            }}>
              {t('transaction-id')}:
            </DetailText>
            <DetailText style={{fontSize:'1rem'}}  onClick={() => handleCopyToClipboard(currentTransaction.tx_hash)}>
              <span>
              {truncate(currentTransaction.tx_hash, 12, 12)}
              <StyledCopy src={copyIcon} />
              </span>
            </DetailText>
          </CopyWrapper>
         
          <CopyWrapper style={{  marginBottom: '0.25rem' }}>
          <DetailText  style={{
              color: 'rgba(255, 255, 255, 0.75)',  
              fontSize: '0.8rem',  
            }} >
            {t('transaction-time')}
            </DetailText>
            <DetailText style={{fontSize:'1rem'}}>
              {currentTransaction.time_stamp !== null
                ? new Date(currentTransaction.time_stamp * 1000).toLocaleDateString(undefined, {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    timeZone: 'UTC',
                  }).replace(',', '')
                  : t('waiting-for-chain')}
              </DetailText>
            </CopyWrapper>
          </DetailContainer>
          <DetailContainer theme={theme}>
          <DetailText style={{fontSize:'1rem',textAlign:'center',paddingTop:'1rem'}}>
            {t('click-to-view-details')}
          </DetailText>
          <Button
            label={t('click-to-view-details')}
            theme={theme}
            type="primary"
            onClick={() => window.open(`http://explorer.turingbitchain.io/tx/${currentTransaction.tx_hash}`, '_blank')}
          />
          
          </DetailContainer>
        </div>
      )}
    </PageWrapper>
  );

  const preferencesPage = (
    <>
      <SettingsRow
        name={t('social-profile')}
        description={t('set-your-display-name-and-avatar')}
        onClick={() => setPage('social-profile')}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        name={t('require-password')}
        description={t('require-a-password-for-sending-assets')}
        jsxElement={
          <ToggleSwitch
            theme={theme}
            on={isPasswordRequired}
            onChange={() => updatePasswordRequirement(!isPasswordRequired)}
          />
        }
      />
      <SettingsRow
        name={t('auto-approve-limit')}
        description={t('transactions-at-or-below-this-tbc-amount-will-be-auto-approved')}
        jsxElement={
          <Input
            theme={theme}
            placeholder={String(noApprovalLimit)}
            type="number"
            onChange={(e) => updateNoApprovalLimit(Number(e.target.value))}
            value={noApprovalLimit}
            style={{ width: '5rem', margin: 0 }}
          />
        }
      />
      <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setPage('main')} />
    </>
  );

  const socialProfilePage = (
    <PageWrapper $marginTop="5rem">
      <BackButton onClick={() => setPage('preferences')} />
      <SettingsText theme={theme}>{t('display-name')}</SettingsText>
      <Input
        theme={theme}
        placeholder={t('display-name')}
        type="text"
        onChange={(e) => setEnteredSocialDisplayName(e.target.value)}
        value={enteredSocialDisplayName}
      />
      <SettingsText theme={theme}>{t('avatar')}</SettingsText>
      <Input
        theme={theme}
        placeholder={t('avatar-url')}
        type="text"
        onChange={(e) => setEnteredSocialAvatar(e.target.value)}
        value={enteredSocialAvatar}
      />
      <Button
        theme={theme}
        type="primary"
        label={t('save')}
        style={{ marginTop: '1rem' }}
        onClick={handleSocialProfileSave}
      />
    </PageWrapper>
  );

  return (
    <Show
      when={!showSpeedBump}
      whenFalseContent={
        <SpeedBump
          theme={theme}
          message={speedBumpMessage}
          onCancel={handleCancel}
          onConfirm={(password?: string) => handleSpeedBumpConfirm(password)}
          showSpeedBump={showSpeedBump}
          withPassword={decisionType === 'export-keys' || decisionType === 'export-keys-qr-code' || decisionType === 'export-mnemonic-words' || decisionType === 'export-pubkey-qr-code'}
        />
      }
    >
      {/* <Content $isMobile={isMobile}> */}
      <Content>
        <TopNav />
        <Show when={isProcessing && page === 'export-historic-transactions'}>
          <PageLoader theme={theme} message='loading' />
        </Show>
        <div style={{ marginTop: "20px" }}> </div>
        <Show when={page === 'main'}>{main}</Show>
        <Show when={page === 'connected-apps'}>{connectedAppsPage}</Show>
        <Show when={page === 'preferences'}>{preferencesPage}</Show>
        <Show when={page === 'social-profile'}>{socialProfilePage}</Show>
        <Show when={page === 'export-keys-options'}>{exportKeyOptionsPage}</Show>
        <Show when={page === 'create-ms-wallet'}><CreateMsWallet pubkey={tbcPubKey}/></Show>
        <Show when={page === 'ms-wallet-details'}><MsTransaction pubkey={tbcPubKey} /></Show>
        <Show when={page === 'export-historic-transactions'}>{exportHistoricPage}</Show>
        <Show when={page === 'export-keys-qr'}>{exportPrivKeysAsQrCodePage}</Show>
        <Show when={page === 'export-pubkey-qr'}>{exportPubKeysAsQrCodePage}</Show>
        <Show when={page === 'export-mnemonic-words'}>{mnemonicWordsPage}</Show>
        <Show when={page === 'export-transaction-details'}>{transactionDetailsPage}</Show>
        <Show when={page === 'multi-wallet'}>{mulitiWalletPage}</Show>
        <Show when={page === 'existed-ms-wallet'}>{existedMsPage}</Show>
      </Content>
    </Show>
  );
};
