import styled, { keyframes } from 'styled-components';
import { Text } from './Reusable';
import { SnackbarType } from '../contexts/SnackbarContext';
import { ColorThemeProps, Theme } from '../theme';
import errorIcon from '../assets/error.svg';
import infoIcon from '../assets/info-dark.svg';
import successIcon from '../assets/success.svg';
import React, { useState, useEffect } from 'react';

type SnackBarColorTheme = ColorThemeProps & { color: string };

// 定义动画
const slideIn = keyframes`
  from {
    top: 1.5rem;
    left: -17px;
    opacity: 0;
  }
  to {
    top: 1.5rem;
    left: 0;
    opacity: 0.8;
  }
`;

const slideOut = keyframes`
  from {
    top: 1.5rem;
    left: 0;
    opacity: 0.8;
  }
  to {
    top: 1.5rem;
    left: -17px;
    opacity: 0;
  }
`;

export const SnackBarContainer = styled.div<SnackBarColorTheme & { isExiting: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  position: absolute;
  top: 1.5rem;
  left: 0;
  margin: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.white};
  z-index: 200;
  animation: ${({ isExiting }) => (isExiting ? slideOut : slideIn)} 0.25s ease-out;
  animation-fill-mode: forwards;
`;

const Image = styled.img`
  width: 1rem;
  height: 1rem;
  margin: 1rem;
`;

export type SnackbarProps = {
  /** The message that should be displayed on the snackbar */
  message: string;
  /** The type of snackbar. success | error | info */
  type: SnackbarType | null;
  theme: Theme;
};

export const Snackbar = (props: SnackbarProps) => {
  const { message, type, theme } = props;
  
  const [isExiting, setIsExiting] = useState(false);

  // 点击弹窗时触发滑出动画
  const handleClickAndTouchMove = () => {
    setIsExiting(true);
  };

  // 在弹窗显示2.5秒后自动滑出
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true); // 2.5秒后自动滑出
    }, 2500); // 固定为2.5秒

    return () => clearTimeout(timer);
  }, []);

  return (
    <SnackBarContainer 
      color={type === 'error' ? theme.errorRed : type === 'info' ? theme.white : theme.lightAccent}
      isExiting={isExiting} 
      onClick={handleClickAndTouchMove}
      onTouchMove={handleClickAndTouchMove}
    >
      <Image src={type === 'error' ? errorIcon : type === 'info' ? infoIcon : successIcon} />
      <Text
        theme={theme}
        style={{
          margin: '1rem 0 1rem .25rem',
          color: type === 'error' ? theme.white : theme.darkAccent,
          wordWrap: 'break-word',
          textAlign: 'left',
        }}
      >
        {message}
      </Text>
    </SnackBarContainer>
  );
};
