import { useState } from 'react';
import { styled } from 'styled-components';
import { useKeys } from '../hooks/useKeys';
import { useSnackbar } from '../hooks/useSnackbar';
import { useViewport } from '../hooks/useViewport';
import { ColorThemeProps, Theme } from '../theme';
import { sleep } from '../utils/sleep';
import { Button } from './Button';
import { Input } from './Input';
import { PageLoader } from './PageLoader';
import { ButtonContainer, HeaderText, Text } from './Reusable';
import { Show } from './Show';
import { useTranslation } from 'react-i18next';

const Container = styled.div<ColorThemeProps & { $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${(props) => (props.$isMobile ? '100vw' : '22.5rem')};
  height: ${(props) => (props.$isMobile ? '100vh' : '33.75rem')};
  margin: 0;
  background-color: ${({ theme }) => theme.mainBackground};
  color: ${({ theme }) => theme.white};
  z-index: 100;
`;

export type SpeedBumpProps = {
  message: string;
  showSpeedBump: boolean;
  theme: Theme;
  withPassword?: boolean;
  onCancel: () => void;
  onConfirm: (password?: string) => void;
};

export const SpeedBump = (props: SpeedBumpProps) => {
  const { message, onCancel, onConfirm, showSpeedBump, theme, withPassword = false } = props;
  const { isMobile } = useViewport();

  const [password, setPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { addSnackbar } = useSnackbar();
  const { verifyPassword } = useKeys();
  const { t } = useTranslation();
  
  const handleConfirm = async () => {
    if (!withPassword) {
      onConfirm();
      return;
    }
    try {
      setIsProcessing(true);
      await sleep(25);

      if (!password) {
        addSnackbar(t('you-must-enter-a-password'), 'error');
        return;
      }

      const isVerified = await verifyPassword(password);
      if (!isVerified) {
        addSnackbar(t('invalid-password'), 'error');
        return;
      }

      onConfirm(password);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
      setPassword('');
    }
  };

  const mainContent = (
    <>
      <img src="/logo.png" style={{ width: "130px", height: "auto" }} />
      <div style={{ marginTop: "50px", marginBottom: "40px" }}>
        <HeaderText theme={theme}>{t('are-you-sure')}</HeaderText>
        <Text theme={theme}>{message}</Text>
      </div>
      <Show when={withPassword}>
        <Input
          theme={theme}
          placeholder={t('enter-wallet-password')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Show>
      <ButtonContainer>
        <Button theme={theme} type="secondary" label={t('confirm')} onClick={handleConfirm} style={{ margin: '0 0.5rem' }} />
        <Button theme={theme} type="primary" label={t('cancel')} onClick={onCancel} />
      </ButtonContainer>
    </>
  );

  return (
    <Show when={showSpeedBump}>
      <Container $isMobile={isMobile} theme={theme}>
        <Show when={isProcessing} whenFalseContent={mainContent}>
          <PageLoader theme={theme} message={t('waiting')} />
        </Show>
      </Container>
    </Show>
  );
};
