import Select, { SingleValue } from 'react-select';
import { Theme } from '../theme';
import { text } from 'stream/consumers';

export type SelectProps = {
  options: { value: string; label: string }[];  // 下拉菜单选项
  selected: string;  // 当前选中的选项
  theme: Theme;  // 主题
  onChange?: (selectedOption: { value: string, label: string }) => void;  // 选择变化时的回调
};

export const SelectSwitchRow = (props: SelectProps) => {
  const { options, selected, theme, onChange } = props;

  const sortedOptions = [
    ...options.filter(option => option.value === selected),
    ...options.filter(option => option.value !== selected)
  ];

  // 自定义react-select的样式
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: theme.primaryButton,  // 初始框体背景颜色
      color: theme.white,  // 初始框体字体颜色
      borderRadius: '10px',
      border: 'none',
      // padding: '0.2rem 0.5rem',  // 调整padding使框更紧凑
      minHeight: 'auto',  // 禁用最小高度
      width: '100%',  // 调整下拉框宽度
      fontSize: '0.875rem',  // 设置较小的字体
    }),
    singleValue: (provided: any) => ({//控制箭头高度 和字体
      ...provided,
      color: theme.white,  // 单选时字体颜色
      fontSize: '0.875rem',  // 确保字体与控制框一致
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? theme.primaryButton + 'D0'  // 选中时的背景颜色
        : state.isFocused
          ? theme.primaryButton   // 悬停时的高亮背景颜色
          : theme.primaryButton + '95',  // 默认选项背景颜色
      color: state.isSelected ? theme.white : theme.black,  // 选中时字体颜色
      padding: '0.25rem',
      fontSize: '0.875rem',
      borderBottom: `1.5px solid ${theme.black}`,
      borderRadius: '3px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: theme.white,  // 下拉箭头颜色
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',  // 隐藏分隔线
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: theme.primaryButton + '100',  // 下拉菜单的背景颜色
      marginTop: '0.25rem',  // 调整菜单的上边距
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: '0.25rem 0',
      fontSize: '0.875rem',  // 更小的字体大小
      maxHeight: '200px', // 设置最大高度
      overflowY: 'auto', // 启用垂直滚动
      scrollbarWidth: 'thin', // Firefox 下的滚动条宽度
      scrollbarColor: `${theme.primaryButton} ${theme.black}`, // Firefox 滚动条颜色
    })
  };

  // 修改后的handleChange，考虑到onChange接收的参数是SingleValue类型
  const handleChange = (newValue: SingleValue<{ value: string; label: string }>) => {
    // 处理newValue为null的情况
    if (newValue) {
      if (onChange) {
        onChange(newValue);  // 调用父组件的onChange
      }
    }
  };

  return (
    <Select
      options={sortedOptions}
      value={sortedOptions.find(option => option.value === selected)}  // 设置当前选中的值
      onChange={handleChange}  // 处理选项变化
      styles={customStyles}  // 使用自定义样式
      isSearchable={false}  // 禁用搜索功能（如果不需要的话）
    />
  );
};
