import axios from 'axios';
import Base58  from 'bs58';
import init, { Hash, ChainParams, P2PKHAddress, PublicKey, Script, SigHash, Transaction, TxIn, TxOut } from 'bsv-wasm-web';
import { useEffect, useState } from 'react';
import { DEFAULT_TP_WALLET_PATH, DEFAULT_NABOX_WALLET_PATH,DEFAULT_OKX_WALLET_PATH,FEE_PER_BYTE, SWEEP_PATH } from '../utils/constants';
import { decrypt, deriveKey, encrypt, generateRandomSalt } from '../utils/crypto';
import { generateKeysFromTag, getKeys, getKeysFromWifs, Keys } from '../utils/keys';
import { NetWork } from '../utils/network';
import { storage } from '../utils/storage';
import { UTXO } from './useTbc';
import { useTBChainPyPool } from './useTBChainPyPool';
import { useNetwork } from './useNetwork';
import { usePasswordSetting } from './usePasswordSetting';
import { useTuringBitChain } from './useTuringBitChain';
import * as tbc from 'tbc-js';

export type KeyStorage = {
  encryptedKeys: string;
  passKey: string;
  salt: string;
  msAddress: string;
};

export type WifKeys = {
  payPk: string;
  ordPk?: string;
  mnemonic?: string;
  identityPk?: string;
};

export type SupportedWalletImports = 'turings' |'tp'|'okx'|'nabox'|'wif';

export const useKeys = () => {
  const [tbcAddress, setTbcAddress] = useState('');
  const [ordAddress, setOrdAddress] = useState('');
  const [identityAddress, setIdentityAddress] = useState('');
  const [tbcPubKey, setTbcPubKey] = useState('');
  const [ordPubKey, setOrdPubKey] = useState('');
  const [identityPubKey, setIdentityPubKey] = useState('');

  const { network, isAddressOnRightNetwork } = useNetwork();
  const { isPasswordRequired } = usePasswordSetting();
  const { getBaseUrl } = useTuringBitChain();
  const { broadcastWithTBChainPyPool } = useTBChainPyPool();

  const getChainParams = (network: NetWork): ChainParams => {
    return network === NetWork.Mainnet ? ChainParams.mainnet() : ChainParams.testnet();
  };

  useEffect(() => {
    (async () => {
      await init();
      if (tbcPubKey) {
        const walletAddr = PublicKey.from_hex(tbcPubKey)
          .to_address()
          .set_chain_params(getChainParams(network))
          .to_string();

        setTbcAddress(walletAddr);
      }

      if (ordPubKey) {
        const ordAddr = PublicKey.from_hex(ordPubKey)
          .to_address()
          .set_chain_params(getChainParams(network))
          .to_string();

        setOrdAddress(ordAddr);
      }
    })();
  }, [tbcPubKey, ordPubKey, network]);

  const checkPaykey = (payPk: string) => {
    const buf = Buffer.from(Base58.decode(payPk))
    if (buf.length < 4) { return false }
  
    const data = buf.toString('hex').slice(0, -8)
    const csum = buf.toString('hex').slice(-8)
  
    const hash = Hash.sha_256d(Buffer.from(data,'hex'));
    const hash4 = hash.to_bytes().slice(0, 4)
    console.log(csum)
   console.log(hash4.toString())
    if (csum !== hash4.toString()) { return false }
   
    return true;
  }

  const generateMultiSignAddress = (pubkeys: string[], signatureCount: number): string => {
    const multisig = new tbc.Multisig();
    let publicKeys: tbc.PublicKey[] = [];

    for (let i = 0; i < pubkeys.length; i++) {
      publicKeys.push(tbc.PublicKey.fromString(pubkeys[i]));
    }

    const multiaddress = multisig.createMultisigAddress(publicKeys, signatureCount, pubkeys.length);

    const multiSignAddresses = JSON.parse(localStorage.getItem('multiSignAddresses') || '{}');

    multiSignAddresses[multiaddress] = pubkeys;

    localStorage.setItem('multiSignAddresses', JSON.stringify(multiSignAddresses));

    return multiaddress;
  };

  const getMultiSignAddressPubkeys = (address: string): string[] => {

    const multiSignAddresses = JSON.parse(localStorage.getItem('multiSignAddresses') || '{}');

    const pubkeys = multiSignAddresses[address];

    if (pubkeys) {
      return pubkeys;
    } else {
      return [];
    }
  };


  const generateSeedAndStoreEncrypted = (//函数的主要目的看起来是为了生成一种加密形式的种子或秘密信息，并可能涉及钱包导入以及与不同服务相关的派生路径
    password: string,
    mnemonic?: string,
    walletDerivation: string | null = null,
    ordDerivation: string | null = null,
    identityDerivation: string | null = null,
    importWallet?: SupportedWalletImports,
  ) => {
    const salt = generateRandomSalt();
    const passKey = deriveKey(password, salt);
    switch (importWallet) {
      case 'tp':
        walletDerivation = DEFAULT_TP_WALLET_PATH;
        break;
        case 'okx':
        walletDerivation = DEFAULT_OKX_WALLET_PATH;
        break;
          case 'nabox':
            walletDerivation = DEFAULT_NABOX_WALLET_PATH;
            break;
        }

    const keys = getKeys(mnemonic, walletDerivation, ordDerivation, identityDerivation);
    if (keys == null) return null;
    if (mnemonic) {
      sweepLegacy(keys);
    }
    const encryptedKeys = encrypt(JSON.stringify(keys), passKey);
    storage.set({ encryptedKeys, passKey, salt });
    return keys.mnemonic;
  };

  const sweepLegacy = async (keys: Keys) => {//用于扫描和转移指定地址上的未花费交易输出（UTXO），并将其合并为一笔新的交易以进行转移
    return;
    await init();
    const sweepWallet = generateKeysFromTag(keys.mnemonic, SWEEP_PATH);
    if (!isAddressOnRightNetwork(sweepWallet.address)) return;
    const { data } = await axios.get<UTXO[]>(`${getBaseUrl()}/address/${sweepWallet.address}/unspent`);
    const utxos = data;
    if (utxos.length === 0) return;
    const tx = new Transaction(1, 0);
    const changeAddress = P2PKHAddress.from_string(sweepWallet.address);

    let satsIn = 0;
    utxos.forEach((utxo: any, vin: number) => {
      //@ts-ignore
      const txin = new TxIn(Buffer.from(utxo.tx_hash, 'hex'), utxo.tx_pos, Script.from_hex(''));
      tx.add_input(txin);
      satsIn += utxo.value;
      const sig = tx.sign(
        sweepWallet.privKey,
        SigHash.Input,
        vin,
        changeAddress.get_locking_script(),
        BigInt(utxo.value),
      );
      const asm = `${sig.to_hex()} ${sweepWallet.pubKey.to_hex()}`;
      txin?.set_unlocking_script(Script.from_asm_string(asm));
      tx.set_input(vin, txin);
    });

    const size = tx.to_bytes().length + 34;
    const fee = Math.ceil(size * FEE_PER_BYTE);
    const changeAmount = satsIn - fee;
    tx.add_output(new TxOut(BigInt(changeAmount), P2PKHAddress.from_string(keys.walletAddress).get_locking_script()));

    const rawTx = tx.to_hex();
    const { txid } = await broadcastWithTBChainPyPool(rawTx);
    //console.log('Change sweep:', txid);
  };

  const generateKeysFromWifAndStoreEncrypted = (password: string, wifs: WifKeys) => {
    const salt = generateRandomSalt();
    const passKey = deriveKey(password, salt);
    const keys = getKeysFromWifs(wifs);
    const encryptedKeys = encrypt(JSON.stringify(keys), passKey);
    storage.set({ encryptedKeys, passKey, salt });
    return keys;
  };

  /**
   *
   * @param password An optional password can be passed to unlock sensitive information
   * @returns
   */
  const retrieveKeys = (password?: string, isBelowNoApprovalLimit?: boolean): Promise<Keys | Partial<Keys>> => {
    return new Promise((resolve, reject) => {
      storage.get(['encryptedKeys', 'passKey', 'salt', 'msAddress'], async (result: KeyStorage) => {
        try {
          await init();
          if (result.msAddress) {
            setTbcAddress(result.msAddress);
          }
          if (result.encryptedKeys && result.passKey) {
            const d = decrypt(result.encryptedKeys, result.passKey);
            const keys: Keys = JSON.parse(d);

            const walletAddr = P2PKHAddress.from_string(keys.walletAddress)
              .set_chain_params(getChainParams(network))
              .to_string();

            const ordAddr = P2PKHAddress.from_string(keys.ordAddress)
              .set_chain_params(getChainParams(network))
              .to_string();

            setTbcAddress(walletAddr);
            setOrdAddress(ordAddr);
            setTbcPubKey(keys.walletPubKey);
            setOrdPubKey(keys.ordPubKey);

            // identity address not available with wif or 1sat import
            if (keys.identityAddress) {
              const identityAddr = P2PKHAddress.from_string(keys.identityAddress)
                .set_chain_params(getChainParams(network))
                .to_string();

              setIdentityAddress(identityAddr);
              setIdentityPubKey(keys.identityPubKey);
            }

            if (!isPasswordRequired || isBelowNoApprovalLimit || password) {
              const isVerified = isBelowNoApprovalLimit || !isPasswordRequired || (await verifyPassword(password ?? ''));
              isVerified
                ? resolve(
                  Object.assign({}, keys, {
                    ordAddress: ordAddr,
                    walletAddress: walletAddr,
                  }),
                )
                : reject('Unauthorized!');
            } else {
              resolve({
                ordAddress: ordAddr,
                walletAddress: walletAddr,
                walletPubKey: keys.walletPubKey,
                ordPubKey: keys.ordPubKey,
              });
            }
          }
        } catch (error) {
          reject(error);
        }
      });
    });
  };

  const verifyPassword = (password: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (!isPasswordRequired) resolve(true);
      storage.get(['salt', 'passKey'], (result: KeyStorage) => {
        try {
          const derivedKey = deriveKey(password, result.salt);
          resolve(derivedKey === result.passKey);
        } catch (error) {
          reject(error);
        }
      });
    });
  };

  useEffect(() => {
    retrieveKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    checkPaykey,
    generateSeedAndStoreEncrypted,
    generateKeysFromWifAndStoreEncrypted,
    retrieveKeys,
    verifyPassword,
    generateMultiSignAddress,
    tbcAddress,
    ordAddress,
    identityAddress,
    tbcPubKey,
    ordPubKey,
    identityPubKey,
    getMultiSignAddressPubkeys
  };
};
